<!-- 总览页安全飞行注意事项 -->
<template>
  <div
    class="securityFlyDialog"
    v-if="securityFlyVisible"
    :class="{ z_index: cengji[2].tai == true }"
  >
    <!-- <div class="security_head">
      <div class="security_head_left">安全飞行注意事项</div>
      <div class="security_head_right" @click="$emit('close')">关闭</div>
    </div> -->
    <div class="pup_head">
      <div class="flex">
        <div class="pup_img">
          <img
            src="~@/assets/zong_lan_ye_img/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="fontOne">安全飞行注意事项</div>
      </div>
      <div class="fontTwo" @click="$emit('close')">关闭</div>
    </div>
    <div class="nei">
      <div class="kuang">
        <div class="mb18" v-for="item in securityList" :key="item.lightInfoId">
          {{ item.detail }}
        </div>
      </div>
    </div>
    <!-- <div class="security_content">
      <div
        class="security_content_symbol"
        v-for="item in securityList"
        :key="item.lightInfoId"
      >
        {{ item.detail }}
      </div>
    </div> -->
  </div>
</template>
<script>
import API from "@/api";
export default {
  props: {
    securityFlyVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      securityList: [],
      cengji: null,
    };
  },
  mounted() {
    // this.securityFly();
    // 传2代表安全飞行层级提到99
    this.cengji = this.$store.state.cengji.Zindex;
  },
  methods: {
    async securityFly() {
      let res = await API.SECURITYFLY.Security(3);
      this.securityList = res;
    },
  },
};
</script>
<style lang="scss" scoped>
.securityFlyDialog {
  position: fixed;
  top: 20%;
  left: 31%;
  z-index: 0;
  width: 35%;
  // height: 318px;
  display: flex;
  flex-direction: column;
  width: 672px;
  height: 287px;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  .security_head {
    padding: 0 10px;
    background: rgba(0, 34, 140, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.5);
    border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    height: 44px;
    line-height: 44px;
    .security_head_left {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
    }
    .security_head_right {
      font-family: MicrosoftYaHei;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 400;
      cursor: pointer;
    }
  }
  .security_content {
    padding: 10px 10px;
    // height: 260px;
    // background: rgba(22, 29, 45, 0.8);
    // box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
    border-radius: 0 0 10px 10px;
    .security_content_symbol {
      margin: 16px 50px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #92d9ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.z_index {
  z-index: 99;
}
.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
  }
}
.nei {
    width: 100%;
    height: calc(100% - 32px);
    box-sizing: border-box;
    padding: 18px 16px 16px 16px;
}

.kuang {
    width: 640px;
    height: 224px;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #FFFFFF;
}
</style>