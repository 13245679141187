<template>
  <div class="cpt-command-situation-detail dialog1027">
    <div class="dialog-header" v-interact>
      <div class="dialog-header__icon" />
      <div class="dialog-header__title">警情通知</div>
      <div class="dialog-header__close">
        <!-- <el-tooltip content="警情列表" placement="top">
          <span @click="$emit('routerJq')" class="iconfont mr10 icon-jilu"></span>
        </el-tooltip> -->
        <span @click="$emit('close')">关闭</span>
      </div>
    </div>
    <div class="flight_detail_box dialog-content">
      <audio preload="auto" autoplay>
        <source src="horse.ogg" type="audio/ogg">
        <source src="./8773.mp3" type="audio/mpeg">
        您的浏览器不支持 audio 元素。
      </audio>
      <div class="dialog-content-item textarea">
        <span class="dialog-content__label">警情事件内容：</span>
        <div class="dialog-content__text w400">{{ situation_detail.msg || '暂无' }}</div>
      </div>
      <div></div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">报警时间：</span>
        <div class="dialog-content__text w400">{{ situation_detail.happenTime || '暂无' }}</div>
      </div>
      <div class="dialog-content-item">
        <span class="dialog-content__label">推送时间：</span>
        <div class="dialog-content__text w400">{{ situation_detail.happenTime || '暂无' }}</div>
      </div>

      <div class="jcsb">
        <span class="ml36">派发机构：</span>
        <el-cascader :disabled='true' ref="elcascader" class="w428" placeholder="请选择所属机构" v-model="organizationId" :options="unitList"
          :show-all-levels="false" :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }" clearable></el-cascader>
      </div>
      <div class="btn-box1027">
        <div class="btn1027" @click="$emit('close')">取消</div>
        <div class="btn1027" @click="handleCancerclose">无需派发</div>
         <div class="btn1027" @click='showInfo'>查看详情</div> 
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      organizationId: null,
      unitList: [],
    };
  },
  props: {
    situation_detail: {
      type: Object,
      default: () => ({}),
    },
    _index: {
      type: Number
    }
  },
  created() {
    this.getUnitList();
    this.organizationId = Number(this.situation_detail.orgId)
  },
  methods: {
    showInfo(){
      this.$emit("close");

      this.$emit('DistributionInfo')
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async distributionFn() {
      let { taskId } = this.situation_detail;
      if (!this.organizationId) {
        return this.$message.warning("请选择所属机构!!!");
      }
      let res = await API.SITUATION.dispatchPoliceTask({
        id: taskId,
        organizationId: this.organizationId,
      });
      this.$message.success(res);
      let time = Date.now();
      // 刷新飞行任务-警情任务列表
      this.$store.commit("device/SET_JQ_LIST", time);
      this.$emit("close");
    },
    async handleCancerclose() {
      let res = await API.SITUATION.updateJingQingInfoById({
        id: this.situation_detail.taskId,
        approvalStatus: 7,
        remark:  '无需派发'
      });
      this.$emit('close')
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-command-situation-detail {
  position: absolute;
  width: 560px;
  // height: 608px;
  box-sizing: border-box;
  background: rgba(0, 23, 79, 0.7);
  box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
    inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
  border-radius: 13px;
  left: calc(50% - 255px);
  top: 170px;
  z-index: 200;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 44px;
  background: rgba(16, 65, 215, 0.2);
  box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.6);
  border-radius: 10px 10px 0 0;
}

.hd-box {
  font-family: MicrosoftYaHei-Bold;
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  margin-left: 20px;
}

.el-button {
  border: 0;
  opacity: 0.8;
  font-family: PangMenZhengDao;
  font-size: 22px;
  color: #00ffff;
  text-align: center;
  font-weight: 400;
  width: 114px;
  height: 40px;
  padding: 10px 20px;
  background: rgba(0, 34, 140, 0.2);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 15px 0 #00b6ff;
  border-radius: 4px;
  position: absolute;
  left: 50%;
  bottom: 20px;
}

.flight_detail_box {
  box-sizing: border-box;

  // display: flex;
  // flex-wrap: wrap;
  // justify-content: space-between;
  .textarea {
    div {
      min-height: 50px;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
<style>
.el-cascader-panel {
  background: #02173d;
  height: 300px;
}
</style>
