<template>
  <div class="cpt-fn_icons">
    <Select />
    <div class="icons-list_box">
      <FactorItem
        v-show="isGjx"
        @change="handle_change"
        @item-change="handle_item_change"
        @shang-change="shang_change"
        v-for="(item, index) in list"
        :key="index"
        :data="item"
        :listY="list"
      />
      <el-tooltip
        class="item"
        effect="light"
        content="全要素"
        placement="top-start"
      >
        <div class="icon-gjx" @click="isGjx = !isGjx" :class="{color:routeColor == true}" @mousemove="rulershow = true" @mouseleave="rulershow = false">
          <!-- <img :src="require(`@/assets/images/home/gongjuxiang.png`)" alt="" /> -->
          <img v-if="!rulershow" src="~@/assets/basic-tools/workN.png" />
        <img v-if="rulershow" src="~@/assets/basic-tools/work.png" />
        </div>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import FactorItem from "./factor-item";
import Select from "./select";

export default {
  components: { FactorItem, Select },
  data() {
    return {
      rulershow:false,
      flag: false,
      isGjx: false,
      command: false,
      routeColor: false,
      list: [
        {
          id: 1,
          pid: -1,
          checked: false,
          name: "无人机",
          icon: "iconfont icon-uav",
        },
        {
          id: 2,
          pid: -1,
          checked: false,
          name: "鹰巢",
          icon: "iconfont icon-yingchao",
        },
        {
          id: 3,
          pid: -1,
          checked: false,
          name: "基站",
          icon: "iconfont icon-jizhan",
        },
        {
          id: 4,
          pid: -1,
          checked: false,
          name: "电塔",
          icon: "iconfont dianta",
        },
        {
          id: 5,
          pid: -1,
          checked: false,
          name: "起降场",
          icon: "iconfont icon-wurenjiqijiangchang",
        },
        {
          id: 6,
          checked: false,
          name: "警务元素",
          icon: "iconfont icon-jingwuyuansu",
          pid: -1,
          children: [
            {
              cate_id: 2,
              checked: false,
              name: "警务元素",
              icon: "iconfont icon-kuaifanquan",
              pid: 1,
              children: [
                // {
                //   id: 1,
                //   checked: false,
                //   name: "警情任务",
                //   icon: "iconfont icon-kuaifanquan",
                //   pid: 1,
                // },
                {
                  id: 2,
                  checked: false,
                  name: "常态任务",
                  icon: "iconfont icon-xuncha",
                  pid: 1,
                },
                {
                  id: 3,
                  checked: false,
                  name: "临时任务",
                  icon: "iconfont icon-linshirenwu",
                  pid: 1,
                },
              ],
            },
            {
              cate_id: 4,
              checked: false,
              name: "警力元素",
              icon: "iconfont icon-kuaifanquan",
              pid: 2,
              children: [
                {
                  name: "警务工作站",
                  icon: "iconfont icon-gongzuozhan",
                  id: 1,
                  pid: 2,
                  checked: false,
                },
                {
                  name: "派出所",
                  icon: "iconfont icon-paichusuo",
                  id: 2,
                  pid: 2,
                  checked: false,
                },
                {
                  name: "水上检查站",
                  icon: "iconfont icon-shuishangjianchazhan",
                  id: 3,
                  pid: 2,
                  checked: false,
                },
                {
                  name: "警务室",
                  icon: "iconfont icon-zjingwushi",
                  id: 4,
                  pid: 2,
                  checked: false,
                },
                {
                  name: "高速服务区",
                  icon: "iconfont icon-gaosu",
                  id: 5,
                  pid: 2,
                  checked: false,
                },
              ],
            },
            {
              cate_id: 3,
              checked: false,
              name: "感知设备",
              icon: "iconfont icon-kuaifanquan",
              pid: 3,
              children: [
                {
                  id: 2,
                  name: "监控设备",
                  icon: "iconfont icon-jiaojing",
                  pid: 3,
                  checked: false,
                },
                {
                  id: 1,
                  name: "警车",
                  icon: "iconfont icon-jingche",
                  pid: 3,
                  checked: false,
                },
                {
                  id: 3,
                  name: "4G执法记录仪",
                  icon: "iconfont icon-tejing",
                  pid: 3,
                  checked: false,
                },
                {
                  name: "350M",
                  icon: "iconfont icon-a-350M",
                  id: 4,
                  pid: 3,
                  checked: false,
                },
              ],
            },
            {
              cate_id: 5,
              checked: false,
              name: "快反圈",
              icon: "iconfont icon-kuaifanquan",
              pid: 4,
              children: [
                {
                  name: "一分钟快反圈",
                  icon: "iconfont icon-yifenzhongkuaifanquan",
                  id: 1,
                  pid: 4,
                  checked: false,
                },
                {
                  name: "三分钟快反圈",
                  icon: "iconfont icon-sanfenzhongkuaifanquan",
                  id: 2,
                  pid: 4,
                  checked: false,
                },
                {
                  name: "五分钟快反圈",
                  icon: "iconfont icon-wufenzhongkuaifanquan",
                  id: 3,
                  pid: 4,
                  checked: false,
                },
              ],
            },
          ],
        },
        {
          id: 7,
          checked: false,
          name: "社会资源",
          icon: "iconfont icon-jingwuyuansu",
          pid: -1,
          children: [
            {
              cate_id: 6,
              checked: false,
              name: "重点区域",
              icon: "iconfont icon-kuaifanquan",
              pid: 6,
              children: [
                {
                  id: 1,
                  name: "重点行政单位",
                  icon: "iconfont icon-zhongdiandanwei",
                  pid: 6,
                  checked: false,
                },
                {
                  id: 2,
                  name: "医院",
                  icon: "iconfont icon-yiyuan",
                  pid: 6,
                  checked: false,
                },
                {
                  id: 3,
                  name: "银行",
                  icon: "iconfont icon-yinhang",
                  pid: 6,
                  checked: false,
                },
                {
                  id: 4,
                  name: "KTV、酒吧",
                  icon: "iconfont icon-KTV",
                  pid: 6,
                  checked: false,
                },
                {
                  id: 5,
                  name: "学校",
                  icon: "iconfont icon-xuexiao",
                  pid: 6,
                  checked: false,
                },
                {
                  id: 6,
                  name: "加油加气站",
                  icon: "iconfont icon-jiayouzhan",
                  pid: 6,
                  checked: false,
                },
              ],
            },
          ],
        },
      ],
    };
  },
  methods: {
    shang_change(e, is_show, id) {
      console.log(id, "-----------------------2");
      this.$emit("shang-change", e, is_show, id);
    },
    handle_change(e) {
      let { list } = this;
      e.children.forEach((item) => (item.checked = e.checked));
      this.$emit("change", e, list);
    },
    handle_item_change(e) {
      this.$emit("shang-change", e);
      // let { list } = this;
      // let parent = list.find(item => item.cate_id == e.pid)
      // console.log("**x-parent:", parent);
      // parent.checked = parent.children.every(item => item.checked)
      // this.$emit('item-change', parent, list)
      // this.$emit("item-change", e, list);
    },
  },
  watch: {
    "$store.state.device.flag"(newData) {
      this.flag = newData;
    },
    "$route.path": {
      handler(value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    if (this.$route.path == "/command") {
      this.command = true;
    } else {
      this.command = false;
    }
  },
  mounted() {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".icons-list_box"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isGjx = false;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
.cpt-fn_icons {
  // position: absolute;
  // top: 262px;
  // right: 550px;
  position: absolute;

  right: 0;
  .icons-list_box {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    margin-top: 5px;
  }
}
.defaultFlag {
  margin-top: 10px;
  right: 50px;
  transition: 0.3s;
}
.icon-gjx {
  width: 48px;
  height: 48px;
  background: rgba(22, 29, 45, 0.58);
  // border: 1px solid #fff;
  border-radius: 2px;
  text-align: center;
  line-height: 48px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.color {
  // border: 1px solid #fff !important;
  &:hover{
    border: 1px solid #43deff;
  }
}
</style>
