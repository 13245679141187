<template>
  <div class="cpt-factor-item">
    <div class="icon-box">
      <el-tooltip
        class="item"
        effect="dark"
        :content="data.name || ''"
        placement="top-start"
        :style="{ background: data.checked ? '#000' : '' }"
      >
        <div class="icon1" @click="handle_icon1(data)">
          <img
            @click="isdilog = false"
            :src="require(`@/assets/images/icons/factor/${data.id}.png`)"
            alt=""
          />
          <img
            v-if="data.id > 5"
            :src="require(`@/assets/images/icons/factor/jiantou.png`)"
            alt=""
            class="jiantou"
            :class="active1 ? 'active1' : ''"
          />
        </div>
      </el-tooltip>
      <div
        v-if="data.children && data.children.length && data.checked"
        class="icon2-groud"
      >
        <div
          class="icon2"
          v-for="(item, index) in data.children"
          :key="index"
          @click="handleIcon2(item, index)"
          :style="{ background: item.checked ? '#000' : '' }"
        >
          <div
            class="icon3"
            v-if="item.checked && item.children.length && item.checked"
            @click.stop="liunian(item, index)"
          >
            <div class="icon3-cate" v-for="vv in item.children" :key="vv.id">
              <el-checkbox v-model="vv.checked" @change="itemChange(item)">
                <div class="child-inner">
                  <span :class="vv.icon"></span>
                  <!-- <div></div>
          <img :src="require(`@/assets/images/icons/factor/${data.cate_id}.png`)" alt=""> -->
                  <span class="title" @click="item.checked = true">
                    {{ vv.name }}</span
                  >
                </div>
              </el-checkbox>
            </div>
          </div>
          <el-tooltip
            class="item"
            effect="dark"
            :content="item.name || ''"
            placement="top-start"
            :style="{ background: item.checked ? '#000' : '' }"
          >
            <div class="iconb">
              <img
                :src="
                  require(`@/assets/images/factor/jlzy/${item.cate_id}.png`)
                "
                alt=""
              />
              <img
                :src="require(`@/assets/images/icons/factor/jiantou.png`)"
                alt=""
                class="jiantou"
              />
            </div>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let obj = {
  cate_id: 1,
  checked: false,
  name: "主要设备",
  icon: "iconfont icon-zhuyaoshebei",
  pid: -1,
  children: [
    {
      id: 1,
      pid: 1,
      checked: false,
      name: "无人机",
      icon: "iconfont icon-uav",
    },
    {
      id: 2,
      pid: 1,
      checked: false,
      name: "鹰巢",
      icon: "iconfont icon-yingchao",
    },
    {
      id: 3,
      pid: 1,
      checked: false,
      name: "基站",
      icon: "iconfont icon-jizhan",
    },
      {
      id: 4,
      pid: 1,
      checked: false,
      name: "电塔",
      icon: "iconfont icon-dianta",
    },
    {
      id: 5,
      pid: 1,
      checked: false,
      name: "起降场",
      icon: "iconfont icon-wurenjiqijiangchang",
    },
  ],
};
export default {
  data() {
    return {
      isdilog: true,
      obj: obj,
      active1: false,
    };
  },
  inject: ["g_cesium_layer", "g_show_zysb"],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    listY: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    itemChange(e) {
      if(window.base_layer.SATELLITE.show == true){
    window.base_layer.SATELLITE.show = false
    window.base_layer.BASIC.show = false
    setTimeout(() => {
      window.base_layer.SATELLITE.show = true
    }, 500);
    }
      let cesium_layer = this.g_cesium_layer();
      cesium_layer.fly_to_globe();
      this.$emit("item-change", e);
    },
    liunian(item, index) {
      console.log("liunian");
      item.checked = true;
      console.log("离谱！");
    },
    handle_icon1(zysb) {
      if (zysb.id < 6) {
        if(window.base_layer.SATELLITE.show == true){
    window.base_layer.SATELLITE.show = false
    window.base_layer.BASIC.show = false
    setTimeout(() => {
      window.base_layer.SATELLITE.show = true
    }, 500);
    }
        let cesium_layer = this.g_cesium_layer();
        cesium_layer.fly_to_globe();
        zysb.checked = !zysb.checked;
        let { obj } = this;
        let _this = this;
        obj.children.forEach((item, index) => {
          if (item.id == zysb.id) {
            obj.children.splice(index, 1);
            obj.children.push(zysb);
          }
        });
        console.log(zysb.id,'-----------------------1');
        this.$emit("shang-change", obj, zysb.checked,zysb.id);
      } else {
        this.active1 = !this.active1;
        if (this.data.checked) {
          this.data.checked = !this.data.checked;
        } else {
          for (let i = 4; i < this.listY.length; i++) {
            this.listY[i].checked = false;
          }
          this.data.checked = !this.data.checked;
        }
      }
    },
    handleIcon2(item, index) {
      console.log("handleIcon2");

      console.log("---------item", item);
      console.log("---------index", index);
      console.log("data7:", this.data.children);
      let ary = this.data.children;
      if (item.checked) {
        item.checked = !item.checked;
      } else {
        for (let i = 0; i < ary.length; i++) {
          ary[i].checked = false;
        }
        ary[index].checked = true;
      }
    },
  },
  provide() {
    return {
      itemChange: this.itemChange,
    };
  },
};
</script>

<style lang="scss" scoped>
.cpt-factor-item {
  width: 48px;
  height: 48px;
  // margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  // background: rgba(22, 29, 45, 0.58);
  border: 1px solid #04d5da;
  border-radius: 2px;
  .icon-box {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    // line-height: 48px;
    // border: solid 2px transparent;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-image: linear-gradient(#161d2d, #161d2d),
    //   linear-gradient(to top, #1c94ff, #00ffff);
    // background-origin: border-box;
    // background-clip: content-box, border-box;
    // background: #161d2d;
    // border: 2px solid #00ffff;
    transition: 0.5s;
    img {
      width: 20px;
      // height: 16px;
    }
    .el-icon {
      color: #00ffff;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(25%, -50%) scale(0.5);
      &::before {
        display: none;
      }
    }
    .iconfont {
      color: #00ffff;
      font-size: 40px;
    }
  }
  .icon-text {
    font-family: Microsoft YaHei;
    font-size: 12px;
    color: #08c2d1;
    letter-spacing: 0;
    text-align: center;
    text-shadow: 0 1px 2px #000000;
    font-weight: 700;
    line-height: 1.8;
    // white-space: nowrap;
  }
}

.icon-box {
  width: 48px;
  height: 48px;
  // margin-right: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: rgba(22, 29, 45, 0.58);
  border: 1px solid #04d5da;
  border-radius: 2px;
  position: relative;
  .icon1 {
    width: 48px;
    height: 48px;
    cursor: pointer;
    //  background: rgba(22,29,45,0.58);
    border: 1px solid #04d5da;
    border-radius: 2px;
    position: relative;
    transition: 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    img {
      width: 20px;
    }
    .jiantou {
      width: 5px;
      position: absolute;
      right: 10%;
      top: 40%;
      &.active1 {
        transform: rotate(90deg);
      }
    }
  }
  .icon2-groud {
    position: absolute;
    top: 48px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .icon2 {
      width: 48px;
      height: 48px;
      cursor: pointer;
      background: rgba(22, 29, 45, 0.58);
      border: 1px solid #04d5da;
      border-radius: 2px;
      position: relative;
      transition: 0.5s;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 20px;
      }
      .jiantou {
        transform: rotate(180deg);
        width: 5px;
        position: absolute;
        left: 10%;
        top: 40%;
      }
    }
    .icon3 {
      background: rgba(22, 29, 45, 0.58);
      border: 1px solid #04d5da;
      border-radius: 2px;
      box-sizing: border-box;
      padding: 20px;
      width: 150px;
      position: absolute;
      top: 0;
      right: 50px;
      font-family: MicrosoftYaHei;
      .icon3-cate {
        text-align: left;
      }
    }
  }
}

.child-inner {
  font-size: 12px;
  color: #00fff7;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  .iconfont::before {
    font-size: 18px;
  }
}

.dropdown-menu {
  ::v-deep {
    background-color: #161d2d !important;
    border: 1px solid #178890 !important;
    border-radius: 0px;
    padding: 0px;
    .factor-item-children-box {
      padding: 16px;
      box-sizing: border-box;
      .selected-all {
        border-top: 1px solid rgba(0, 255, 255, 0.3);
        margin-top: 13px;
        padding-top: 7px;
        .title {
          font-family: Microsoft YaHei;
          font-size: 14px;
          color: #08c2d1;
          letter-spacing: 0;
          font-weight: 700;
        }
      }
    }
    .factor-item-more-box {
      color: #00ffff;
      box-sizing: border-box;
      padding: 30px;
    }

    .popper__arrow {
      border-bottom-color: #178890 !important;
    }

    .popper__arrow::after {
      top: 1px;
      margin-left: -6px;
      border-top-width: 0;
      border-bottom-color: #178890 !important;
    }

    .el-checkbox {
      display: flex;
      align-items: center;
      .el-checkbox__input {
        .el-checkbox__inner {
          width: 20px;
          height: 20px;
          background: #000000;
          border-color: #08c2d1;
          border-radius: 2px;
          box-sizing: border-box;
          &::after {
            height: 12px;
            left: 6px;
            top: 1px;
            width: 5px;
          }
        }
      }
    }
  }
}

/deep/ .el-checkbox__inner {
  background-color: transparent;
  border: 1px solid #04d5da !important;
}
</style>
