<!-- 飞控地图选择 -->
<template>
  <div class="cpt-hwkeys">
    <!-- <div class="change">
      <div
        class="typeNmae"
        :class="{ active: !is_3d }"
        v-show="!is_3d"
        @click="handle_change_3d(true)"
      >2D</div>
      <div
        class="typeNmae"
        :class="{ active: is_3d }"
        v-show="is_3d"
        @click="handle_change_3d(false)"
      >3D</div>
    </div>-->

    <div class="hwkeys-wrap cp" @click="change" @mouseout="likai">
      <div :class="{
        'hwkeys-item': 1,
        'hwkeys-item-satellite': 1,
      }" v-for="item in tabs" :key="item.id" v-show="item.id == current" @click="handle_change(item.id)">
        <!-- <div class="typeNmae">{{ item.name }}</div> -->
      </div>
    </div>

    <div class="changeMapBox pa cp">
      <div class="dise" @mouseout="likai">
        <div class="bai" :class="{ active: !is_3d }" v-show="!is_3d" @click="handle_change_3d(true)">
          2D
        </div>
        <div class="bai" :class="{ active: is_3d }" v-show="is_3d" @click="handle_change_3d(false)">
          3D
        </div>
      </div>
      <div v-for="item in tabs" :key="item.id" class="dise" @mousemove="jinru(item.id)">
        <div :class="{
          'hwkeys-item': 1,
          [item.class_name]: 1,
        }" @click="handle_change(item.id)" style="position: relative; overflow: hidden">
          <div class="zimu" :class="{ sh: numId == item.id + 1 }">
            {{ item.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { global_leyers, base_layer } from "@/utils/global";
import Water from "@/components/accident/water";
export default {
  components: { Water },
  data() {
    return {
      numId: null, //鼠标悬浮效果
      waterFlag: false,
      showwater: false,
      active: false,
      flag: false,
      is_3d: true,
      current: 1,
      tabs: [
        {
          id: 1,
          name: "卫星",
          class_name: "hwkeys-item-satellite",
        },
        {
          id: 2,
          name: "街道",
          class_name: "hwkeys-item-street",
        },
        {
          id: 3,
          name: "夜景",
          class_name: "hwkeys-item-night-blue",
        },
      ],
    };
  },
  mounted() {
    console.log('handle_change2')
    setTimeout(() => {
      this.handle_change(1);
      this.handle_change_3d(false);
    }, 3500);
  },
  watch: {
    "$store.state.device.mapFlag"(newData) {
      this.flag = newData;
    },
    "$store.state.device.waterFlag"(newData) {
      this.waterFlag = newData;
    },
  },
  methods: {
    // 鼠标悬浮效果
    jinru(id) {
      this.numId = id + 1;
    },
    likai() {
      this.numId = null;
    },
    guanbiwater() {
      this.showwater = false;
    },
    openwater() {
      // this.showwater = true
      // "$store.state.device.mapFlag"(newData){
      //   this.flag = newData
      // }
      this.$store.commit("device/SET_WATERBOX", true);
    },
    handle_change_3d(bool) {
      this.is_3d = bool;
      this.$store.commit("device/SET_3D_STATUS", bool);
      try {
        global_leyers.forEach((promise) => {
          promise.then((layer) => {
            layer.visible = bool;
          });
        });
      } catch (error) { }
    },
    handle_change(id) {
      this.current = id;
      if (id == 1) {
        base_layer.BASIC && (base_layer.BASIC.show = false);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
        base_layer.NIGHT && (base_layer.NIGHT.show = false);
      } else if (id == 2) {
        base_layer.BASIC && (base_layer.BASIC.show = true);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
        base_layer.NIGHT && (base_layer.NIGHT.show = false);
      } else {
        base_layer.BASIC && (base_layer.BASIC.show = false);
        base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
        base_layer.NIGHT && (base_layer.NIGHT.show = true);
      }
      // console.log(id,'我是地图');
    },
    change() {
      this.active = !this.active;
      // global_leyers.forEach(promise => {
      //   promise.then((layer) =>{
      //     layer.visible = this.active;
      //   });
      // })
      // base_layer.BASIC && (base_layer.BASIC.show = !this.active)
      // base_layer.SATELLITE && (base_layer.SATELLITE.show = this.active)
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-hwkeys {
  z-index: 1;
  bottom: 30px;

  .changeMapBox {
    width: 201px;
    height: 48px;
    background: rgba(9, 32, 87, 0.7);
    border: 1px solid #43deff;
    left: -159px;
    margin-top: 3px;
    display: none;
    justify-content: space-around;
    align-items: center;

    // display: flex;
    .hwkeys-item {
      width: 30px;
      height: 30px;
      color: #04d5da;
      text-align: right;
      line-height: 49px;
      // margin-top: 5px;
      border-radius: 4px;
      font-size: 9px;
      border: 1px solid #eee;
    }

    .hwkeys-item-satellite {
      background: url(../../assets/images/3d.png) center no-repeat;
    }

    .hwkeys-item-street {
      background: url(../../assets/images/2d.png) center no-repeat;
    }

    .hwkeys-item-night-blue {
      background: url(../../assets/images/night-blue.png) center no-repeat;
    }

    .hwkeys-item-3d {
      background: url(../../assets/images/3d.png) center no-repeat;
    }

    .hwkeys-item-2d {
      background: url(../../assets/images/2d.png) center no-repeat;
    }
  }

  position: absolute;
  right: 14px;

  width: 104px;
  height: 78px;
  transition: 0.2s;
  transform: translateX(0);

  &.wkeysRight {
    transition: 0.3s;
    transform: translateX(-12px);
    right: 40px;
  }

  .hwkeys-wrap {
    position: absolute;
    right: 0;
    min-width: 52px;
    height: 52px;
    border-radius: 2px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: width 0.25s;

    &.active {
      width: 166px;
    }

    .hwkeys-item {
      width: 52px;
      height: 52px;
      box-sizing: border-box;
      border-radius: 50%;
      border: 3px solid #fff;
      position: relative;

      &.hwkeys-item-satellite {
        // background: url(../../assets/images/3d.png) center no-repeat;
        background: url('../../assets/newImage/ditu_icon.png') center no-repeat;
        background-size: 112% 112%;
      }

      &.hwkeys-item-street {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-night-blue {
        background: url(../../assets/images/night-blue.png) center no-repeat;
        // background-size: 115% 115%;
      }

      &.hwkeys-item-3d {
        background: url(../../assets/images/3d.png) center no-repeat;
      }

      &.hwkeys-item-2d {
        background: url(../../assets/images/2d.png) center no-repeat;
      }

      &.hwkeys-item-street {}

      .typeNmae {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        height: 20px;
        border: 1px solid #43ddfe;
        box-shadow: inset 0 0 7px 0 #43ddfe;
        border-radius: 1px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #04d5da;
      }
    }
  }

  .waterbox {
    height: 48px;
    width: 48px;
    position: absolute;
    top: 130px;
    right: 0px;
    border: 1px solid #04d5da;
    background: rgba(22, 29, 45, 0.7);
    color: #43deff;
    font-size: 20px;
    line-height: 48px;
  }

  .water_quality {
    width: 459px;
    height: 418px;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 13px;
  }
}

.cpt-hwkeys:hover {
  .changeMapBox {
    display: flex;
  }
}

.dise {
  width: 34px;
  height: 34px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
}

.bai {
  text-align: center;
  line-height: 30px;
  font-size: 14px;
  color: #ffffff !important;
  font-weight: 700;
  width: 30px;
  height: 30px;
  // border: 2px solid #fff;
  border-radius: 4px;
  // margin: 5px 0 0 0;
  background: #000000;
}

.zimu {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 18px;
  opacity: 0.64;
  background: #fff;
  border-radius: 0 0 4px 4px;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-family: MicrosoftYaHei;
  color: #000;
  line-height: 16px;
  font-weight: 400;
  display: none;
  pointer-events: auto;
}

@keyframes fr {
  from {
    bottom: -60%;
  }

  to {
    bottom: 0%;
  }
}

.sh {
  display: block;
  animation: fr 1s linear;
  animation-fill-mode: forwards;
  pointer-events: auto;
}

.anniu {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
}
</style>