<template>
  <!-- :class="isFind ? 'active' : ''" -->
  <div class="cpt-select">
    <div
      class="select_box"
      :class="{ color: routeColor, active: isFind }"
    >
      <el-tooltip
        class="item"
        effect="light"
        content="搜索"
        placement="top-start"
      >
        <span class="iconfont icon-sousuo" @click.stop="isFind = !isFind"></span
      ></el-tooltip>
      <el-form v-if="isFind">
        <el-form-item prop="destination">
          <div class="item-group">
            <el-autocomplete
              popper-class="search"
              :popper-append-to-body="false"
              class="autocomplete"
              v-model="destination.name"
              :fetch-suggestions="handle_query_address_async"
              placeholder="请输入目标位置"
              :trigger-on-focus="false"
              @select="handle_select_address"
              clearable
            >
              <template slot-scope="{ item }">
                <div>
                  <span style="font-size: 14px; color: #fff">{{
                    item.name
                  }}</span>
                  <span
                    style="font-size: 12px; color: #999; margin-left: 12px"
                    >{{ item.address }}</span
                  >
                </div>
              </template>
            </el-autocomplete>
            <!-- <div @click="handle_pick" class="el-icon-location-outline"></div> -->
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { Utils } from "@/lib/cesium";
let handler = null;
let data_srouce = null;
let location_icon = null;
export default {
  data() {
    return {
      isFind: false,
      routeColor: false,
      input: "",
      destination: {
        name: "",
        lng: 0,
        lat: 0,
      },
    };
  },
  components: {},
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    "$route.path": {
      handler(value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
  },
  methods: {
    handle_query_address_async(address, cb) {
      API.MAP.Geo({ keywords: address })
        .then((res) => {
          cb(res.data.data.rows);
        })
        .catch((e) => cb([]));
    },
    handle_select_address(item) {
      if(window.base_layer.SATELLITE.show == true){
    window.base_layer.SATELLITE.show = false
    window.base_layer.BASIC.show = false
    setTimeout(() => {
      window.base_layer.SATELLITE.show = true
    }, 500);
    }
      let viewer = window.viewer;
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      // 模式关闭
      window.plate_data_source && (window.plate_data_source.show = false);
      if (item) {
        let location = item.location.split(",");
        this.destination.name = item.name;
        this.destination.lng = Number(location[0]);
        this.destination.lat = Number(location[1]);

        let cesium_layer = this.g_cesium_layer();
        let viewer = cesium_layer.viewer();
        let potiion = Utils.transformWGS842Cartesian({
          lng: this.destination.lng,
          lat: this.destination.lat,
          alt: 1000,
        });
        viewer.camera.flyTo({
          destination: potiion,
        });
        this.init_location_icon(potiion);
      }
    },
    init_location_icon(position) {
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();

      if (location_icon) {
        // location_icon.position = position;
        viewer.entities.remove(location_icon);
      location_icon = null;
      } 
      // else {
        location_icon = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(
              Number(this.destination.lng),
              Number(this.destination.lat)
            ),
          billboard: {
            width: 36,
            height: 36,
            image: require("@/assets/images/icons/icon_location.png"),
            // horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            // verticalOrigin: Cesium.VerticalOrigin.Bottom,
            // heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
            distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
          },
        });
        viewer.camera.moveEnd.addEventListener(()=>{
          console.log(this.destination.lng);
          location_icon.potiion = Cesium.Cartesian3.fromDegrees(
              Number(this.destination.lng),
              Number(this.destination.lat)
            )
        })
      // }
    },
    destroy_location_icon() {
      if (!location_icon) {
        return location_icon;
      }
      let cesium_layer = this.g_cesium_layer();
      let viewer = cesium_layer.viewer();
      viewer.entities.remove(location_icon);
      location_icon = null;
    },
  },
  mounted() {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".cpt-select"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isFind = false;
      }
    });
  },
  computed: {
    cesium_layer() {
      return this.g_cesium_layer();
    },
  },
  destroyed() {
    // this.destroy_cesium_handler();
    // this.clear_entities();
  },
  inject: ["g_cesium_layer"],
};
</script>

<style lang="scss" scoped>
.cpt-select {
  text-align: right;
  /deep/.el-autocomplete-suggestion li:hover {
    background-color: #a1b9ee;
  }
  .select_box {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    background: rgba(22, 29, 45, 0.58);
    // border: 1px solid #04d5da;
    border-radius: 2px;
    text-align: center;
    line-height: 48px;
    cursor: pointer;
    &.active {
      // width: 412px;
      text-align: left;
      box-sizing: border-box;
      padding-left: 10px;
      height: 50.4px;
    }
  }
}
.icon-sousuo {
  display: inline-block;
  width: 48px;
  height: 48px;
  cursor: pointer;
  &::before {
    font-size: 24px;
    content: "\e6d5";
    color: #00ffff;
  }
}
/deep/ .el-input__inner {
  padding-right: 30px;
  width: 359px !important;
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4;
  letter-spacing: 0;
  text-align: left;
  font-weight: 400;
  background: transparent !important;
  border: 0;
}
/deep/ .el-form-item {
  margin-bottom: 22px;
  width: 50%;
  position: absolute;
  top: 5%;
  left: 12%;
}
/deep/ input {
  font-family: MicrosoftYaHei;
  font-size: 16px;
  color: #5baad4 !important;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
}
::v-deep {
  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }
  }
}
.color{
  // border: 1px solid #fff !important;
  /deep/ .icon-sousuo::before{
    color: #fff;
  }
  &:hover{
  border: 1px solid #43deff !important;
  /deep/ .icon-sousuo::before{
    color: #43deff;
  }
  }
}
</style>
<style lang="scss" scoped>
.search {
  .el-scrollbar {
    .el-autocomplete-suggestion li:hover {
      background-color: #34466e !important;
    }
  }
}
.el-autocomplete{
  border: 1px solid #04d5da;
  right:368px;
  background: rgba(22, 29, 45, 0.58);
}

</style>