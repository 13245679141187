<template>
  <div class="check">
    <div class="check_left">
      <div class="check_left_head mt20">
        <div class="iconfont icon-wurenji1 mr10"></div>
        飞行检查清单
      </div>
      <div class="line"></div>
      <div class="check_left_list">
        <div
          @click="checkType = '设备信息'"
          :class="checkType == '设备信息' ? 'selct' : ''"
        >
          设备信息
        </div>
        <div
          @click="checkType = '通信自检'"
          :class="checkType == '通信自检' ? 'selct' : ''"
        >
          通信自检
        </div>
        <div
          @click="checkType = '人工自检'"
          :class="checkType == '人工自检' ? 'selct' : ''"
        >
          人工自检
        </div>
      </div>
    </div>
    <div class="check_right">
      <div class="form">
        <div class="dev" v-if="checkType == '设备信息'">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="130px"
          >
            <el-form-item label="无人机名称:" prop="deviceName">
              <el-select
                v-model="ruleForm.deviceName"
                clearable
                placeholder="请选择无人机"
                @change="onChange"
              >
                <el-option
                  class="option"
                  v-for="item in droneList"
                  :key="item.deviceHardId"
                  :label="item.deviceName"
                  :value="item.deviceHardId"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="无人机编号:" prop="deviceHardId">{{
              ruleForm.deviceHardId || "暂无"
            }}</el-form-item>
            <el-form-item label="挂载名称:" prop="mountName">
              <el-select
                v-model="ruleForm.mountName"
                clearable
                multiple
                placeholder="请选择挂载"
              >
                <el-option
                  class="option"
                  v-for="item in mountLists"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                >
                  <!-- <el-checkbox class="checked" :label="item.name" @change="onChange"></el-checkbox> -->
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="飞行建议:" prop="flyingAdvice">
              <el-select
                v-model="ruleForm.flyingAdvice"
                clearable
                placeholder="请选择建议"
                :class="
                  ruleForm.flyingAdvice == 1
                    ? 'yes'
                    : ruleForm.flyingAdvice == 2
                    ? 'no'
                    : ''
                "
              >
                <el-option
                  class="option"
                  v-for="item in addList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="当前时间:" prop="flightTime">{{
              ruleForm.flightTime || "暂无"
            }}</el-form-item>
            <el-form-item label="返航电压:" prop="returnPower">{{
              ruleForm.returnPower || "暂无"
            }}</el-form-item>
            <el-form-item label="返航高度:" prop="returnAltitude">{{
              ruleForm.returnAltitude || "暂无"
            }}</el-form-item>
            <el-form-item label="基站干扰值:" prop="stationInterfere">{{
              ruleForm.stationInterfere || "暂无"
            }}</el-form-item>
          </el-form>
        </div>
        <div class="txinfo" v-else-if="checkType == '通信自检'">
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="130px"
          >
            <el-form-item label="图传数:" prop="pictureTransmission"
              ><span v-if="ruleForm.pictureTransmission == 1" class="c1dd"
                >正常</span
              >
              <span v-else class="cff6">异常</span></el-form-item
            >
            <el-form-item label="平台控制:" prop="platformControl"
              ><span v-if="ruleForm.platformControl == 1" class="c1dd"
                >正常</span
              >
              <span v-else class="cff6">异常</span></el-form-item
            >
            <el-form-item label="基站信号值:" prop="signalValue"
              ><span v-if="ruleForm.signalValue == 1" class="c1dd">正常</span>
              <span v-else class="cff6">异常</span></el-form-item
            >
            <el-form-item label="定位信息:" prop="locationInfo"
              ><span v-if="ruleForm.locationInfo == 1" class="c1dd">正常</span>
              <span v-else class="cff6">异常</span></el-form-item
            >
          </el-form>
        </div>
        <div class="people" v-else-if="checkType == '人工自检'">
          <div class="tab">
            <div
              class="tabS"
              :style="
                tabname == 1
                  ? 'color: #00ffff;border-bottom: solid 1px  #00F8FF;'
                  : ''
              "
              @click="() => (tabname = 1)"
            >
              遥控
            </div>
            <div
              class="tabS"
              :style="
                tabname == 2
                  ? 'color: #00ffff;border-bottom: solid 1px  #00F8FF;'
                  : ''
              "
              @click="() => (tabname = 2)"
            >
              机身
            </div>
            <div
              class="tabS"
              :style="
                tabname == 3
                  ? 'color: #00ffff; border-bottom: solid 1px  #00F8FF;'
                  : ''
              "
              @click="() => (tabname = 3)"
            >
              控制台
            </div>
          </div>
          <div class="formCheck">
            <el-form
              v-if="tabname == 1"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="130px"
            >
              <el-form-item label="遥控器电量" prop="remotePower">
                <el-switch v-model="ruleForm.remotePower"></el-switch>
              </el-form-item>
              <el-form-item label="遥控响应" prop="controlResponse">
                <el-switch v-model="ruleForm.controlResponse"></el-switch>
              </el-form-item>
              <el-form-item label="GPS杆" prop="deviceGps">
                <el-switch v-model="ruleForm.deviceGps"></el-switch>
              </el-form-item>
              <el-form-item label="遥控器功能键" prop="remoteFunction">
                <el-switch v-model="ruleForm.remoteFunction"></el-switch>
              </el-form-item>
            </el-form>
            <el-form
              v-else-if="tabname == 2"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="130px"
            >
              <el-form-item label="机架" prop="deviceFrame">
                <el-switch v-model="ruleForm.deviceFrame"></el-switch>
              </el-form-item>
              <el-form-item label="桨叶" prop="deviceLeaf">
                <el-switch v-model="ruleForm.deviceLeaf"></el-switch>
              </el-form-item>
              <el-form-item label="云台紧固" prop="deviceYuntai">
                <el-switch v-model="ruleForm.deviceYuntai"></el-switch>
              </el-form-item>
              <el-form-item label="机身螺丝" prop="deviceScrew">
                <el-switch v-model="ruleForm.deviceScrew"></el-switch>
              </el-form-item>
              <el-form-item label="机臂" prop="deviceArm">
                <el-switch v-model="ruleForm.deviceArm"></el-switch>
              </el-form-item>
              <el-form-item label="天线" prop="deviceAntenna">
                <el-switch v-model="ruleForm.deviceAntenna"></el-switch>
              </el-form-item>
            </el-form>
            <div class="leftImg pr" v-if="tabname == 2 && cateName == '六旋翼'">
              <!-- 机身螺丝 -->
              <span class="dib pa left-20"
                ><span v-if="ruleForm.deviceScrew" class="c1dd">机身螺丝</span>
                <span v-else class="cff6">机身螺丝</span></span
              >
              <!-- 桨叶 -->
              <span class="dib pa top-20 right20"
                ><span v-if="ruleForm.deviceLeaf" class="c1dd">桨叶</span>
                <span v-else class="cff6">桨叶</span></span
              >
              <!-- 机架 -->
              <span class="dib pa bottom30 right50"
                ><span v-if="ruleForm.deviceFrame" class="c1dd">机架</span>
                <span v-else class="cff6">机架</span></span
              >
              <!-- 机臂 -->
              <span class="dib pa bottom30 right10"
                ><span v-if="ruleForm.deviceArm" class="c1dd">机臂</span>
                <span v-else class="cff6">机臂</span></span
              >
              <!-- 天线 -->
              <span class="dib pa bottom45 left-10"
                ><span v-if="ruleForm.deviceAntenna" class="c1dd">天线</span>
                <span v-else class="cff6">天线</span></span
              >
              <!-- 云台紧固 -->
              <span class="dib pa bottom25 left20"
                ><span v-if="ruleForm.deviceYuntai" class="c1dd">云台紧固</span>
                <span v-else class="cff6">云台紧固</span></span
              >
              <img src="~@/assets/images/wrj3.png" alt="" />
            </div>
            <div class="leftImg pr" v-if="tabname == 2 && cateName == '四旋翼'">
              <!-- 机身螺丝 -->
              <span class="dib pa left-40 top70"
                ><span v-if="ruleForm.deviceScrew" class="c1dd">机身螺丝</span>
                <span v-else class="cff6">机身螺丝</span></span
              >
              <!-- 桨叶 -->
              <span class="dib pa top5 left45"
                ><span v-if="ruleForm.deviceLeaf" class="c1dd">桨叶</span>
                <span v-else class="cff6">桨叶</span></span
              >
              <!-- 机架 -->
              <span class="dib pa bottom30 right50"
                ><span v-if="ruleForm.deviceFrame" class="c1dd">机架</span>
                <span v-else class="cff6">机架</span></span
              >
              <!-- 机臂 -->
              <span class="dib pa top10 left100"
                ><span v-if="ruleForm.deviceArm" class="c1dd">机臂</span>
                <span v-else class="cff6">机臂</span></span
              >
              <!-- 天线 -->
              <span class="dib pa top20 right10"
                ><span v-if="ruleForm.deviceAntenna" class="c1dd">天线</span>
                <span v-else class="cff6">天线</span></span
              >
              <!-- 云台紧固 -->
              <span class="dib pa bottom40 left10"
                ><span v-if="ruleForm.deviceYuntai" class="c1dd">云台紧固</span>
                <span v-else class="cff6">云台紧固</span></span
              >
              <img src="~@/assets/images/wrj4.png" alt="" />
            </div>
            <el-form
              v-else-if="tabname == 3"
              :model="ruleForm"
              :rules="rules"
              ref="ruleForm"
              label-width="130px"
            >
              <el-form-item label="动力电池" prop="powerBattery">
                <el-switch v-model="ruleForm.powerBattery"></el-switch>
              </el-form-item>
              <el-form-item label="无人机电池" prop="deviceBattery">
                <el-switch v-model="ruleForm.deviceBattery"></el-switch>
              </el-form-item>
              <el-form-item label="飞行姿态稳定性" prop="flightStable">
                <el-switch v-model="ruleForm.flightStable"></el-switch>
              </el-form-item>
              <el-form-item label="遥控器模式" prop="remoteModel">
                <el-switch v-model="ruleForm.remoteModel"></el-switch>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <div class="Btn btn1" v-if="checkType == '人工自检'" @click="submit">
        完成
      </div>
      <div class="Btn" v-else @click="next">下一步</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      ruleForm: {
        deviceScrew: false, //机身螺丝1
        deviceAntenna: false, //天线1
        deviceYuntai: false, //云台紧固1
        deviceGps: false, //gps杆1
        deviceLeaf: false, //桨叶
        deviceArm: false, //机臂1
        deviceFrame: false, //机架1
        deviceBattery: false, //无人机电池1
        flightStable: false, //飞行姿态稳定性1
        controlResponse: false, //遥控响应1
        remoteModel: false, //遥控器模式1
        remotePower: false, //遥控器电量1
        remoteFunction: false, //遥控器功能键1
        powerBattery: false, //动力电池1
        remoteLink: null, //遥控器连接
        locationInfo: null, //定位信息
        signalValue: null, //信号值
        platformControl: null, //平台控制
        pictureTransmission: null, //图数传
        // returnSpeed: null, //返航速度
        returnAltitude: null, //返航高度
        returnPower: null, //返航电压
        flightTime: this.time(), //飞行时间
        deviceHardId: null,
        deviceName: null,
        mountName: null,
        stationInterfere: "", //基站干扰值
        flyingAdvice: null, //飞行建议
      },
      tabname: 1,
      cateName: "",
      rules: {},
      checkType: "设备信息",
      droneList: [],
      mountLists: [],
      addList: [
        {
          id: 1,
          name: "适宜飞行",
        },
        {
          id: 2,
          name: "不宜飞行",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["user_info"]),
  },
  mounted() {
    this.getMountList();
    this.getDroneList();
  },
  methods: {
    async getDroneList() {
      let res = await API.DEVICE.ListDrone({ pageSize: 10000 });
      this.droneList = res.data || [];
      console.log(res, "res");
    },
    next() {
      if (this.checkType == "设备信息") {
        this.checkType = "通信自检";
      } else {
        this.checkType = "人工自检";
      }
    },
    time() {
      let now = new Date();
      let year = now.getFullYear(); //得到年份
      let month =
        now.getMonth() + 1 > 9
          ? now.getMonth() + 1
          : "0" + (now.getMonth() + 1); //得到月份
      let date = now.getDate() > 9 ? now.getDate() : "0" + now.getDate(); //得到日期
      // let day = now.getDay();//得到周几
      let hour = now.getHours(); //得到小时数
      let minute =
        now.getMinutes().toString().length > 1
          ? now.getMinutes()
          : 0 + now.getMinutes().toString(); //得到分钟数
      let second = now.getSeconds(); //得到秒数
      return year + "-" + month + "-" + date + " " + hour + ":" + minute;
    },
    onChange(val) {
      this.ruleForm.stationInterfere = "";
      this.info(val);
    },
    async info(val) {
      let res = await API.ACCIDENT.getMountInfoByDeviceHardId({
        deviceHardId: val,
      });
      let res2 = await API.ACCIDENT.getSNIRByDeviceHardId(val);
      this.ruleForm.deviceHardId = val;
      res2.forEach((val, index) => {
        if (val.sinr) {
          this.ruleForm.stationInterfere =
            this.ruleForm.stationInterfere +
            val.sinr +
            (res2.length - 1 == index ? "" : ";");
        }
      });
      this.cateName = res2[0].cateName;
      this.ruleForm.returnPower = res.uavInfo.customData.returnVoltage;
      this.ruleForm.returnAltitude = res.uavInfo.customData.returnAltitude;
      this.ruleForm.pictureTransmission =
        res.uavInfo.customData.transportStatus;
      this.ruleForm.platformControl = res.uavInfo.customData.controlStatus;
      this.ruleForm.signalValue = res.uavInfo.customData.signalStatus;
      this.ruleForm.locationInfo = res.uavInfo.customData.positionStatus;
    },
    async getMountList() {
      let res = await API.DEVICE.ListMount();
      this.mountLists = res || [];
    },
    async submit() {
      if (!this.ruleForm.deviceHardId) {
        return this.$message.info("请选择无人机");
      }
      this.droneList.forEach((val) => {
        if (val.deviceHardId == this.ruleForm.deviceHardId) {
          this.ruleForm.deviceName = val.deviceName;
        }
      });
      let mountName = "";
      this.ruleForm.mountName.forEach((val, index) => {
        mountName =
          mountName +
          val +
          (this.ruleForm.mountName.length - 1 == index ? "" : ";");
      });
      this.ruleForm.mountName = mountName;
      this.ruleForm.deviceScrew = Number(this.ruleForm.deviceScrew);
      this.ruleForm.deviceAntenna = Number(this.ruleForm.deviceAntenna);
      this.ruleForm.deviceYuntai = Number(this.ruleForm.deviceYuntai);
      this.ruleForm.deviceGps = Number(this.ruleForm.deviceGps);
      this.ruleForm.deviceLeaf = Number(this.ruleForm.deviceLeaf);
      this.ruleForm.deviceArm = Number(this.ruleForm.deviceArm);
      this.ruleForm.deviceFrame = Number(this.ruleForm.deviceFrame);
      this.ruleForm.deviceBattery = Number(this.ruleForm.deviceBattery);
      this.ruleForm.flightStable = Number(this.ruleForm.flightStable);
      this.ruleForm.controlResponse = Number(this.ruleForm.controlResponse);
      this.ruleForm.remoteModel = Number(this.ruleForm.remoteModel);
      this.ruleForm.remotePower = Number(this.ruleForm.remotePower);
      this.ruleForm.remoteFunction = Number(this.ruleForm.remoteFunction);
      this.ruleForm.powerBattery = Number(this.ruleForm.powerBattery);
      let data = {
        deviceName: this.ruleForm.deviceName,
        deviceSerial: this.ruleForm.deviceHardId,
        mount: this.ruleForm.mountName,
        flightAdvice: this.ruleForm.flyingAdvice == 0 ? "适合飞行" : "不宜飞行",
        returnVoltage: this.ruleForm.returnPower || "",
        returnHeight: this.ruleForm.returnAltitude || "",
        interferenceValue: this.ruleForm.stationInterfere,
        imageData: this.ruleForm.pictureTransmission,
        platformControl: this.ruleForm.platformControl,
        signalValue: this.ruleForm.signalValue,
        positionInfo: this.ruleForm.locationInfo,
        remoteKey: this.ruleForm.remoteFunction,
        gpsRod: this.ruleForm.deviceGps,
        remoteBattery: this.ruleForm.remotePower,
        remoteResponse: this.ruleForm.controlResponse,
        chassis: this.ruleForm.deviceFrame,
        paddle: this.ruleForm.deviceLeaf,
        fixedGimbal: this.ruleForm.deviceYuntai,
        bodyScrew: this.ruleForm.deviceScrew,
        arm: this.ruleForm.deviceArm,
        antenna: this.ruleForm.deviceAntenna,
        powerBattery: this.ruleForm.powerBattery,
        uavBattery: this.ruleForm.deviceBattery,
        flightStability: this.ruleForm.flightStable,
        remoteModel: this.ruleForm.remoteModel,
      };
      var abc = "";
      for (let key in data) {
        abc += "&" + key + "=" + data[key];
      }
      let url = `${process.env.VUE_APP_BASE_URL}/hawksystemserver/homeExcelReport/exportFlightCheck?FLYINGSESSIONID=${this.user_info.FLYINGSESSIONID}&mmc-identity=${this.user_info["mmc-identity"]}${abc}`;
      let res = await API.HOME.insertUav(this.ruleForm);
      this.$el_confirm(
        "是否导出模板？",
        () => {
          const fileName = url;
          if ("download" in document.createElement("a")) {
            let downloadElement = document.createElement("a");
            downloadElement.href = url;
            downloadElement.download = fileName;
            document.body.appendChild(downloadElement);
            downloadElement.click();
            document.body.removeChild(downloadElement);
          }
        },
        () => {}
      );
      localStorage.setItem("flag", 1);
      this.$emit("handle_SecurityCheck");
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.check {
  // background: url("../../assets/bg.png") ;
  min-height: 450px;
  min-width: 670px;
  z-index: 10;
  border-radius: 10px;
  position: fixed;
  left: 596px;
  top: 15%;
  display: flex;
  .check_left {
    width: 194px;
    // height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: rgba(11, 23, 49, 0.89);
    border-radius: 10px 0 0 10px;
    .check_left_head {
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      text-shadow: 1px 1px 0 rgba(5, 3, 35, 0.7);
      font-weight: 700;
    }
    .line {
      margin: 10px 0;
      width: 137px;
      height: 1px;
      background-image: linear-gradient(
        90deg,
        rgba(7, 61, 98, 0) 0%,
        rgba(25, 98, 136, 0.34) 25%,
        rgba(44, 135, 176, 0.7) 51%,
        rgba(26, 100, 139, 0.37) 74%,
        rgba(7, 61, 98, 0) 100%
      );
      border-radius: 0 10px 0 0;
    }
    .check_left_list {
      width: 100%;
      font-family: MicrosoftYaHei;
      font-size: 16px;
      color: rgba(146, 217, 255, 0.7);
      letter-spacing: 0;
      font-weight: 400;
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        padding: 10px 0;
      }
      .selct {
        width: 100%;
        color: #00ffff;
        text-align: center;
        background-image: linear-gradient(
          90deg,
          rgba(7, 61, 98, 0) 0%,
          rgba(25, 98, 136, 0.34) 25%,
          rgba(44, 135, 176, 0.7) 51%,
          rgba(26, 100, 139, 0.37) 74%,
          rgba(7, 61, 98, 0) 100%
        );
      }
    }
  }
  .check_right {
    width: 479px;
    // height: 100%;
    background: rgba(5, 29, 60, 0.8);
    border-radius: 0 10px 10px 0;
    display: flex;
    flex-direction: column;
    .form {
      border-radius: 10px;
      .dev,
      .txinfo {
        padding: 20px 20px 0 0;
        margin: 30px 30px 0 30px;
      }
      .people {
        display: flex;
        flex-direction: column;

        .tab {
          background: rgb(20, 30, 54);
          opacity: 1;
          width: 100%;
          height: 36px;
          line-height: 36px;
          display: flex;
          color: #ffffff;
          .tabS {
            width: 30%;

            text-align: center;
            line-height: 30px;
          }
        }
        .formCheck {
          display: flex;
        }
      }
      .c1dd {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #31db24;
        letter-spacing: 0;
        font-weight: 400;
      }
      .cff6 {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #fd4d4d;
        letter-spacing: 0;
        font-weight: 400;
      }
    }
    .btn1 {
      position: absolute;
      bottom: 30px;
    }
    .Btn {
      margin-bottom: 30px;
      align-self: center;
      width: 90px;
      height: 34px;
      text-align: center;
      line-height: 34px;
      background: rgba(0, 34, 140, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 20px 0 #00b6ff;
      border-radius: 6px;
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.el-form-item {
  margin-bottom: 10px;
}
/deep/ .el-form-item__label {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 700;
}
/deep/ .el-select .el-input__inner {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
  width: 184px;
  height: 32px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: rgba(146, 217, 255, 0.34);
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.yes .el-input__inner {
  font-family: PangMenZhengDao;
  font-size: 12px;
  color: #23cc16;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.no .el-input__inner {
  font-family: PangMenZhengDao;
  font-size: 12px;
  color: #f03a3a;
  letter-spacing: 0;
  font-weight: 400;
}
.el-select {
  width: 184px;
}
/deep/.el-select-dropdown .el-scrollbar {
  background: rgba(11, 23, 49, 0.8);
  border: 1px solid rgba(14, 123, 117, 0.63);
}
/deep/.el-switch.is-checked .el-switch__core {
  background: #23cc16;
  box-shadow: 0 1px 2px 0 rgba(5, 45, 72, 0.5);
  border: none;
  // border-radius: 7px;
}
/deep/.el-switch__core {
  background: #dc4141;
  box-shadow: 0 1px 2px 0 rgba(5, 45, 72, 0.5);
  border: none;
}
/deep/.el-form-item__content {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #92d9ff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.el-checkbox__label {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  // color: #00F5FF;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.el-checkbox__inner {
  width: 12px;
  height: 12px;
  // border: 1px solid #00F5FF;
}
/deep/.el-checkbox__input.is-checked .el-checkbox__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #00f5ff;
}
/deep/.el-checkbox__inner::after {
  border: 1px solid #00f5ff;
  border-left: 0;
  border-top: 0;
}
/deep/.el-checkbox__input.is-checked + .el-checkbox__label {
  color: #00f5ff;
}
/deep/.el-select .el-tag {
  border: none;
  background: rgba(0, 0, 0, 0);
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #00f5ff;
  letter-spacing: 0;
  font-weight: 400;
}
/deep/.checked {
  // width: 100%;
}
.leftImg {
  margin: 76px 0 76px 60px;
}
</style>
