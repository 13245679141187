<!-- 人脸识别弹框 -->
<template>
  <div class="face_recognition">
    <div class="chief">
      <!-- 左 -->
      <div class="zuo">
        <div class="warning">预警信息</div>
        <div class="infoBox">
          <!-- 卡片模板 -->
          <div class="card" v-for="(item, index) in child" :key="index" @click="xianxi(item)">
            <div class="photograph">
              <div class="img">
                <img :src="item.face_image_uri_get" alt="" />
                <div class="contrast Capture">抓拍照片</div>
              </div>
              <div class="img">
                <img :src="item.picture_uri_get" alt="" />
                <div class="contrast KeepFile">公安局留档照片</div>
              </div>
            </div>
            <div>
              <div class="general">
                <div class="red" style="margin-right: 15px">{{ item.busLabel }}</div>
                <div class="nowarp">
                  <span class="fontOne">匹配度</span>
                  <span class="fontOne" style="margin: 0 2px">:</span>
                  <span class="red" style="font-size: 18px">
                   000
                  </span>
                </div>
              </div>
              <div style="margin: 14px 0; display: flex" class="nowarp">
                <span class="fontOne nowarp">姓名</span>
                <span class="fontOne" style="margin: 0 2px">:</span>
                <span class="fontTwo">{{ item.NAME }} </span>
              </div>
              <div class="nowarp">
                <span class="fontOne">时间</span>
                <span class="fontOne" style="margin: 0 2px">:</span>
                <span class="fontTwo">{{ item.address }} </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 左 -->
      <!-- 中 -->
      <div class="zhong">
        <!-- 轮播图 -->
        <el-carousel height="100%"  @change="lunbotu" :autoplay=false ref="huandeng">
          <el-carousel-item v-for="(item, index) in arr" :key="index">
            <img :src="item.storagePath" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 中 -->
      <!-- 右 -->
      <div class="you">
        <div class="warning" style="margin-bottom: 0">人员信息对比</div>
        <div>
          <div class="photograph">
            <div class="img" style="height: 167px">
              <img :src="danren.face_image_uri_get" alt="" />
              <div
                class="contrast Capture"
                style="height: 34px; line-height: 34px"
              >
                抓拍照片
              </div>
            </div>
            <div class="img" style="height: 167px">
              <img :src="danren.picture_uri_get" alt="" />
              <div
                class="contrast KeepFile"
                style="height: 34px; line-height: 34px"
              >
                公安局留档照片
              </div>
            </div>
          </div>
          <div class="resemblance nowarp">
            <span class="KeepFile nowarp" style="margin: 0 16px 0 0"
              >相似度</span
            >
            <span class="fontEight"> resemblance </span>
          </div>
          <div style="margin: 51px 0 0 0; display: flex">
            <div class="fontNine" style="margin: 0 0 0 20px">
              <div class="jianju nowarp">
                <span>姓名</span>
                <span>:</span>
              </div>
              <div class="jianju nowarp">
                <span>出生年份</span>
                <span class="colon">:</span>
              </div>
              <div class="jianju nowarp">
                <span>发现时间</span>
                <span class="colon">:</span>
              </div>
              <div class="jianju nowarp">
                <span>发现地点</span>
                <span class="colon">:</span>
              </div>
              <div class="jianju nowarp">
                <span>身份证号</span>
                <span class="colon">:</span>
              </div>
              <div class="jianju nowarp">
                <span>AI识别</span>
                <span class="colon">:</span>
              </div>
            </div>
            <div>
              <div class="fontTen jianju nowarp">{{ danren.NAME || '暂无' }}</div>
              <div class="fontTen jianju nowarp">
                {{ danren.born_year || '暂无' }}
              </div>
              <div class="fontTen jianju nowarp">
                {{ danren.address || '暂无' }}
              </div>
              <div class="fontTen jianju nowarp">
                personalInformation.theSpot
              </div>
              <div class="fontTen jianju nowarp">
                {{ danren.person_id || '暂无' }}
              </div>
              <div class="jianju kuang red nowarp">
                personalInformation.ruralCadastral
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 右 -->
    </div>
    <!-- 下面 -->
    <div class="bjs">
      <div class="articleInformation">
        <div>
          <span class="fontFive" style="margin: 0 73px 0 16px">今日抓拍</span>
          <span class="fontSix">共</span>
          <span class="fontSeven" style="margin: 0 8px"> today </span>
          <span class="fontSix">次抓拍</span>
        </div>
        <div style="padding: 0 8px 0 0">
          <span class="fontSix">历史抓拍</span>
          <span class="fontSix" style="margin: 0 2px">:</span>
          <span class="fontSeven" style="margin: 0 8px"> history </span>
          <span class="fontSix">次</span>
        </div>
      </div>
      <div style="display: flex; padding: 0 0px" class="special">
        <!-- 卡片模板 -->
        <div class="kapian">
          <div class="gallery-thumbs" v-for="(item, index) in arr" :key="index" :class="{is_active:index == numId}" @click="yujing(index)">
            <div class="todayPhoto">
              <!-- <div class="imgItem"> -->
              <img :src="item.storagePath" alt="" style="height: 100%; width: 100%" />
              <!-- </div> -->

              <div class="exclamationPoint fontFive">
                <i class="el-icon-warning-outline"></i>
              </div>
              <div class="photoNumber">照片{{ index + 1 }}</div>
            </div>
            <div style="margin-top: 8px" class="fontOne">
              <span>地点</span>
              <span style="margin: 0 4px">:</span>
              <span class="font"> todayCapture.site </span>
            </div>
            <div class="fontOne" style="margin-top: 4p">
              <span>时间</span>
              <span style="margin: 0 4px">:</span>
              <span class="font">{{ item.addTime }}</span>
            </div>
          </div>
        </div>
        <!-- 上下翻页 -->
        <div class="pageNumber">
          <div class="jiantou" @click="shang">
            <i class="el-icon-arrow-up"></i>
          </div>
          <div>
            <div class="fontThre">{{ dangqianye }}</div>
            <div>/</div>
            <div class="fontOneFour">{{ zongye }}</div>
          </div>
          <div class="jiantou" @click="xiafanye">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/assets/ligature.js";
import API from "@/api/modules/aiUse";
export default {
  data() {
    return {
      arr: [],
      // 试验人脸
      ren: "001a002e344e50142039384e00000000",
      dangqianye:0,
      zongye:0,
      numId:0,//轮播图对应今日抓拍
      child:[],//预警信息
      danren:{},//人员信息对比
    };
  },
  mounted() {
    API.LicensePlateRecognitionList({
      deviceHardId: localStorage.getItem("deviceHardId"),
      // deviceHardId: this.ren,
    }).then((res) => {
      this.arr = res.data;
      this.dangqianye = res.totalPage
      this.zongye = res.pageNo
      console.log(res, "人脸数据11111");
    });
  },
  methods: {
    lunbotu(index){
      this.numId = index
      this.child = this.arr[index].child[0].shijuFaceResults
      this.danren = this.child[0]
    },
    // 预警信息
    yujing(index){
      this.numId = index
      this.child = this.arr[index].child[0].shijuFaceResults
      this.danren = this.child[0]
      this.$refs.huandeng.setActiveItem(index);
    },
    // 详细信息
    xianxi(item){
      this.danren = item
    },
     // 上翻页
    shang(){
      
    },
    // 下翻页
    xiafanye(){

    }
  },
};
</script>

<style lang="scss" scoped>
.face_recognition {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  .chief {
    display: flex;
  }
  .zuo {
    width: 20%;
    background: #161d2d;
    border: 1px solid #178890;
    border-radius: 0 4px 4px 0;
    background-color: #0a0d18;
    overflow: hidden;
    overflow-y: auto;
    .warning {
      height: 32px;
      margin: 12px 0 14px 0;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #92d9ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 700;
      background: url(http://116.62.122.225/hawk_ai/static/img/plate.9235b6b2.gif)
        no-repeat;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .infoBox {
      display: flex;
      align-items: center;
      flex-direction: column;
      width: 95%;
      padding: 0 8px;
      height: 500px;
      overflow: scroll;
      ::-webkit-scrollbar {
        width: 0 !important;
      }
      .card {
        width: 98%;
        height: 228px;
        background-image: linear-gradient(179deg, #323644 0%, #131622 100%);
        border: 1px solid #40bddf;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
          inset 0 1px 2px 0 rgba(46, 255, 246, 0.15),
          inset 0 -1px 0 0 rgba(24, 41, 117, 0.5);
        border-radius: 4px;
        padding: 8px;
        margin-bottom: 8px;
        cursor: pointer;
        .photograph {
          display: flex;
          justify-content: space-between;
          .img {
            width: 49%;
            border: 1px solid red;
            height: 120px;
            position: relative;
            img {
              width: 100%;
              height: 100%;
            }
            .contrast {
              position: absolute;
              bottom: 0;
              left: 0;
              width: 100%;
              text-align: center;
            }
            .Capture {
              opacity: 0.8;
              background: #0c1120;
              opacity: 0.75;
              font-family: MicrosoftYaHeiUI;
              font-size: 12px;
              color: #aee9ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
            .KeepFile {
              opacity: 0.8;
              background: #0c1120;
              opacity: 0.75;
              font-family: MicrosoftYaHeiUI;
              font-size: 12px;
              color: #aee9ff;
              letter-spacing: 0;
              text-align: center;
              font-weight: 400;
            }
          }
        }
        .general {
          display: flex;
          align-items: flex-end;
          padding-top: 8px;
          .red {
            font-family: MicrosoftYaHeiUI;
            font-size: 14px;
            color: #ff4a4a;
            letter-spacing: 0;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .fontOne {
            opacity: 0.7;
            font-family: MicrosoftYaHeiUI;
            font-size: 12px;
            color: #7fabbc;
            letter-spacing: 0;
            font-weight: 400;
          }
          .fontTwo {
            font-family: MicrosoftYaHeiUI;
            font-size: 14px;
            color: #aee9ff;
            letter-spacing: 0;
            font-weight: 400;
          }
        }
      }
    }
  }
  .zhong {
    width: 50%;
    background: #161d2d;
    border: 1px solid #178890;
    border-radius: 0 4px 4px 0;
    background-color: #0a0d18;
  }
  .you {
    width: 30%;
    background: #161d2d;
    border: 1px solid #178890;
    border-radius: 0 4px 4px 0;
    background-color: #0a0d18;
    overflow: hidden;
    overflow-y: auto;
    .warning {
      height: 32px;
      margin: 12px 0 14px 0;
      font-family: MicrosoftYaHei-Bold;
      font-size: 16px;
      color: #92d9ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 700;
      background: url(http://116.62.122.225/hawk_ai/static/img/plate.9235b6b2.gif)
        no-repeat;
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
    .photograph {
      display: flex;
      justify-content: space-between;
      .img {
        width: 49%;
        border: 1px solid red;
        height: 120px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .contrast {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          text-align: center;
        }
        .Capture {
          opacity: 0.8;
          background: #0c1120;
          opacity: 0.75;
          font-family: MicrosoftYaHeiUI;
          font-size: 12px;
          color: #aee9ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
        .KeepFile {
          opacity: 0.8;
          background: #0c1120;
          opacity: 0.75;
          font-family: MicrosoftYaHeiUI;
          font-size: 12px;
          color: #aee9ff;
          letter-spacing: 0;
          text-align: center;
          font-weight: 400;
        }
      }
    }
    .resemblance {
      border: 1px solid #244d5f;
      height: 48px;
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      .KeepFile {
        opacity: 0.8;
        background: #0c1120;
        opacity: 0.75;
        font-family: MicrosoftYaHeiUI;
        font-size: 12px;
        color: #aee9ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
      .fontEight {
        font-family: MicrosoftYaHeiUI;
        font-size: 32px;
        color: #ff4a4a;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
    .fontNine {
      opacity: 0.7;
      font-family: MicrosoftYaHeiUI;
      font-size: 14px;
      color: #7fabbc;
      letter-spacing: 0;
      font-weight: 400;
    }
    .jianju {
      margin: 0 0 18px 0;
    }
    .colon {
      margin: 0 3px;
    }
    .fontTen {
      font-family: MicrosoftYaHeiUI;
      font-size: 14px;
      color: #aee9ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
}
.nowarp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bjs {
  background-color: #0a0d18;
  padding: 5px;
}
.articleInformation {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #178890;
  border-bottom: 0px;
  margin-top: 8px;
  .fontFive {
    font-family: MicrosoftYaHeiUI;
    font-size: 18px;
    color: #45d8ff;
    letter-spacing: 0;
    font-weight: 400;
  }
  .fontSix {
    font-family: MicrosoftYaHeiUI;
    font-size: 14px;
    color: #7fabbc;
    letter-spacing: 0;
    font-weight: 400;
  }
  .fontSeven {
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #40c0e2;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.special {
  display:flex;
  justify-content: space-between;
  .todayPhoto {
    position: relative;
    height: 120px;
  }
  .fontOne {
    opacity: 0.7;
    font-family: MicrosoftYaHeiUI;
    font-size: 12px;
    color: #7fabbc;
    letter-spacing: 0;
    font-weight: 400;
    .font {
      font-family: MicrosoftYaHeiUI;
      font-size: 12px;
      color: #aee9ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .exclamationPoint {
    position: absolute;
    left: 5px;
    top: 0;
    cursor: pointer;
  }
  .photoNumber {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.8;
    background: #0c1120;
    opacity: 0.75;
    font-family: MicrosoftYaHeiUI;
    font-size: 12px;
    color: #aee9ff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
  img {
    height: 120px !important;
    width: 100% !important;
  }
  .slide {
    width: 100% !important;
    height: 100% !important;
    margin-right: 0 !important;
  }
}
.gallery-thumbs:hover {
  opacity: 1;
}
.is_active {
  opacity: 1 !important;
}
.gallery-thumbs {
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #40bddf;
  border-radius: 8px;
  padding: 8px;
  margin: 0 5px 0 0;
  opacity: 0.5;
  cursor: pointer;
  .fontFive {
    font-family: MicrosoftYaHeiUI;
    font-size: 18px;
    color: #45d8ff;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.pageNumber {
  margin: 15px 2px 0 3px;
  width: 48px;
  height: 173px;
  background-image: linear-gradient(179deg, #323644 0%, #131622 100%);
  border: 1px solid #40bddf;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 2px 0 rgba(46, 255, 246, 0.15),
    inset 0 -1px 0 0 rgba(24, 41, 117, 0.5);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .jiantou {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #00d2ff;
    letter-spacing: 0;
    text-align: center;
    line-height: 10px;
    font-weight: 400;
    cursor: pointer;
  }
  .fontThre {
    font-family: MicrosoftYaHeiUI;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
  .fontOneFour {
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #7fabbc;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
//轮播图样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel {
  height: 100%;
}
/deep/ .el-carousel__indicators {
  // display: none;
}
/deep/ .el-carousel__arrow--left {
  left: 0;
  background-image: url("~@/assets/left.svg");
  background-size: 100% 100%;
  border-radius: 0 !important;
  height: 100px;
  width: 30px;
  background-color: transparent !important;
}
/deep/ .el-carousel__arrow--right {
  right: 0;
  background-image: url("~@/assets/right.svg");
  background-size: 100% 100%;
  border-radius: 0 !important;
  height: 100px;
  width: 30px;
  background-color: transparent !important;
}
.kapian{
  display: flex;
}
</style>
