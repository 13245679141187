<template>
  <div>
    <div class="card">
      <div class="close">
        <span @click="guan">X</span>
      </div>
      <face_recognition v-if="typeName == '人脸识别'"></face_recognition>
      <plate_number v-else-if="typeName == '车牌识别'"></plate_number>
      <!-- <plate_number></plate_number> -->
      <crowded v-else-if="typeName == '人流识别'"></crowded>
      <!-- <button @click="abc">1111</button> -->
    </div>
  </div>
</template>

<script>
import face_recognition from "@/components/layout/pup/face_recognition/index.vue";
import plate_number from "@/components/layout/pup/plate_number/index.vue";
import crowded from "@/components/layout/pup/crowded/index.vue";
export default {
  components: {
    face_recognition,
    plate_number,
    crowded,
  },
  props: {
    typeName: {
      type: String,
    },
  },
  data() {
    return {
      typeN: "",
    };
  },
  methods: {
    guan() {
      this.$parent.close();
    },
    abc() {
      console.log(this.typeName, "???????");
    },
  },
  watch: {
    typeName: function (val, old) {
      this.typeN = val;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  width: 90vw;
  height: 80vh;
  // border: 1px solid #f8f8f8;
  color: red;
  background-color: #fff;
}
.close {
  font-size: 24px;
  cursor: pointer;
  text-align: right;
  height: 40px;
  border-bottom: 1px solid #f8f8f8;
  line-height: 40px;
  padding: 0 10px 0 0;
  color: #000;
  background-color: #fff;
}
</style>