
export default {
    fullList: [],
    NoticeInfoFlag:false,
    NoticeData: {
        deviceHardId:"",
        leftText: "取消",
        rightText: "确认",
        title: '接管确认',
        content:""
    },
}