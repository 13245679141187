<!-- 问卷调查 -->
<template>
  <div class="report">
    <div class="pup_head" v-interact>
      <div class="flex">
        <div class="pup_img">
          <img
            src="~@/assets/zong_lan_ye_img/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="fontOne">
          {{ surveyName || "暂无" }}
        </div>
      </div>
      <div class="fontTwo" @click="pupClose">关闭</div>
    </div>
    <div class="nei">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-position="top"
      >
        <el-form-item
          v-for="(item, index) in questions"
          :key="item.id"
          :label="index + 1 + '、' + item.name + '('+item.type+')'"
          :prop="'require' + index"
        >
          <el-radio-group v-if="item.type == '单选'" v-model="ruleForm['require' + index]">
            <el-radio
              :label="item2.id"
              v-for="item2 in item.list"
              :key="item2.id"
              :class="item2.photo ? 'photo' : ''"
              >
              {{ item2.answer }}
              <el-image v-if="item2.photo" :src="imgApi+item2.photo" fit="cover"
                          :preview-src-list="[imgApi+item2.photo]"></el-image>
              </el-radio
            >
          </el-radio-group>
          <el-checkbox-group
            v-if="item.type == '多选'"
            v-model="ruleForm['require' + index]"
          >
            <el-checkbox
              :label="item2.id"
              v-for="item2 in item.list"
              :key="item2.id"
              :class="item2.photo ? 'photo' : ''"
              >
              {{ item2.answer }} 
              <el-image v-if="item2.photo" :src="imgApi+item2.photo" fit="cover"
                          :preview-src-list="[imgApi+item2.photo]"></el-image>
              </el-checkbox
            >
          </el-checkbox-group>
          <el-input type="textarea"  v-if="item.type == '简答'" v-model="ruleForm['require' + index]"></el-input>
        </el-form-item>
      </el-form>
      <div class="btn" @click="handle_submit">提交</div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      surveyName: null,
      surveyId: null,
      questions: [],
      ruleForm: {},
      rules: {},
      imgApi: process.env.VUE_APP_IMG_URL,

    };
  },
  mounted() {
    this.getSurvey();
  },
  methods: {
    async getSurvey() {
      let res = await API.HOME.getSurvey();
      this.surveyName = res.surveyName;
      this.questions = res.questionDtos;
      this.questions.forEach((val, i) => {
        this.surveyId = val.surveyId
        if (val.isRequired == "必填") {
          this.rules["require" + i] = [
            {
              required: true,
              message: "此题为必填",
              trigger: "change",
            },
          ];
        }
        if (val.type == '多选') {
          this.$set(this.ruleForm, "require" + i, [])
          val["require" + i] = []
        } else {
          this.$set(this.ruleForm, "require" + i, "")
          val["require" + i] = ""
        }
      });
    },
    // 提交
    handle_submit() {
      this.$el_confirm("确定完成问卷并提交吗？", () => {
        this.submit();
      });
    },
    submit() {
      this.$refs["ruleForm"].validate(async (valid) => {
        if (valid) {
          this.questions.forEach((val,i) => {
            val["require" + i] = this.ruleForm["require" + i]
          })
          let arr = []
          this.questions.forEach((val, i) => {
            let data = {}
            if (val["require" + i].length > 0 && Array.isArray(val["require" + i])) {
              val["require" + i] = val["require" + i].join(",")
            }
            data = {
              surveyQuestionId: val.id,
              optionIds:val["require" + i]
            }
            arr.push(data)
          })
          let params = {
            id: this.surveyId,
            list: arr
          }
          let res = await API.HOME.writeSurvey(params)
          this.$message.success("问卷已提交，感谢您的参与！")
          this.pupClose()
          localStorage.setItem("isSurvey",1)
          this.$emit("close")
        } else {
          return false;
        }
      });
    },
    pupClose() {
      this.$emit("handleClose");
    },
  },
};
</script>

<style scoped lang="scss">
.report {
  width: 900px;
  height: 662px;
  position: fixed;
  top: -160px;
  right: 50%;
  background: rgba(12, 34, 73, 0.7);
  border-radius: 10px;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
}

.flex {
  display: flex;
}

.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    align-items: center;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
  }
}
.nei {
  width: 100%;
  box-sizing: border-box;
  padding: 12px;
  height: calc(100% - 32px);
  overflow: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  .el-form {
    text-align: left;
    padding: 10px;
    /deep/.el-form-item {
      // margin-bottom: 0;
      .el-form-item__label {
        color: #fff;
        padding: 0;
        line-height: 20px;
      }
      .el-form-item__content {
        .el-radio-group{
          display: flex;
          flex-direction: column;
        }
        .el-radio {
          margin: 10px 5px;
        }
        .photo{
          width: 100%;
          .el-checkbox__input{
            position: absolute;
            top: 4px;
          }
          .el-checkbox__label{
          margin-left: 14px;
          }
        }
        .el-radio__label,
        .el-checkbox__label {
          color: #fff;
          .el-image{
            margin-top: 10px;
            display: block;
            width: 300px;
          }
        }
      }
    }
  }
  .btn {
    width: 80px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    color: #fff;
    background: #3f9dff;
    border-radius: 5px;
  }
}
</style>
