<!-- 总览页 -->
<!-- 报备情况 -->
<!-- 设备报备 -->
<template>
  <div class="cpt-layout-report-popup">
    <!-- <div class="headerbg">
      <div class="title pa left50 top10">设备报备</div>
      <div class="img-close" @click="() => $emit('handleReportShow', false,'设备报备')">
        关闭
      </div>
    </div> -->
    <div class="pup_head">
      <div class="flex">
        <div class="pup_img">
          <img
            src="~@/assets/zong_lan_ye_img/jinye.png"
            style="width: 100%; height: 100%"
            alt=""
          />
        </div>
        <div class="fontOne">设备报备</div>
      </div>
      <div
        class="fontTwo"
        @click="() => $emit('handleReportShow', false, '设备报备')"
      >
        关闭
      </div>
    </div>

    <div class="popup_box">
      <div class="select dib">
        <span style="color: #92d9ff" class="fw700 fz18 ml25">设备名称</span>
        <el-select
          popper-class="popperSelect"
          filterable
          multiple
          collapse-tags
          v-model="value"
          placeholder="请选择设备"
          clearable
        >
          <el-option-group
            v-for="(group, index) in options"
            :key="index"
            :label="group.label"
            :popper-append-to-body="false"
          >
            <el-option
              v-for="(item, index2) in group.options"
              :key="group.type + index2"
              :label="item.deviceName"
              :disabled="item.cardStatus == 1"
              :value="item.deviceId + ',' + item.type + ',' + item.deviceName"
            >
              <span v-if="item.cardStatus == 0" style="float: left">{{
                item.deviceName
              }}</span>
              <div v-if="item.cardStatus == 1">
                <span style="float: left">{{ item.deviceName }}</span>
                <span style="float: right; color: green; font-size: 13px"
                  >已报备</span
                >
              </div>
            </el-option>
          </el-option-group>
        </el-select>
      </div>
      <el-button class="dib" @click="handle_click" :disabled="value.length == 0"
        >资源报备</el-button
      >
    </div>

    <div class="tagbox">
      <el-tag
        v-show="value.length > 0"
        @close="closeFn(item)"
        closable
        v-for="(item, index4) in value"
        :key="index4"
      >
        {{ item.split(",")[2] }}
      </el-tag>
    </div>
    <div class="instruction mt20">
      <span>说明:</span>
      <div class="dib ml15">
        <span class="fw700"> 日常报备资源</span
        >（无人机-正常;电池-满电、正常;鹰巢-满电、正常）
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/api";
export default {
  data() {
    return {
      options: [
        {
          label: "无人机",
          type: "x",
          options: [],
        },
        {
          label: "挂载",
          type: "y",

          options: [],
        },
        {
          label: "鹰巢",
          type: "z",
          options: [],
        },
      ],
      value: [],
    };
  },
  async created() {
    await this.getDroneList();
    await this.getMountList();
    await this.getNestList();
    // await this.getBatteryList();
    // await this.StationList();//基站
  },
  methods: {
    // 关闭tag
    closeFn(data) {
      this.value.splice(data, 1);
    },
    async handle_click() {
      let arr = [];
      this.value.forEach((item) => {
        let newItem = item.split(",");
        arr.push({ deviceId: Number(newItem[0]), type: Number(newItem[1]) });
      });
      await API.USER.ResourceReport(arr);
      this.$el_message("报备成功");
      this.$emit("handleReportShow", false, 1);
      localStorage.removeItem("cardStatus");
    },
    async getDroneList() {
      let res = await API.DEVICE.ListDrone({ pageSize: 10000 });
      if (res) {
        let arr = [];
        res.data.forEach((item) => {
          arr.push({
            id: "1-" + item.id,
            deviceName: item.deviceName,
            deviceId: item.id,
            type: 1,
            cardStatus: item.cardStatus,
          });
        });
        this.options[0].options = arr || [];
      } else {
        this.options[0].options = [];
      }
    },
    // 挂载列表
    async getMountList() {
      let res = await API.DEVICE.ListMount({ pageSize: 10000 });
      if (res) {
        let arr = [];
        res.forEach((item) => {
          arr.push({
            id: "2-" + item.id,
            deviceName: item.name,
            deviceId: item.id,
            type: 2,
            cardStatus: item.cardStatus,
          });
        });
        this.options[1].options = arr || [];
      } else {
        this.options[1].options = [];
      }
    },
    // 鹰巢
    async getNestList() {
      let res = await API.DEVICE.ListNest({ pageSize: 10000 });
      if (res) {
        let arr = [];
        res.data.forEach((item) => {
          arr.push({
            id: "4-" + item.id,
            deviceName: item.name,
            deviceId: item.id,
            type: 4,
            cardStatus: item.cardStatus,
          });
        });
        this.options[2].options = arr || [];
      } else {
        this.options[2].options = [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-select-dropdown__item.selected::after {
  right: 7px !important;
}

.cpt-layout-report-popup {
  z-index: 9px;
  background: rgba(12, 34, 73, 0.7) !important;
  border-radius: 10px !important;
  border: 1px solid rgba(26, 92, 246, 0.5);
  backdrop-filter: blur(1px);
  /deep/ .el-select-group__wrap {
    background: #161d2d;
  }

  .headerbg {
    height: 44px;
    background: rgba(0, 34, 140, 0.2);
    box-shadow: inset 0 0 15px 0 rgba(0, 182, 255, 0.5);
    border-radius: 10px 10px 0 0;
    .title {
      font-family: MicrosoftYaHei-Bold;
      font-size: 18px;
      color: #00ffff;
      letter-spacing: 0;
      font-weight: 700;
    }
  }
  width: 608px;
  min-height: 308px;
  background: rgba(22, 29, 45, 0.8);
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  font-family: MicrosoftYaHeiUI;
  color: #ccedff;
  // z-index: 999;
  .img-close {
    font-size: 18px;
    color: #00ffff;
    position: absolute;
    right: 20px;
    top: 10px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  .popup_box {
    margin-top: 24px;
    margin-left: 30px;
  }
  &::v-deep {
    .el-select {
      width: 300px;
      height: 51px;
      margin-left: 15px;
      .el-input--suffix {
        width: 100%;
      }
    }
    .tagbox {
      margin-left: 50px;
      margin-top: 10px;
      min-height: 100px;
      width: 550px;
      .el-tag {
        border: none;
        height: 20px;
        color: #00ffff;
        .el-icon-close {
          margin-top: 14px;
          right: 0;
        }
      }
    }
    .el-tag {
      border: 1px solid #00ffff;
      border-radius: 15px;

      background: rgba(22, 29, 45, 0);
      color: #00ffff;
      font-family: MicrosoftYaHei-Bold;
      font-size: 14px;
      letter-spacing: 0;
      font-weight: 700;
      padding-left: 5px;
    }

    .el-select .el-tag__close.el-icon-close::before {
      background: #00ffff;
      border-radius: 50%;
    }
    .el-input__inner {
      height: 51px !important;

      background: rgba(22, 29, 45, 0);
      border: 1px solid #08c2d1;
      font-family: MicrosoftYaHeiUI;
      font-size: 16px;
      color: #08c2d1;
    }
    .el-select__input {
      background: rgba(22, 29, 45, 0);
      border-radius: 2px;
      font-family: MicrosoftYaHeiUI;
      font-size: 16px;
      color: #08c2d1;
    }
    .el-button {
      margin-left: 33px;
      background: rgba(0, 34, 140, 0.2);
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), inset 0 0 10px 0 #00b6ff;
      border-radius: 6px;
      border: none;
      color: #01f8f9;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  .instruction {
    margin-left: 50px;
    color: #92d9ff;
    margin-bottom: 20px;
  }
}
.flex {
  display: flex;
}
.pup_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 16px 0 12px;
  align-items: center;
  height: 32px;
  background: linear-gradient(
    180deg,
    #9198ff 0%,
    rgba(45, 81, 153, 0.45) 40%,
    #05091a 100%
  );
  box-shadow: inset 0px 0px 10px 2px #3f9dff;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid #427dff;

  .pup_img {
    display: flex;
    width: 19px;
    height: 25px;
    margin: 0 4px 0 0;
  }

  .fontOne {
    font-size: 20px;
    font-family: YouSheBiaoTiHei;
    color: #14faff;
    line-height: 26px;
    text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
    background: linear-gradient(
      135deg,
      #e3aa77 0%,
      #f5cda9 38%,
      #f9ecd3 58%,
      #fcdbb1 79%,
      #edb07a 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .fontTwo {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #d2dfff;
    cursor: pointer;
    line-height: 20px;
  }
}
</style>

