import factor from "./factor"
import uav from "./uav"

export default {

    date: {
        date: "----/--/--",
        week: "----",
        hour: "--:--",
        week_map: {
            0: "星期天",
            1: "星期一",
            2: "星期二",
            3: "星期三",
            4: "星期四",
            5: "星期五",
            6: "星期六",
        },
    },
    weather: {
        day: null,
        current: {},
        hour_list: [],
    },

    factor,
    uav
}