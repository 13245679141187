<!-- 人流识别 -->
<template>
  <div>
    人流识别
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>