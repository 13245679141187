<template>
  <div v-if="itemIndex==1">
    <div class="dialog" v-if="isShow" >
    <div class="head_title">
      <div> <slot name="dialogleft"></slot></div>
      <div class="title f15 lh30" style="color:yellow">{{data.title}}</div>
      <div> <slot  name="dialogright"></slot></div>
    </div>
    <div class="line"></div>
    <div class="content">{{ data.msg }}</div>
      <slot name="footer"></slot>
  </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    itemIndex: {
      type: Number,
      default: () => 1,
    },
    isShow: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style scoped lang='scss'>
.dialog {
  position: fixed;
  top: 300px;
  padding-bottom: 10px;
  left: 750px;
  z-index: 9999999;
  width: 457px;
  min-height: 146px;
  background: url("../../assets/images/warn/yj.png") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  .head_title {
    margin: 8px;
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #fd4d4d;
    text-align: center;
    font-weight: 700;
    .title {
      background: none;
    }
  }

  .line {
    width: 457px;
    height: 2px;
    align-self: center;
    background-image: linear-gradient(
      244deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(237, 89, 68, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .content {
    padding-top: 16px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: calc(100% - 35px);
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fd4d4d;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
  }
}
</style>