<!-- 车牌识别 -->
<template>
  <div class="bj">
    <div class="flex">
      <!-- 轮播图部分 -->
      <div class="lunbo">
        <el-carousel
          height="100%"
          @change="lunbotu"
          ref="huandeng"
          :autoplay="blo"
        >
          <el-carousel-item v-for="(item, index) in lunbo" :key="index">
            <img :src="item.image_url" alt="" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- 轮播图部分 -->
      <!-- 对比部分 -->
      <div class="duibi">
        <div class="warning" style="margin-bottom: 0">车辆信息对比</div>
        <div style="margin: 8px 0 0 0; padding: 0 12px">
          <div class="photograph">
            <div class="img" style="height: 282px">
              <img :src="duibishuju.image_url" alt="" />
              <div
                class="contrast Capture"
                style="height: 57px; line-height: 57px"
              >
                抓拍照片
              </div>
            </div>
          </div>
          <div class="resemblance nowarp">
            <span class="KeepFile nowarp" style="margin: 0 16px 0 0"
              >相似度</span
            >
            <span class="fontEight"> resemblance </span>
          </div>
          <div>
            <div class="xinxi shangjianju">
              <div class="zuo xinxi nowarp">
                <div class="key">车牌号 :</div>
                <div class="shuju">{{ duibishuju.license_plates }}</div>
              </div>
              <div class="you xinxi nowarp">
                <div class="key">车主姓名 :</div>
                <div class="shuju nowarp">川建国</div>
              </div>
            </div>
            <div class="shangjianju">
              <div class="zuo xinxi nowarp">
                <div class="key">身份证/单位编号 :</div>
                <div class="shuju">福建省厦门市</div>
              </div>
            </div>
            <div class="xinxi shangjianju">
              <div class="zuo xinxi nowarp">
                <div class="key">车辆品牌 :</div>
                <div class="shuju">丰田</div>
              </div>
              <div class="you xinxi nowarp">
                <div class="key">车辆类型 :</div>
                <div class="shuju nowarp">其他</div>
              </div>
            </div>
            <div class="shangjianju">
              <div class="zuo xinxi nowarp">
                <div class="key">车身颜色 :</div>
                <div class="shuju">黑色</div>
              </div>
            </div>
            <div class="xinxi shangjianju">
              <div class="zuo xinxi nowarp cheliang">
                <div class="key">车辆识别代号 :</div>
                <div class="shuju">WDXFFR1245</div>
              </div>
              <div class="you xinxi nowarp">
                <div class="key">联系号码 :</div>
                <div class="shuju nowarp">13751523478</div>
              </div>
            </div>
          </div>
          <div class="shangjianju">
            <div class="xinxi nowarp">
              <div class="key">归属地 :</div>
              <div class="shuju">深圳市南山区西丽万科云城</div>
            </div>
          </div>
          <div class="shangjianju">
            <div class="xinxi nowarp">
              <div class="key">登机时间 :</div>
              <div class="shuju">{{ times(duibishuju.capture_time) }}</div>
            </div>
          </div>
          <div class="shangjianju">
            <div class="xinxi nowarp">
              <div class="key">选择日期时间 :</div>
              <div class="shijian">
                <el-date-picker
                  v-model="value1"
                  type="datetime"
                  placeholder="选择日期时间"
                >
                </el-date-picker>
              </div>
            </div>
          </div>
          <div class="shangjianju">
            <div class="xinxi nowarp">
              <div class="key">移动轨迹 :</div>
              <div class="shuju"></div>
            </div>
          </div>
        </div>
        <div class="btn">
          <el-button type="primary">定位</el-button>
        </div>
      </div>
      <!-- 对比部分 -->
    </div>
    <!-- 底部照片 -->
    <div class="dibu">
      <div class="articleInformation">
        <div>
          <span class="fontFive" style="margin: 0 73px 0 16px">今日抓拍</span>
          <span class="fontSix">共</span>
          <span class="fontSeven" style="margin: 0 8px"> today </span>
          <span class="fontSix">次抓拍</span>
        </div>
        <div style="padding: 0 8px 0 0">
          <span class="fontSix">历史抓拍</span>
          <span class="fontSix" style="margin: 0 2px">:</span>
          <span class="fontSeven" style="margin: 0 8px"> history </span>
          <span class="fontSix">次</span>
        </div>
      </div>
      <!-- 卡片模板 -->
      <div style="display: flex; padding: 0 0px" class="special">
        <div
          class="gallery-thumbs"
          v-for="(item, index) in card"
          :key="index"
          :class="{ actives: index == numId }"
          @mousemove="move(index)"
          @click="chakan(index)"
        >
          <div class="todayPhoto">
            <!-- <div class="imgItem"> -->
            <img
              :src="item.image_url"
              alt=""
              style="height: 100%; width: 100%"
            />
            <!-- </div> -->

            <div class="exclamationPoint fontFive">
              <i class="el-icon-warning-outline"></i>
            </div>
            <div class="photoNumber">{{ item.license_plates }}</div>
          </div>
          <div style="margin-top: 8px" class="fontOne">
            <span>地点</span>
            <span style="margin: 0 4px">:</span>
            <span class="font"> todayCapture.site </span>
          </div>
          <div class="fontOne" style="margin-top: 4p">
            <span>时间</span>
            <span style="margin: 0 4px">:</span>
            <span class="font">{{ times(item.capture_time) }}</span>
          </div>
        </div>
        <!-- 上下翻页 -->
        <div class="pageNumber">
          <div class="jiantou" @click="shangjian">
            <i class="el-icon-arrow-up"></i>
          </div>
          <div>
            <div class="fontThre">{{ ye }}</div>
            <div style="text-align:center;">/</div>
            <div class="fontOneFour">{{ zongye }}</div>
          </div>
          <div class="jiantou" @click="xiajian">
            <i class="el-icon-arrow-down"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bus from "@/assets/ligature.js";
import API from "@/api/modules/aiUse";
export default {
  data() {
    return {
      che:'001f002d343951183334393700000000',// 车牌试验账号
      lunbo: [], //轮播图的数组
      value1: "", //时间选择器
      card: [], // 卡片数组,
      numId: "", //轮播图与下面卡片对应
      blo: true, // 幻灯片是否自动切换
      duibishuju: "", //对比的数据
      ye:'',//当前页码
      zongye:'',//总条目数
    };
  },
  mounted() {
    this.abc();
  },
  methods: {
    // 翻页功能
    shangjian(){
      if(this.ye == 1){
        return
      }else{
        // 没有翻页的接口详情
      }
    },
    xiajian(){
      if(this.ye == this.zongye){
        return
      }else{
        let a = this.ye + 1
        // 没有翻页的接口详情
      }
    },
    // 轮播图公转功能
    lunbotu(index) {
      this.numId = index;
      this.duibishuju = this.lunbo[index];
      console.log(this.duibishuju, "查看");
    },
    // 小图对应轮播图
    move(index) {
      this.numId = index;
      this.duibishuju = this.lunbo[index];
      this.$refs.huandeng.setActiveItem(index);
    },
    // chakan(index){
    //      this.numId = index
    //       this.duibishuju = this.lunbo[index]
    //       this.$refs.huandeng.setActiveItem(index)
    //       this.blo = false
    // },
    // 数据请求
    abc() {
      API.aiPlateList({
        deviceHardId: localStorage.getItem("deviceHardId"),
        // deviceHardId: this.che,
      }).then((res) => {
        this.lunbo = res.data;
        this.card = res.data;
        this.ye = res.pageNo
        this.zongye = res.totalPage
        console.log(res, "车牌数据");
      });
    },
    times(num) {
      if (num > 9999999999) {
        // 这里判断：时间戳为几位数
        var c_Date = new Date(parseInt(num));
      } else {
        var c_Date = new Date(parseInt(num) * 1000);
      }
      var c_Year = c_Date.getFullYear();
      var c_Month = c_Date.getMonth() + 1;
      var c_Day = c_Date.getDate();
      var c_Hour = c_Date.getHours();
      var c_Min = c_Date.getMinutes();
      var c_Sen = c_Date.getSeconds();
      var c_Time =
        c_Year +
        "/" +
        this.getzf(c_Month) +
        "/" +
        this.getzf(c_Day) +
        " " +
        this.getzf(c_Hour) +
        ":" +
        this.getzf(c_Min) +
        ":" +
        this.getzf(c_Sen); //最后拼接时间

      return c_Time;
    },
    //补0操作  小于10的就在数字前面加0，这应该很好理解吧
    getzf(c_num) {
      if (parseInt(c_num) < 10) {
        c_num = "0" + c_num;
      }
      return c_num;
    },
  },
};
</script>

<style lang="scss" scoped>
.xinxi {
  display: flex;
}
.bj {
  background-color: #000;
  height: 750px;
  overflow-y: scroll;
}
.flex {
  display: flex;
  padding: 8px 0 8px 0px;
}
.lunbo {
  width: 69.5%;
  height: 700px;
  background: #24272a;
  border-radius: 4px;
  padding: 8px 0 8px 0;
  margin: 0 14px 0 0;
}
.duibi {
  width: 30%;
  background: #161d2d;
  border: 1px solid #178890;
  border-radius: 4px 0 0 4px;
  margin: 8px 0 0 0;
  .warning {
    height: 32px;
    width: 100%;
    // margin: 0 0 0px 0;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #92d9ff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 700;
    background: url(http://116.62.122.225/hawk_ai/static/img/plate.9235b6b2.gif)
      no-repeat;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
  .photograph {
    display: flex;
    justify-content: space-between;
    .img {
      width: 100%;
      border: 1px solid red;
      height: 282px;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .contrast {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        text-align: center;
      }
      .Capture {
        opacity: 0.8;
        background: #0c1120;
        opacity: 0.75;
        font-family: MicrosoftYaHeiUI;
        font-size: 12px;
        color: #aee9ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
      .KeepFile {
        opacity: 0.8;
        background: #0c1120;
        opacity: 0.75;
        font-family: MicrosoftYaHeiUI;
        font-size: 12px;
        color: #aee9ff;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
      }
    }
  }
  .resemblance {
    border: 1px solid #244d5f;
    height: 48px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 25px;
    .KeepFile {
      opacity: 0.8;
      background: #0c1120;
      opacity: 0.75;
      font-family: MicrosoftYaHeiUI;
      font-size: 12px;
      color: #aee9ff;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }
    .fontEight {
      font-family: MicrosoftYaHeiUI;
      font-size: 32px;
      color: #ff4a4a;
      letter-spacing: 0;
      text-align: center;
      font-weight: 400;
    }
  }
}
.nowarp {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.shangjianju {
  margin: 0 0 8px 0;
}

.zuo {
  width: 50%;
}
.you {
  width: 50%;
}
.key {
  width: 102px;
  opacity: 0.7;
  font-family: MicrosoftYaHeiUI;
  font-size: 12px;
  color: #7fabbc;
  letter-spacing: 0;
  text-align: right;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.shuju {
  font-family: MicrosoftYaHeiUI;
  font-size: 16px;
  color: #aee9ff;
  letter-spacing: 0;
  font-weight: 400;
  margin: 0 0 0 8px;
}
.shijian {
  border-radius: 4px;
  width: 161px;
  // height: 35px;
  margin: 0 0 0 8px;
  /deep/ .el-input__inner {
    width: 161px;
    background: rgba(2, 31, 51, 0.7);
    border: 1px solid rgba(18, 156, 156, 0.5);
  }
}
/deep/ .el-input__inner::-webkit-input-placeholder {
  font-family: MicrosoftYaHeiUI;
  font-size: 14px;
  color: rgba(18, 156, 156, 0.5) !important;
}
/deep/ .el-input__icon {
  color: rgba(18, 156, 156, 0.5) !important;
}
.btn {
  width: 100%;
  display: flex;
  justify-content: center;
  button {
    width: 116px;
    height: 40px;
    background: #129c9c;
    border-radius: 2px;
  }
}

.articleInformation {
  width: 100%;
  height: 43px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #178890;
  border-bottom: 0px;
  margin-top: 8px;
  .fontFive {
    font-family: MicrosoftYaHeiUI;
    font-size: 18px;
    color: #45d8ff;
    letter-spacing: 0;
    font-weight: 400;
  }
  .fontSix {
    font-family: MicrosoftYaHeiUI;
    font-size: 14px;
    color: #7fabbc;
    letter-spacing: 0;
    font-weight: 400;
  }
  .fontSeven {
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #40c0e2;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.dibu {
  margin: 0 0 20px 0;
}

.special {
  .todayPhoto {
    position: relative;
    height: 120px;
  }
  .fontOne {
    opacity: 0.7;
    font-family: MicrosoftYaHeiUI;
    font-size: 12px;
    color: #7fabbc;
    letter-spacing: 0;
    font-weight: 400;
    .font {
      font-family: MicrosoftYaHeiUI;
      font-size: 12px;
      color: #aee9ff;
      letter-spacing: 0;
      font-weight: 400;
    }
  }
  .exclamationPoint {
    position: absolute;
    left: 5px;
    top: 0;
    cursor: pointer;
  }
  .photoNumber {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: 0.8;
    background: #0c1120;
    opacity: 0.75;
    font-family: MicrosoftYaHeiUI;
    font-size: 12px;
    color: #aee9ff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }
  img {
    height: 120px !important;
    width: 100% !important;
  }
  .slide {
    width: 100% !important;
    height: 100% !important;
    margin-right: 0 !important;
  }
}
.gallery-thumbs:hover {
  opacity: 1;
}
.gallery-thumbs {
  height: 20%;
  box-sizing: border-box;
  border: 1px solid #40bddf;
  border-radius: 8px;
  padding: 8px;
  margin: 0 5px 0 0;
  opacity: 0.5;
  cursor: pointer;
  .fontFive {
    font-family: MicrosoftYaHeiUI;
    font-size: 18px;
    color: #45d8ff;
    letter-spacing: 0;
    font-weight: 400;
  }
}
.actives {
  opacity: 1;
}
.pageNumber {
  margin: 15px 2px 0 3px;
  width: 48px;
  height: 173px;
  background-image: linear-gradient(179deg, #323644 0%, #131622 100%);
  border: 1px solid #40bddf;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5),
    inset 0 1px 2px 0 rgba(46, 255, 246, 0.15),
    inset 0 -1px 0 0 rgba(24, 41, 117, 0.5);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  .jiantou {
    font-family: MicrosoftYaHei;
    font-size: 20px;
    color: #00d2ff;
    letter-spacing: 0;
    text-align: center;
    line-height: 10px;
    font-weight: 400;
    cursor: pointer;
  }
  .fontThre {
    font-family: MicrosoftYaHeiUI;
    font-size: 24px;
    color: #ffffff;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
  .fontOneFour {
    font-family: MicrosoftYaHeiUI;
    font-size: 16px;
    color: #7fabbc;
    letter-spacing: 0;
    text-align: center;
    font-weight: 400;
  }
}
//轮播图样式
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
.el-carousel {
  height: 100%;
}
/deep/ .el-carousel__indicators {
  // display: none;
}
/deep/ .el-carousel__arrow--left {
  left: 0;
  background-image: url("~@/assets/left.svg");
  background-size: 100% 100%;
  border-radius: 0 !important;
  height: 100px;
  width: 30px;
  background-color: transparent !important;
}
/deep/ .el-carousel__arrow--right {
  right: 0;
  background-image: url("~@/assets/right.svg");
  background-size: 100% 100%;
  border-radius: 0 !important;
  height: 100px;
  width: 30px;
  background-color: transparent !important;
}
</style>
