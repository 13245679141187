<template>
  <div class="cpt-layout">
    <img
      v-if="isAlarm"
      class="cpt-layout-alarm"
      src="~@/assets/images/alarm.png"
      width="100%"
      height="100%"
    />
    <div v-if="isAlarm" class="iconfont icon-guanbi cpt-layout-close" @click="handle_close(1)"></div>
    <div class="cpt-layout-alarm-hint" v-if="isHint">
      <h5 class="title">预警通知</h5>
      <span class="iconfont icon-guanbi" @click="handle_close(2)"></span>
      <a :href="ai_link" target="_blank" class="content">您收到一条关于交通事故的警情紧急通知！！！</a>
    </div>
    <!-- 消息通知 start-->
    <Message ref="messageRef" @handleClose="showmessage = false" v-show="showmessage" />
    <!-- 消息通知 end-->
    <!-- 滚动提示 start-->
    <NotificationsBar :messagenum="messagenum" @messageInfo="showInfo" />
    <!-- 滚动提示 end-->
    <CesiumLayer
      ref="cesium_layer"
      @change-plate-mode="handle_change_plate_mode"
      @initDone="onCesiumInitDone"
      v-if="visibleMainMap"
    />
    <router-view ref="router-view" />

    <!-- 侧边工具栏 start-->
    <div v-if="!floating_menuFlag && flag">
      <div>
        <Select class="floating-menuFkSelect"></Select>
      </div>
      <factoriconsfkV3
        class="floating-menuFkqys pf right10"
        @change="factor_change"
        @item-change="factor_change"
        @shang-change="factor_shang_change"
      />
      <cesiumToolbarfk
        class="tc pf CesiumToolbarfk"
        @toolbar-click="toolbar_handle_click"
        ref="cesium_toolbar"
      />
      <Hwkeysfk />
    </div>
    <div
      class="floating-menu"
      v-show="floating_menuFlag"
      :class="[
      qysFlag ? 'qysStyleLeft' : 'qysStyleRight',
      offset ? 'offset' : '',
      ...floatMenuPos,
    ]"
      v-if="ab == true && visibleMainMap"
    >
      <div class="header" v-interact :class="{ assimilation: routeColor == true }"></div>
      <FactorIcons
        @change="factor_change"
        @item-change="factor_change"
        @shang-change="factor_shang_change"
        v-show="isShow && isShowFactorIcons"
      />
      <CesiumToolbar @toolbar-click="toolbar_handle_click" ref="cesium_toolbar" />
      <Hwkeys v-show="isShow" />
    </div>
    <!-- 侧边工具栏 end-->
    <!-- 水质检测 start -->
    <Water v-show="waterebox"></Water>
    <!-- 水质检测 end -->
    <!-- 飞行检查 start-->
    <checkSafe @close="checkVisible = false" v-if="checkVisible"></checkSafe>
    <!-- 飞行检查 end -->
    <!-- 设备报备 start -->
    <reportPopup v-if="report_is_show" @handleReportShow="handle_report_show" />
    <!-- 设备报备 end -->
    <HKVideo v-if="hk_is_show" :setting="setting" :list="list" @hideHKVideo="hiden_hk_video" />
    <!-- 应急打卡提醒 start-->
    <dialogNew :isShow="signType" :data="signData">
      <div class="safetyWarningDoneY mb31" slot="footer" @click="yjdk">确认报备</div>
    </dialogNew>
    <!-- 应急打卡提醒 end-->
    <!-- 飞行提醒 检验是否影响鹰巢周期任务执行 start-->
    <dialogNew :isShow="flightTipsisShow" :data="flightTips">
      <div slot="dialogright" class="cp" @click="flightTipsisShow=false">关闭</div>
    </dialogNew>
    <!-- 飞行提醒 end-->
    <!-- 无人机强制接管消息 start-->
    <div class="donedialog" v-if="donevisible">
      <div class="head">
        <div class="title">{{ doneData.title || "暂无" }}</div>
        <div
          class="close cp"
          @click="
          donevisible = false;
        donevisibleFlag = true;
        "
        >关闭</div>
      </div>
      <div class="line"></div>
      <div class="content">{{ doneData.msg || "暂无" }}</div>
      <div class="flex">
        <div
          class="doneN"
          v-if="donevisibleFlag"
          @click="
          donevisible = false;
        donevisibleFlag = true;
        "
        >取消</div>
        <div
          class="doneY"
          v-if="donevisibleFlag"
          @click="doneTrue(doneData.time, doneData.itemId)"
        >确定</div>
      </div>
    </div>
    <!-- 无人机强制接管消息 end-->

    <!-- 收到请求控制权限通知 start-->
    <NoticeInfo
      @close="uav_jursdiction(2)"
      @handle="uav_jursdiction(1)"
      class
      @exit="NoticeFlag = false"
      v-if="NoticeFlag"
      :leftText="uav.NoticeData.leftText"
      :rightText="uav.NoticeData.rightText"
      :content="uav.NoticeData.content"
      :title="uav.NoticeData.title"
    ></NoticeInfo>
    <!-- 收到请求控制权限通知 end-->
    <!-- 警情派发通知 start-->
    <el-dialog
      class="policeSituationvisibleBox"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      :visible.sync="policeSituationvisible"
    >
      <div class="pup_head">
        <div class="flex">
          <div class="pup_img">
            <img src="~@/assets/zong_lan_ye_img/jinye.png" style="width: 100%; height: 100%" alt />
          </div>
          <div class="fontOne">{{ policeSituationObj.status == 4300 ? '警情任务派发通知' : '警情任务派发反馈通知' }}</div>
        </div>
        <div class="fontTwo" @click="policeSituationvisible = false;">关闭</div>
      </div>
      <div class="line"></div>
      <!-- 警情任务派发通知 start -->
      <div class="content" v-if="policeSituationObj.status == 4300">
        <div class="ml10">
          警情内容：
          <span class="dib inputBox pl6 tl">{{ policeSituationObj.msg || "暂无" }}</span>
        </div>
        <div class="wih100 ml4 mt30">
          派发时间：
          <span class="inputBox dib tl">
            {{ policeSituationObj.date ||
            "暂无" }}
          </span>
        </div>

        <div class="tl mt30 mb10 ml27">
          备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注：
          <!-- <el-input style="vertical-align: top;" class="w400" :rows="1" type="textarea"
            :placeholder="policeSituationObj.status == 4301 ? '暂无' : '拒绝请求时需必填！'" v-model="policeSituationData"
          :autosize="{ minRows: 1 }"></el-input>-->
          <el-select
            class="ml6 w400"
            v-model="policeSituationData"
            :placeholder="policeSituationObj.status == 4301 ? '暂无' : '拒绝请求时需必选！'"
            size="mini"
            multiple
            filterable
            allow-create
            default-first-option
          >
            <el-option
              v-for="(item,index) in policeSituationDataType"
              :key="index"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </div>
      </div>
      <!-- 警情任务派发通知 end -->
      <!-- 警情任务派发反馈通知 satrt -->
      <div class="content" v-if="policeSituationObj.status == 4301">
        <div class>
          警情内容：
          <span class="dib inputBox pl6 tl">{{ policeSituationObj.msg || "暂无" }}</span>
        </div>

        <div class="wih100 ml25 tl mt30">
          状&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;态 ：
          <span
            v-if="policeSituationObj.acceptStatus == 0"
            class="ml20"
            style="color:red"
          >已拒绝</span>
          <span v-else class="ml20 fw700 mb30" style="color:green">对方已接收</span>
        </div>
        <div class="wih100 mt30">
          签 收 人 :
          <span class="inputBox dib tl">
            {{ policeSituationObj.responseName ||
            "暂无" }}
          </span>
        </div>
        <div class="wih100 mt30">
          签收时间:
          <span class="inputBox dib tl">
            {{ policeSituationObj.responseTime ||
            "暂无" }}
          </span>
        </div>
        <div class="wih100 mt30">
          签收单位:
          <span class="inputBox dib tl">
            {{ policeSituationObj.responseOrg ||
            "暂无" }}
          </span>
        </div>
        <div class="wih100 mt30 mb30">
          联系方式:
          <span class="inputBox dib tl">
            {{ policeSituationObj.tel ||
            "暂无" }}
          </span>
        </div>
        <!--  -->
        <div class="tl mb10 ml27" v-if="policeSituationData">
          备&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;注:
          <el-input
            style="vertical-align: top;"
            :disabled="true"
            class="w400"
            :rows="1"
            type="textarea"
            placeholder="暂无"
            v-model="policeSituationData"
            :autosize="{ minRows: 1 }"
          ></el-input>
        </div>
      </div>
      <!-- 警情任务派发反馈通知 end -->
      <div class="flex mb10" v-if="policeSituationObj.status == 4300">
        <div class="doneNo" @click="updateDispatchResponseLogFn(0)">拒绝</div>
        <div class="doneY" @click="updateDispatchResponseLogFn(1)">接收</div>
      </div>
    </el-dialog>
    <Distribution @close="distributionType = null" v-if="distributionType == 11"></Distribution>
    <!-- 警情派发通知 end-->
    <!-- 安全预警 start-->
    <dialogNew :isShow="safetyWarning" :data="safetyWarningData">
      <div class="safetyWarningDoneY mb31" slot="footer" @click="safetyWarningClick">签收</div>
    </dialogNew>
    <!-- 安全预警 end-->
    <!-- 全局预警 start-->
    <dialogNew :isShow="visible" :data="warningInfor">
      <div class="safetyWarningDoneY mb31" slot="footer" @click="warningRecord">签收</div>
    </dialogNew>
    <!-- 全局预警 end -->
    <!-- 派发弹窗 start -->
    <Distributionpf
      v-show="warningNoticeStatus"
      @DistributionInfo="DistributionInfo(item)"
      @routerJq="routerJq"
      v-for="(item, index) in waringPFlist"
      :situation_detail="item"
      :_index="index"
      :key="index"
      @close="closeWaringPF(index)"
    ></Distributionpf>
    <!-- 派发弹窗 end -->
    <div class="pup" v-if="tubiao == true">
      <pup :typeName="typeN"></pup>
    </div>
    <!-- v-if="$store.state.home.weatheryjFlag" -->
    <WeatherYjList v-if="$store.state.home.weatheryjFlag"  :list="$store.state.home.weatherList" @close="$store.commit('home/SET_WEATHER_FLAG', false)"></WeatherYjList>
  </div>
</template>

<script>
// 派发弹窗
import Distribution from "@/components/command/flight-task/Distribution";
// 派发弹窗
import Distributionpf from "@/components/Distributionpf";
import Moment from "moment";
import dayjs from "dayjs";
import { mapGetters, mapState } from "vuex";
import API from "@/api";
import Lockr from "lockr";
import Update from "./update";
import WeatherYjList from "./weatheryj";
import CesiumLayer from "@/components/cesium-layer";
import SafeFly from "@/components/header-right/safeFly.vue";
import checkSafe from "@/components/header-right/check-safe.vue";

import Water from "@/components/accident/water";
import factoriconsfkV3 from "@/components/factor-iconsfk_v3";
import Select from "@/components/factor-iconsfk_v3/select";

import FactorIcons from "@/components/factor-icons_v3";
import Hwkeys from "@/components/hwkeys";
import Hwkeysfk from "@/components/hwkeysfk";

import CesiumToolbar from "@/components/cesium-toolbar";
import cesiumToolbarfk from "@/components/cesium-toolbarfk";

import HKVideo from "@/components/hk-video";
import reportPopup from "./report-popup";
import Popup from "./methods/popup";
import NotificationsBar from "@/components/notificationsBar";
// import Message from "@/components/header-right/message.vue";
import Message from "@/pages/home/Confirmation_Dialogs/inform/index.vue";
import data from "./data";
import methods from "./methods";
import NoticeInfo from "@/components/observe/fckernel/uav/control/components/notice";
// 引入组件
import pup from "./pup/index.vue";
// 引入bus
import Bus from "@/assets/ligature.js";
//定时器调用
import { setDate } from "@/utils/setTimeout.js";
import factor from "./methods/factor.js";
import dialogNew from "@/components/dialogNew/index.vue";

let collection = {
  zysb: null,
  jwys: null,
  gzsb: null,
  jlys: null,
  kfq: null,
  zddq: null
};

const hideCesiumPaths = [""];

export default {
  name: "Layout",
  components: {
    Water,
    Update,
    WeatherYjList,
    CesiumLayer,
    FactorIcons,
    CesiumToolbar,
    reportPopup,
    HKVideo,
    Hwkeys,
    SafeFly,
    checkSafe,
    NotificationsBar,
    Message,
    factoriconsfkV3,
    Select,
    cesiumToolbarfk,
    Hwkeysfk,
    NoticeInfo,
    pup,
    Distribution,
    Distributionpf,
    dialogNew
  },
  data() {
    return {
      // 飞行提示-检验是否影响鹰巢周期任务执行 start
      flightTips: {
        title: "校验是否影响鹰巢周期任务执行",
        msg: "当前无人机剩余电量50%，可能对一小时内的周期任务有影响,请知悉"
      },
      flightTipsisShow: false,
      // 飞行提示 end
      // 派发弹窗 start
      waringPFlist: [],
      warningNoticeStatus: true,
      // 派发弹窗 end
      // 安全预警 start
      safetyWarning: false,
      safetyWarningData: {
        title: "预警通知",
        msg: "1321312",
        noticeStatus: 0,
        waringId: 1,
        sendUser: 1
      },
      // 安全预警 end
      //全局预警弹窗start
      visible: false,
      warningInfor: {
        title: "电量不足",
        msg: "电量不足提示"
      },
      //全局预警弹窗 end
      // 应急打卡提醒 start
      signType: false, //应急打卡提醒
      signData: {
        title: "应急打卡提醒",
        msg: "平台进入应急状态，请进行报备！"
      },
      //应急打卡提醒 end
      donevisible: false, // 无人机强制接管消息
      // 警情弹窗口
      distributionType: null,
      policeSituationvisible: false,
      policeSituationData: "",
      policeSituationDataType: [
        { name: "设备维修" },
        { name: "人不在岗" },
        { name: "无需协飞" },
        { name: "天气条件不允许" }
      ],
      policeSituationObj: {
        msg:
          "收到一条警情任务，请确认是否接收执行！收到一条警情任务，请确认是否接收执行！收到一条警情任务，请确认是否接收执行！收到一条警情任务，请确认是否接收执行！",
        user: "yanlpcs,092436,092072,245988",
        taskId: "40700",
        dispatchOrg: "机构",
        responseOrg: "签收单位",
        date: "2022-02-10 22:22:00",
        acceptStatus: 0, //1 同意 0 拒绝
        status: "4300",
        responseName: "xxxx",
        tel: "1766032323",
        responseTime: "2022-02-10 22:22:00"
      },
      messagenum: [], //数据刷新，需要重新调接口
      updateShow: false, //更新公告展示
      updateData: {}, //更新公告内容
      ziyuan: false, //记录总览页-安全飞行不会被资源报备连动打开(搜索关键词:设备报备)
      suo: true, //总览页的安全飞行只打开一次
      ab: true,
      tubiao: false, // 点击右边图标,弹出弹框
      typeN: "", //判断是点击的是哪个识别
      ...data,
      floating_menuFlag: true,
      flag: false,

      donevisibleFlag: true,
      doneData: {}, //点调信息
      videoFlag: false,
      waterebox: false,
      FlyVisible: false, //安全检查弹窗
      checkVisible: false, //飞行检查弹窗
      title: "警用无人机一体化作战平台",
      isAlarm: false, //预警弹窗
      isHint: false, //预警
      report_is_show: false, //设备报备
      hk_is_show: false,
      qysFlag: false, //侧边工具栏
      offset: false, //侧边工具栏
      move_id_arr: [],
      zdys_id_arr: [],
      showmessage: false, //消息通知
      NoticeFlag: false, //接管权限弹窗
      setting: {
        id: 1
      },
      sysParams: {
        appkey: "28701077",
        secret: "TQJZOh2ItVom0iLmecw7",
        ip: "32.128.12.96",
        port: 443
      },
      list: [],
      full_list_timer: null,
      routeColor: false, // 判断当前是否为新总览页，是则改颜色
      cesium_layer: null,
      weatherYjList:[],//天气预警列表
      weatheryjFlag:false
    };
  },
  created() {
    Bus.$on("icon_message", item => {
      this.typeN = item;
      this.tubiao = true;
    });
    this.update_date();
  },
  watch: {
    "$store.state.device.warningNotice": {
      handler(value) {
        this.warningNoticeStatus = value;
      },
      deep: true
    },
    "$route.path": {
      handler(value) {
        if (["/fckernelVideos", "/observe/home"].includes(value)) {
          this.flag = false;
        } else {
          this.flag = true;
        }
        if (
          [
            "/fckernelVideos",
            "/fckernel",
            "/observe/home",
            "/accident"
          ].includes(value)
        ) {
          this.floating_menuFlag = false;
        } else {
          this.floating_menuFlag = true;
        }
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true
    },
    "$store.state.device.flag": {
      handler(value) {
        this.qysFlag = value;
      },
      deep: true
    },
    "$store.state.device.waterbox"(newData) {
      this.waterebox = newData;
    },
    // "$store.state.device.videoFlag"(newData) {
    //   this.videoFlag = newData;
    // },
    visibleMainMap: {
      immediate: true,
      handler() {
        // console.log('watch visibleMainMap', this.visibleMainMap)
        if (this.visibleMainMap) {
          this.$nextTick(() => {
            try {
              this.$refs.cesium_layer.init();
            } catch (e) {
              console.log("ceisum_layer init fail", e);
            }
          });
        }
      }
    }
  },
  methods: {
    // 预警记录
    async warningRecord() {
      this.visible = false;
      let id = this.warningInfor.waringId;
      await API.SITUATION.device_waringUpdate({
        id,
        isClosed: 1
      });
    },
    // 跳转到警情列表
    routerJq() {
      if (this.$route.fullPath != "/command?page=5&status=jq") {
        this.$router.push({
          name: "command",
          query: {
            page: 5,
            status: "jq"
          }
        });
        if (this.$route.fullPath == "/command") {
          window.location.reload();
        }
      }
    },
    async DistributionInfo(item) {
      let res = await API.SITUATION.Detail(item.taskId);
      if (this.$route.fullPath != "/command?page=5&status='pf'") {
        this.$router.push({
          name: "command",
          query: {
            page: 5,
            status: "pf"
          }
        });
        if (this.$route.fullPath == "/command") {
          window.location.reload();
        }
      }
      this.$store.commit("situation/SITUATION_DETAIL", res);
    },
    closeWaringPF(index) {
      this.waringPFlist.splice(index, 1);
    },
    async safetyWarningClick() {
      let { waringId, sendUser } = this.safetyWarningData;
      let res = await API.SITUATION.sendNotice({
        waringId,
        isNotice: 1,
        sendUserId: sendUser
      });
      this.$message.success("操作成功");
      this.safetyWarning = false;
    },
    // 打开派发窗口
    async pfFn() {
      let res = await API.SITUATION.Detail(this.policeSituationObj.taskId);
      this.$store.commit("situation/SITUATION_DETAIL", res);
      this.policeSituationvisible = false;
      this.distributionType = 11;
    },
    async updateDispatchResponseLogFn(num) {
      if (num == 1) {
        this.$router.push({
          name: "command",
          query: {
            page: 5,
            status: "jq"
          }
        });
      }
      let { taskId } = this.policeSituationObj;
      if (num == 0) {
        if (this.policeSituationData.length == 0 || !this.policeSituationData) {
          return this.$message.warning("请选择备注信息！");
        }
      }
      let res = await API.SITUATION.updateDispatchResponseLog({
        jingQingId: Number(taskId),
        acceptStatus: num,
        remark: this.policeSituationData && this.policeSituationData.join(",")
      });
      this.$message.success("操作成功");
      this.policeSituationvisible = false;
    },
    async yjdk() {
      let res = await API.USER.addUrgentCardLog({ signType: 1 });
      console.log(res);
      if (res.code == 200) {
        this.$message.success("应急打卡成功！");
        this.signType = false;
      }
    },
    async getupdate() {
      let res = await API.HOME.getmessagecontent({
        isRead: 0,
        type: 7
      });
      // console.log(res,"update");
      if (res.length > 0) {
        console.log(res[0]);
        this.updateData = res[0];
        this.updateShow = true;
      }
    },
    pup_gj() {
      this.ab = false;
    },
    // 给pup子组件调用的方法
    close() {
      // 关闭弹窗功能
      this.tubiao = false;
    },
    async time(date) {
      let dateA = new Date();
      let y = dateA.getFullYear();
      let m =
        dateA.getMonth() + 1 < 10
          ? "0" + (dateA.getMonth() + 1)
          : date.getMonth() + 1;
      let d = dateA.getDate() < 10 ? "0" + dateA.getDate() : dateA.getDate();
      let h = dateA.getHours() < 10 ? "0" + dateA.getHours() : dateA.getHours();
      let mi =
        dateA.getMinutes() < 10 ? "0" + dateA.getMinutes() : dateA.getMinutes();
      let s =
        dateA.getSeconds() < 10 ? "0" + dateA.getSeconds() : dateA.getSeconds();
      let startDateTime = y + "-" + m + "-" + d + " " + h + ":" + mi + ":" + s;
      // console.log(startDateTime,"startDateTimestartDateTimestartDateTime");
      // let startDateTime = await API.WEATHER.getDate()
      let date1 = new Date(startDateTime);
      let date2 = new Date(date);

      let s1 = date1.getTime(),
        s2 = date2.getTime();
      let total = (s2 - s1) / 1000;

      let day = parseInt(total / (24 * 60 * 60)); //计算整bai数天du数
      let afterDay = total - day * 24 * 60 * 60; //取得值算出天数后dao剩余的转秒数shu
      let hour = parseInt(afterDay / (60 * 60)); //计算整数小时数
      let afterHour = total - day * 24 * 60 * 60 - hour * 60 * 60; //取得算出小时数后剩余的秒数
      let min = parseInt(afterHour / 60); //计算整数分
      let afterMin = total - day * 24 * 60 * 60 - hour * 60 * 60 - min * 60; //取得算出分后剩余的秒数

      // console.log("day", day);
      // console.log("afterDay", afterDay);
      // console.log("hour", hour);
      // console.log("afterHour", afterHour);
      // console.log("min", min);
      console.log("afterMin", afterMin);
      return afterMin;
      //  let hous=(afterDay/3600).toFixed(2)//截取小数点后两位.toFixed(2)
    },
    async doneTrue(time, id) {
      let date = this.time(time);
      let feedBack = null;
      if (date > 300) {
        feedBack = 1;
      } else {
        feedBack = 0;
      }
      let data = [
        {
          id: id
          // feedBack: feedBack
        }
      ];
      let res = await API.TONE.updateFeedback(data);
      this.donevisible = false;
      this.donevisibleFlag = true;
    },
    init_ws() {
      const data = new Date();
      let time = data.getHours();
      let ws_url_al = process.env.VUE_APP_WS_URL_ALARM;
      let ws = new WebSocket(ws_url_al);
      let _this = this;
      let { appid, username } = JSON.parse(
        localStorage.getItem("user_info")
      ).data;
      let token = JSON.parse(localStorage.getItem("user_info")).data[
        "mmc-identity"
      ];
      ws.onopen = () => {
        console.log("webscoket7");

        ws.send(
          JSON.stringify({
            type: 100,
            systemCode: "mmc",
            state: 1,
            username,
            token,
            appId: appid
          })
        );
      };
      //type:700
      // msgnum:4110(连接成功)
      // msgnum:4111(未登录连接断开)
      // msgnum:4112(消息发送成功)
      // msgnum:4113(重复登录)
      // msgnum:4114(人脸数据更新)
      // msgnum:4115(车牌数据更新)
      // msgnum:4116(预警信息)
      // msgnum:4117(停止预警)
      // msgnum:4118(数据错误)
      // msgnum:4119(心跳)
      // msgnum:4120(人脸密度预警消息)
      // msgnum:4131(天气信息)
      // msgnum:4132(发布信息)
      // msgnum:4133(临时禁飞区信息)
      // msgnum:4134(无人机预警信息)
      // msgnum:4135(无人机接管请求)
      // msgnum:4136(无人机接管请求响应)
      // msgnum:4137(关闭无人机接管请求)
      // msgnum:4138(任务飞行结束)
      // msgnum:4139(设备状态更新通知)
      // msgnum:4140(点调通知)
      // msgnum:4141(开始点调通知)
      // msgnum:4142(鹰训通知)
      // msgnum:4143(无人机权限切换结果)
      // msgnum:4144(鹰训考试)
      // msgnum:4145(无人机强制接管消息)
      // msgnum:4200(应急打卡)
      // msgnum:4300(警情任务派发通知)
      // msgnum:4301(警情任务派发反馈通知)
      // msgnum:4302(刷新警情任务列表)
      // msgnum:4400(安全 预警弹出框)
      // msgnum:4303警情通知)
      // msgnum:4800 检验是否影响鹰巢周期任务执行

      ws.onmessage = async e => {
        let metadata = null;
        try {
          metadata = JSON.parse(e.data);
        } catch (e) {
          console.log(e);
          return;
        }
        // 在此处监听全局临时取消临时禁飞区
        if (metadata.type == 700) {
          if (metadata.data.wsSendUrlEnums[0] == 14) {
            //  删除所有实体
            this.factor_entities();
            // 重新请求数据
            this.factor_show_xfq()
              .then(res => {
                console.log(res);
              })
              .catch(res => {
                // console.log(res.message,'错误');
              });
          }
          if (metadata.data.wsSendUrlEnums) {
            // 刷新任务列表
            if (metadata.data.wsSendUrlEnums.includes(2)) {
              let time = Date.now();
              this.$store.commit("device/SET_CTLS_LIST", time);
            }
            if (metadata.data.wsSendUrlEnums.includes(1)) {
              let time = Date.now();
              this.$store.commit("device/SET_JQ_LIST", time);
            }
          }
        }
        // 飞行提示
        if (metadata.msgnum == 4800) {
          this.flightTips = {
            msg: metadata.data,
            title: metadata.title
          };
          this.flightTipsisShow = true;
          this.$message.warning(metadata.data);
        }
        // 警情任务派发通知
        if (metadata.msgnum === 4300) {
          this.policeSituationData = null;
          this.policeSituationObj = metadata.data;
          this.policeSituationvisible = true;
        }
        // 警情任务派发反馈通知
        if (metadata.msgnum === 4301) {
          this.policeSituationObj = metadata.data;

          this.policeSituationData = metadata.data.remark;
          this.policeSituationvisible = true;
          // 对方已接收的话刷新警情列表
          if (metadata.data.acceptStatus == 1) {
            let time = Date.now();
            // 刷新飞行任务-警情任务列表
            this.$store.commit("device/SET_JQ_LIST", time);
          }
        }
        if (metadata.msgnum === 4302) {
          let time = Date.now();
          // 刷新飞行任务-警情任务列表
          this.$store.commit("device/SET_JQ_LIST", time);
        }

        if (metadata.msgnum === 4400) {
          if (metadata.data && metadata.data.noticeStatus == 1) {
            // 刷新
            let time = Date.now();
            // 刷新飞行任务-警情任务列表
            this.$store.commit("device/SET_SAFETY_WARNING", time);
          } else {
            this.safetyWarningData = {
              ...metadata.data,
              msg: metadata.data.waring
            };
            this.safetyWarning = true;
          }
          if (metadata.data.wsSendUrlEnums) {
            if (metadata.data.wsSendUrlEnums.includes(num => num == 1)) {
              console.log(metadata.data, "metadata.data700");
            }
          }
        }
        // 警情通知
        if (metadata.msgnum === 4303) {
          this.waringPFlist.push(metadata.data);
        }
        // 警情任务派发通知
        if (metadata.msgnum === 4300) {
          this.policeSituationData = null;
          this.policeSituationObj = metadata.data;
          this.policeSituationvisible = true;
        }
        // 警情任务派发反馈通知
        if (metadata.msgnum === 4301) {
          this.policeSituationObj = metadata.data;

          this.policeSituationData = metadata.data.remark;
          this.policeSituationvisible = true;
          // 对方已接收的话刷新警情列表
          if (metadata.data.acceptStatus == 1) {
            let time = Date.now();
            // 刷新飞行任务-警情任务列表
            this.$store.commit("device/SET_JQ_LIST", time);
          }
        }
        if (metadata.msgnum === 4302) {
          let time = Date.now();
          // 刷新飞行任务-警情任务列表
          this.$store.commit("device/SET_JQ_LIST", time);
        }
        if (metadata.msgnum === 4400) {
          if (metadata.data && metadata.data.noticeStatus == 1) {
            // 刷新
            let time = Date.now();
            // 刷新飞行任务-警情任务列表
            this.$store.commit("device/SET_SAFETY_WARNING", time);
          } else {
            this.safetyWarningData = {
              ...metadata.data,
              msg: metadata.data.waring
            };
            this.safetyWarning = true;
          }
        }
        // 全局预警弹窗
        if (metadata.msgnum === 4134) {
          this.warningInfor = {
            ...metadata.data,
            msg: metadata.data.waring
          };
          setTimeout(() => {
            this.visible = true;
          }, 200);
          // 临时禁飞区
        } else if (metadata.msgnum == 4133) {
          this.g_cesium_resetJfq();
        } else if (metadata.msgnum == 4140) {
          // console.log(metadata, "metadatametadata");
          this.doneData = metadata.data;
          this.donevisible = true;
        }
        // 无人机接管请求
        // 无人机接管请求
        if (metadata.msgnum == 4135) {
          this.uav_showNoticeInfo(metadata.data);
        }
        if (metadata.msgnum == 4136) {
          this.$message.success(metadata.data.msg);
        }
        if (metadata.msgnum == 4145) {
          this.doneData = {
            msg: metadata.data.msg,
            title: "接管请求通知"
          };
          this.donevisibleFlag = false;
          this.donevisible = true;
        }
        if (metadata.msgnum == 4200) {
          this.signType = true;
        }
        if (metadata.data?.wsSendUrlEnums) {
          // console.log(metadata.data.wsSendUrlEnums,"wsSendUrlEnums");
          this.messagenum = metadata.data.wsSendUrlEnums;
        }
        if (metadata.msgnum == 4900) {
          console.log('鹰巢任务监听刷新列表与航线');
          // 鹰巢任务监听刷新列表与航线
          this.$store.commit("fckernel/set_state", {
            key: "nest_task_refresh",
            value: JSON.parse(metadata.data)
          });
          // 刷新
          this.$store.commit("fckernel/set_state", {
            key: "nest_task_srtatus",
            value: new Date().getTime()
          });
        }
        //天气接口刷新，预警
        if(metadata.msgnum == 4131){
          // this.getWeather()
        }
      };
      ws.onerror = error => {
        console.log("error111", error);
      };

      ws.onclose = data => {
        console.log("onclose111", data);
      };
    },
    async getWeather(){
      let res = await API.WEATHER.getNewWeather();
      this.$store.commit("home/SET_WEATHER", res);
      if (res.length > 0) {
        let weather = res[0]
        if(weather.alert){
          weather.alert.forEach(val=>{
            val.img = require(`@/assets/images/weather/yj/icon_weather_warning_${val.infoid}.png`)
          })
          this.weatherYjList = weather.alert
          this.weatheryjFlag = true
        }
      }

    },
    showInfo(id) {
      if (id) {
        // console.log(id, "id------------------------------");
        this.$refs.messageRef.newsId = id;
        this.showmessage = true;
        this.$refs.messageRef.messagecenten(null);
      }
    },
    change_offset(e) {
      this.offset = e;
    },
    handle_close(e) {
      if (e == 1) {
        this.isAlarm = false;
      } else {
        this.isHint = false;
      }
    },
    // 关闭资源报备
    handle_report_show(e, num) {
      //num是总览页下报备情况-资源报备-设备报备-关闭(关键字:设备报备)
      this.report_is_show = e;
      // 安全飞行注意事项会在进入页面时会连动打开一次,但是测试要求第二次关闭'设备报备'时,不能打开'安全飞行注意事项'
      if (num == "设备报备") {
        if (this.ziyuan == true) {
          date;
          return;
        }
        this.ziyuan = true;
      }

      if (!e) {
        this.FlyVisible = true;
        this.$refs.SafeFlyRef && this.$refs.SafeFlyRef.securityFly();
      }
    },
    getTime(getDate) {
      let date = new Date(getDate);
      let Year = date.getFullYear();
      let Month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let Day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let Hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let Minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let Seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      let getDay = date.getDay();
      return {
        Year,
        Month,
        Day,
        Hours,
        Minutes,
        Seconds,
        getDay
      };
    },
    // 时间组件
    async update_date() {
      /* let getDate = await API.WEATHER.getDate();
        let { Year, Month, Day, getDay, Hours, Minutes, Seconds } =
          this.getTime(getDate);
         */
      const moment = Moment();
      this.date.date = moment.format("YYYY/MM/DD");
      this.date.hour = moment.hour() + ":" + moment.minute();
      this.date.week = this.date.week_map[moment.day()];
    },
    async get_weather() {
      try {
        let day = dayjs().format("DD/MM/YYYY HH");
        let res = await API.WEATHER.getNestWeather();
        if (res) {
          let hour = dayjs().hour();
          let newData = res.find(ele => {
            return ele.id == 8;
          });
          let sd = newData.humidity;
          let list = {
            ...newData,
            icon: require(`@/assets/images/weather/icon/${
              hour >= 18 ? "night" : "day"
            }/${newData.weatherCode}.png`),
            sd
          };
          this.weather.current = list;
          this.weather.day = day;
        }
      } catch (error) {}
    },
    // 多久调用一次时间接口
    init_timer() {
      this.timer = window.setTimeout(() => {
        this.update_date();
        // this.update_weather();
      }, 1000);
      // setDate.timer(this.timer,this.update_date,1000)
    },
    destroy_timer() {
      window.clearInterval(this.timer);
    },

    handle_change_plate_mode(val) {
      if (val) {
        // 板块模式
        Object.keys(this.collection).forEach(key => {
          this.factor_hide_collection(key);
        });
      } else {
        // 地图模式;
        Object.keys(this.collection).forEach(key => {
          this.factor_show_collection(key);
        });
      }
    },

    set_title(title) {
      this.title = title;
    },
    click_billboard() {
      let viewer = this.$refs["cesium_layer"].viewer();
      const _this = this;

      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(async movement => {
        let pickedLabel = viewer.scene.pick(movement.position);
        console.log(pickedLabel.id.name, "pickedLabel");
        var pick = new Cesium.Cartesian2(
          movement.position.x,
          movement.position.y
        );
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          if (pickedLabel.id.name == "area") {
            try {
              let data = JSON.parse(pickedLabel.id.id);
              let arr = _this.move_id_arr;
              if (arr.indexOf(data.id) < 0) {
                _this.move_id_arr.push(data.id);
                // 调用弹窗方法
                let popup = new Popup({
                  viewer: viewer,
                  geometry: cartesian,
                  data: data,
                  arr: _this
                });
              }
            } catch (error) {}
          } else {
            try {
              let data = JSON.parse(pickedLabel.id);
              let arr = _this.zdys_id_arr;
              if (arr.indexOf(data.id) < 0) {
                _this.zdys_id_arr.push(data.id);
                // && data?.entityType == "zdys"
                if (cartesian) {
                  // 调用弹窗方法
                  let popup = new Popup({
                    viewer: viewer,
                    geometry: cartesian,
                    data: data,
                    del_real: this.del_real,
                    arr: _this
                  });
                }
                if (data?.category === 1) {
                  this.start_timer(data);
                }
              }
            } catch (error) {}
          }
        }
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
    },
    move_billboard() {
      let viewer = this.$refs["cesium_layer"].viewer();
      let scene = viewer.scene;
      const _this = this;

      let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
      handler.setInputAction(function(movement) {
        let pickedLabel = viewer.scene.pick(movement.endPosition);
        var pick = movement.endPosition;
        if (Cesium.defined(pickedLabel)) {
          // 获取点击位置坐标
          let cartesian = viewer.scene.globe.pick(
            viewer.camera.getPickRay(pick),
            viewer.scene
          );
          try {
            let data = JSON.parse(pickedLabel.id);
            let arr = _this.move_id_arr;
            if (arr.indexOf(data.id) < 0 && data?.entityType == "xfq") {
              _this.move_id_arr.push(data.id);
              // 调用弹窗方法
              let popup = new Popup({
                viewer: viewer,
                geometry: cartesian,
                data: data,
                arr: _this
              });
            }
          } catch (error) {}
        }
      }, Cesium.ScreenSpaceEventType.MOUSE_MOVE);
    },
    click_hk_video() {
      let _this = this;
      $("#layer-container").on("click", ".hk-link", function() {
        let code = $(this).data("code");
        _this.hk_is_show = true;
        _this.setting = {
          // id: code,
          id: 32090017102161000017
        };
        _this.list = [
          {
            // cameraIndexCode: code,
            cameraIndexCode: 32090017102161000017,
            ezvizDirect: 0,
            gpuMode: 0,
            streamMode: 0,
            transMode: 1,
            wndId: 1
          }
        ];
      });
    },
    hiden_hk_video() {
      this.hk_is_show = false;
    },
    start_timer(data) {
      if (!data.deviceHardId) return;
      let viewer = this.$refs["cesium_layer"].viewer();
      this.full_list_timer = window.setInterval(async () => {
        const res = await API.HOME.GetUavTrack({
          // deviceHardId: "MMC123456789",
          deviceHardId: data.deviceHardId
        });
        this.uav_get_track(viewer, res);
      }, 2000);
    },
    del_real(id) {
      let viewer = this.$refs["cesium_layer"].viewer();
      window.clearInterval(this.full_list_timer);
      this.full_list_timer = null;
      // viewer.entities.removeById("home_flightTrajectory_MMC123456789");
      // viewer.entities.removeById(`home_finished_flightTrajectory_MMC123456789`);
      viewer.entities.removeById(`home_flightTrajectory_${id}`);
      viewer.entities.removeById(`home_finished_flightTrajectory_${id}`);
    },
    /**
     * cesium地图初始化完成事件
     */
    async onCesiumInitDone() {
      // console.log('onCesiumInitDone', this.visibleMainMap)
      if (!this.visibleMainMap) {
        return;
      }

      this.$refs["router-view"]?.cesium_layer_change_mode &&
        (await this.$refs["router-view"].cesium_layer_change_mode(
          this.$refs["cesium_layer"].fly_to_by_mode,
          this.$refs["cesium_layer"].MODES
        ));

      // await this.uav_list()

      this.click_billboard();
      this.move_billboard();
      this.click_hk_video();

      // 关闭地图深度检测
      let viewer = this.$refs["cesium_layer"].viewer();
      viewer.scene.globe.depthTestAgainstTerrain = false;
    },
    ...methods
  },
  computed: {
    ...mapState({
      visibleMainMap: state => state.mapmanage.visibleMainMap
    }),
    cesium_layer() {
      return this.$refs["cesium_layer"];
    },
    cesium_toolbar() {
      return this.$refs["cesium_toolbar"];
    },
    collection() {
      return collection;
    },
    ...mapGetters([
      "user_info",
      "isShow",
      "toolbarIsShow",
      "isShowFactorIcons"
    ]),
    ai_link() {
      let token1 = this.user_info["FLYINGSESSIONID"];
      let token2 = this.user_info["mmc-identity"];
      let uid = this.user_info["uid"];
      return `${process.env.VUE_APP_LINK_URL}/hawk_ai/#/home?type=1&FLYINGSESSIONID="${token1}"&mmcIdentity="${token2}"&uid="${uid}"`;
    },
    //浮动菜单定位
    floatMenuPos() {
      if (this.$route.fullPath === "/command?page=11") {
        return ["near-right"];
      }
      return [];
    }
  },
  provide() {
    return {
      g_cesium_layer: () => this.$refs["cesium_layer"],
      g_cesium_modes: () => this.$refs["cesium_layer"].MODES,
      g_cesium_change_mode: e => this.$refs["cesium_layer"].change_mode(e),
      g_cesium_add_polyline: () => this.$refs["cesium_layer"].add_polyline,
      g_cesium_resetJfq: () => this.factor_resetJfq(),
      g_collection: () => this.collection,
      g_date: () => this.date,
      g_weather: () => this.weather,
      g_set_title: this.set_title,
      g_user_info: () => this.user_info,
      g_show_zysb: this.factor_show,
      g_hide_zysb: this.factor_shang_hide,
      g_cesium_toolbar: () => this.cesium_toolbar,
      handle_report_show: this.handle_report_show,
      g_change_offset: this.change_offset
    };
  },
  async mounted() {
    console.log(this.$route,"route");
    this.getWeather()
    this.cesium_layer = this.$refs["cesium_layer"];
    this.getupdate();
    this.init_ws();
    this.init_timer();
    // this.get_weather();
    // this.factor_init_ws();
    // 禁飞区

    this.factor_show_xfq();
    if (this.$route.path === "/home") {
      let cardStatus = localStorage.getItem("cardStatus");
      if (cardStatus) {
        this.handle_report_show(true);
      }
    }
  },
  beforeDestroy() {
    this.destroy_timer();
  }
};
</script>

<style lang="scss" scoped>
.cpt-layout {
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;

  .cpt-layout-alarm {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .floating-menuFkSelect {
    position: absolute;
    top: 70px;
    right: 14px;
  }

  .CesiumToolbarfk {
    position: fixed;
    bottom: 202px;
    right: 10px;
  }

  .cpt-layout-close {
    position: absolute;
    top: 2px;
    z-index: 200;
    cursor: pointer;

    color: red;
    font-size: 40px;
    right: 11px;
  }

  .cpt-layout-alarm-hint {
    background: url("~@/assets/images/layout_alarm.png") no-repeat;
    position: absolute;
    width: 489px;
    height: 162px;
    top: 24%;
    left: 50%;
    margin-left: -245px;
    z-index: 800;
    box-sizing: border-box;

    .title {
      font-family: PangMenZhengDao;
      font-size: 24px;
      color: #dce9ff;
      font-weight: 400;
      padding-left: 125px;
      margin-top: 18px;
    }

    .floating-menu {
      position: fixed;
      top: 25%;
      margin-top: -130px !important;
      right: 25%;
      width: 50px;
      height: 360px;
      text-align: center;
      z-index: 5;

      .header {
        display: inline-block;
        margin: 0 auto;
        width: 50px;
        height: 2px;
        border-top: 2px solid #43deff;
        border-bottom: 2px solid #43deff;
        background-color: #43deff;
        padding: 2px 0;
        background-clip: content-box;
        display: inline-block;
      }

      .content {
        text-decoration: none;
        outline: none;
        color: red;
        display: block;
        margin-top: 30px;
        margin-left: 65px;
      }
    }
  }

  .floating-menu {
    position: fixed;
    top: 25%;
    margin-top: -130px !important;
    right: 472px;
    width: 50px;
    height: 360px;
    text-align: center;
    z-index: 5;

    &.near-right {
      right: 20px;
    }

    .header {
      display: inline-block;
      margin: 0 auto;
      width: 50px;
      height: 2px;
      border-top: 2px solid #43deff;
      border-bottom: 2px solid #43deff;
      background-color: #43deff;
      padding: 2px 0;
      background-clip: content-box;
      display: inline-block;
    }
  }

  .qysStyleRight {
    // right: 500px;
    transition: 0.6s;
  }

  .qysStyleLeft {
    right: 20px;
    transition: 0.3s;
  }

  .offset {
    right: 568px;
    transition: 0.1s;
  }

  .flagpath {
    top: 25% !important;
  }
}
</style>

<style lang="scss">
// 重要元素弹窗
.bx-popup-ctn {
  min-width: 100px;
  position: absolute;
  // z-index: 999;
  padding: 10px;
  opacity: 0.9;
  background: #0c3150;
  border-radius: 8px;
  color: #fff;
  // 20211103修改代码
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #04d5da;
  border-radius: 8px;

  &.active {
    background: rgba(192, 98, 30, 0.7);
    border: 1px solid #c0621e;

    .bx-popup-tip {
      background: rgba(192, 98, 30, 0.7);
      border: 1px solid #c0621e;
    }
  }
}

.bx-popup-ctn-yc {
  min-width: 100px;
  position: absolute;
  // z-index: 999;
  padding: 10px;
  opacity: 0.9;
  background: #0c3150;
  border-radius: 8px;
  color: #fff;
  // 20211103修改代码
  background: rgba(73, 14, 14, 0.81);
  // border: 1px solid #04d5da;
  border-radius: 8px;

  &.active {
    background: rgba(192, 98, 30, 0.7);
    border: 1px solid #c0621e;

    .bx-popup-tip {
      background: rgba(192, 98, 30, 0.7);
      border: 1px solid #c0621e;
    }
  }
}

.bx-popup-tip-container {
  width: 40px;
  height: 20px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  margin-top: 10px;
  overflow: hidden;
  pointer-events: none;
}

.bx-popup-tip {
  width: 17px;
  // background: #0c3150;
  background: rgba(22, 29, 45, 0.7);
  border: 1px solid #04d5da;
  height: 17px;
  padding: 1px;
  margin: -10px auto 0;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bx-popup-header-ctn {
  color: #fff;
  font-size: 16px;
  padding: 4px;
  padding-right: 20px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 700;
  border-bottom: 1px solid #3b4862;
}

.bx-popup-header-ctn-yc {
  color: #fff;
  font-size: 16px;
  padding: 4px;
  padding-right: 20px;
  color: #ccf5ff;
  letter-spacing: 0;
  font-weight: 700;
  border-bottom: 1px solid #3b4862;
}

.bx-popup-content-ctn {
  padding: 10px 0;
  font-size: 14px;

  .li {
    display: flex;
    margin-bottom: 8px;

    .label {
      color: #afbdd1;
    }

    .hk-link {
      color: #01f5fd;
      cursor: pointer;
    }
  }
}

.el-icon-close {
  color: #8aabd4;
  font-size: 16px;
  position: absolute;
  right: 7px;
  top: 16px;
  transform: translateY(-50%);
  cursor: pointer;

  &:hover {
    color: #fff;
  }
}

.dialog {
  position: fixed;
  top: 300px;
  padding-bottom: 10px;
  left: 750px;
  z-index: 9999999;
  width: 457px;
  min-height: 146px;
  // background: rgba(50, 2, 2, 0.7);
  background: url("../../assets/images/warn/yj.png") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  // align-items: center;
  .head_title {
    // background: rgba(50, 2, 2, 0.7);
    // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
    // border-radius: 10px 10px 0 0;
    margin: 8px;
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #fd4d4d;
    text-align: center;
    font-weight: 700;

    .title {
      background: none;
    }
  }

  .line {
    width: 457px;
    height: 2px;
    align-self: center;
    // background: #FD4D4D;
    background-image: linear-gradient(
      244deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(237, 89, 68, 1) 50%,
      rgba(255, 255, 255, 0) 100%
    );
  }

  .content {
    padding-top: 16px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: calc(100% - 35px);
    // padding: 30px;
    // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fd4d4d;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
  }
}

.donedialog {
  position: fixed;
  top: 300px;
  left: 750px;
  z-index: 9999999;
  width: 457px;
  min-height: 146px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  border-radius: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  // align-items: center;
  .head {
    // background: rgba(50, 2, 2, 0.7);
    // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
    // border-radius: 10px 10px 0 0;
    margin: 8px;
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-weight: 700;
  }

  .line {
    width: 457px;
    height: 2px;
    align-self: center;
    // background: #FD4D4D;
    // background-image: linear-gradient(
    //   244deg,
    //   rgba(255, 255, 255, 0) 0%,
    //   rgba(237, 89, 68, 1) 50%,
    //   rgba(255, 255, 255, 0) 100%
    // );
  }

  .content {
    padding-top: 16px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    height: calc(100% - 35px);
    // padding: 30px;
    // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 28px;
    font-weight: 400;
  }

  .flex {
    width: 200px;
    margin-top: 20px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    line-height: 32px;

    .doneN {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #606266;
    }

    .doneY {
      margin-left: 50px;
      color: #fff;
      min-width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      box-shadow: inset 0 0 5px #00ffff;
      background: rgba(4, 227, 227, 0.1);
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
    }

    .doneNo {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: rgba(185, 7, 7, 0.4);
      border: 1px solid #e9092e;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.policeSituationvisible {
  position: fixed;
  top: 300px;
  left: calc(50% - 300px);
  z-index: 9999999;
  width: 600px;
  min-height: 146px;
  background: rgba(0, 39, 121, 0.5);
  box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 0.7),
    inset 0px 0px 64px 0px rgba(26, 138, 227, 0.35),
    inset 0px 0px 8px 0px #019aff;
  border: 1px solid #43deff;
  backdrop-filter: blur(2px);
  border-radius: 10px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;

  // align-items: center;
  .head {
    // background: rgba(50, 2, 2, 0.7);
    // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
    // border-radius: 10px 10px 0 0;
    margin: 8px;
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-weight: 700;
  }

  .pup_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px 0 12px;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .pup_img {
      display: flex;
      width: 19px;
      height: 25px;
      margin: 0 4px 0 0;
    }

    .fontOne {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .fontTwo {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      cursor: pointer;
      line-height: 20px;
    }
  }

  .line {
    width: 457px;
    height: 2px;
    align-self: center;
    // background: #FD4D4D;
    // background-image: linear-gradient(
    //   244deg,
    //   rgba(255, 255, 255, 0) 0%,
    //   rgba(237, 89, 68, 1) 50%,
    //   rgba(255, 255, 255, 0) 100%
    // );
  }

  .content {
    padding-top: 16px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    text-align: left;
    height: calc(100% - 35px);
    // padding: 30px;
    // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    font-weight: 400;
  }

  .inputBox {
    width: 400px;
    // min-height: 120px;
    background-color: #02173d;
    border: 1px solid #2b56b7;
    font-size: 14px;
    color: #00aacb;
    border-radius: 5px;
    text-indent: 1rem;
    vertical-align: top;
  }

  .flex {
    min-width: 175px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    line-height: 32px;

    .doneN {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #606266;
    }

    .doneY {
      margin-left: 50px;
      color: #fff;
      min-width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      box-shadow: inset 0 0 5px #00ffff;
      background: rgba(4, 227, 227, 0.1);
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
    }

    .doneNo {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: rgba(185, 7, 7, 0.4);
      border: 1px solid #e9092e;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #ffffff;
    }
  }
}

.assimilation {
  border-top: 2px solid #1772c1 !important;
  border-bottom: 2px solid #1772c1 !important;
  background-color: #1772c1 !important;
  // border: 1px solid #1772c1;
}

.pup {
  position: absolute;
  top: 10%;
  left: 5%;
}
</style>
<style lang="scss" scoped>
/deep/ .el-input__inner {
  background-color: #02173d;
  color: #00aacb;
  border: 1px solid #2b56b7;
}
.dialog_dk {
  top: 300px;
  left: 750px;
  width: 457px;
  height: 146px;
  background: url("../../assets/images/warn/yj.png") no-repeat center center;
  background-size: cover;
  box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
  border-radius: 10px;
  border-radius: 10px;
  overflow: hidden;

  /deep/ .el-dialog {
    width: 457px;
    height: 146px;
    margin-top: 0 !important;
    background: transparent;

    .el-dialog__header {
      padding-bottom: 0;
      padding-top: 10px;

      .el-dialog__title {
        color: #fff;
      }
    }

    .el-dialog__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        padding-top: 16px;
        border-radius: 0 0 10px 10px;
        width: 100%;
        height: calc(100% - 35px);
        // padding: 30px;
        // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #fd4d4d;
        text-align: center;
        line-height: 28px;
        font-weight: 400;
      }

      .btn {
        cursor: pointer;
        margin-top: 8px;
        padding: 16px;
        background: rgba(255, 64, 64, 0.39);
        color: #fff;
        border-radius: 10px;
      }
    }
  }
}

.policeSituationvisibleBox {
  top: 200px;
  left: 657px;
  width: 600px;
  display: flex;
  flex-direction: column;

  .head {
    // background: rgba(50, 2, 2, 0.7);
    // box-shadow: inset 0 0 56px 0 rgba(255, 64, 64, 0.39);
    // border-radius: 10px 10px 0 0;
    display: flex;
    justify-content: space-between;
    font-family: MicrosoftYaHei-Bold;
    font-size: 16px;
    color: #fff;
    text-align: center;
    font-weight: 700;
  }

  .pup_head {
    width: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 16px 0 12px;
    align-items: center;
    height: 32px;
    background: linear-gradient(
      180deg,
      #9198ff 0%,
      rgba(45, 81, 153, 0.45) 40%,
      #05091a 100%
    );
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff;

    .pup_img {
      display: flex;
      width: 19px;
      height: 25px;
      margin: 0 4px 0 0;
    }

    .fontOne {
      font-size: 20px;
      font-family: YouSheBiaoTiHei;
      color: #14faff;
      line-height: 26px;
      text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
      background: linear-gradient(
        135deg,
        #e3aa77 0%,
        #f5cda9 38%,
        #f9ecd3 58%,
        #fcdbb1 79%,
        #edb07a 100%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .fontTwo {
      font-size: 14px;
      font-family: MicrosoftYaHei;
      color: #d2dfff;
      cursor: pointer;
      line-height: 20px;
    }
  }

  .line {
    width: 457px;
    height: 2px;
    align-self: center;
    // background: #FD4D4D;
    // background-image: linear-gradient(
    //   244deg,
    //   rgba(255, 255, 255, 0) 0%,
    //   rgba(237, 89, 68, 1) 50%,
    //   rgba(255, 255, 255, 0) 100%
    // );
  }

  .content {
    padding-top: 16px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    text-align: left;
    height: calc(100% - 35px);
    font-family: MicrosoftYaHei;
    font-size: 16px;
    color: #fff;
    line-height: 28px;
    font-weight: 400;
    disabled: flex;
  }

  .inputBox {
    width: 400px;
    // min-height: 120px;
    background-color: #02173d;
    border: 1px solid #2b56b7;
    font-size: 14px;
    color: #00aacb;
    border-radius: 5px;
    text-indent: 1rem;
    vertical-align: top;
  }

  .flex {
    min-width: 175px;
    align-self: center;
    display: flex;
    justify-content: space-between;
    line-height: 32px;

    .doneN {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: #fff;
      border: 1px solid #dcdfe6;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #606266;
    }

    .doneY {
      margin-left: 50px;
      color: #fff;
      min-width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      box-shadow: inset 0 0 5px #00ffff;
      background: rgba(4, 227, 227, 0.1);
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
    }

    .doneNo {
      width: 56px;
      height: 32px;
      white-space: nowrap;
      cursor: pointer;
      background: rgba(185, 7, 7, 0.4);
      border: 1px solid #e9092e;
      -webkit-appearance: none;
      text-align: center;
      box-sizing: border-box;
      outline: 0;
      margin: 0;
      transition: 0.1s;
      font-weight: 500;
      color: #ffffff;
    }
  }

  /deep/ .el-dialog {
    width: 533px;
    // height: 477px;
    margin-top: 0 !important;
    overflow: hidden;
    border-radius: 10px;

    background: transparent;
    background: rgba(0, 39, 121, 0.5);
    box-shadow: 0px 2px 8px 0px rgba(1, 162, 255, 70%),
      inset 0px 0px 64px 0px rgba(26, 138, 227, 35%),
      inset 0px 0px 8px 0px #019aff;
    border: 1px solid #43deff;

    .el-dialog__header {
      padding-bottom: 0;
      padding-top: 0px;

      .el-dialog__title {
        color: #fff;
      }
    }

    .el-dialog__body {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .content {
        padding-top: 16px;
        border-radius: 0 0 10px 10px;
        width: 100%;
        height: calc(100% - 35px);
        // padding: 30px;
        // background: url('../../assets/images/warn/yj.png') no-repeat 100%;
        font-family: MicrosoftYaHei;
        font-size: 16px;
        color: #00aacb;
        text-align: center;
        line-height: 28px;
        font-weight: 400;
      }

      .btn {
        cursor: pointer;
        margin-top: 8px;
        padding: 16px;
        background: rgba(255, 64, 64, 0.39);
        color: #fff;
        border-radius: 10px;
      }
    }
  }
}

/deep/.el-textarea__inner {
  background: #02173d;
  border: 1px solid #2b56b7;
  margin-left: 3px;
  color: #009dbe;
}

/deep/.el-textarea.is-disabled .el-textarea__inner {
  width: 400px;
  background: #02173d;
  border-color: #1251cd;
  color: #3a8ee6;
}
</style>
