<template>
  <div class="cesium-toolbar">
    <div v-show="isShow" class="jcsb">
      <!-- <div class="jcsb"> -->

      <div class="toolbar_box " @click="handleClick" >
        <el-tooltip
          content="隐藏禁飞区"
          placement="top"
          effect="light"
          v-if="notFlyIsShow"
        >
          <img src="~@/assets/basic-tools/icon_hide_not_fly.svg" />
        </el-tooltip>
        <el-tooltip content="显示禁飞区" placement="top" effect="light" v-else>
          <img src="~@/assets/basic-tools/icon_show_not_fly.svg" />
        </el-tooltip>
      </div>

      <div class="toolbar_box" @click="() => $emit('toolbar-click', 1)">
        <el-tooltip content="飞入原点" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_set_origin.svg" />
        </el-tooltip>
      </div>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 2)">
        <el-tooltip content="设置原点" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_get_origin.svg" />
        </el-tooltip>
      </div>
      <el-popover
        placement="bottom"
        width="120"
        trigger="hover"
        popper-class="el-popover-box"
      >
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 3)">
            <img src="~@/assets/basic-tools/icon_horizontal.svg" />
            <span>水平测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 4)">
            <img src="~@/assets/basic-tools/icon_vertical.svg" />
            <span>垂直测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 5)">
            <img src="~@/assets/basic-tools/icon_area.svg" />
            <span>面积测量</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 6)">
            <img src="~@/assets/basic-tools/icon_volume.svg" />
            <span>体积测量</span>
          </div>
        </div>
        <div slot="reference" class="toolbar_box">
          <el-tooltip content="测量工具" placement="top" effect="light">
            <img src="~@/assets/basic-tools/icon_measure.svg" />
          </el-tooltip>
        </div>
      </el-popover>
      <el-popover
        placement="bottom"
        width="120"
        trigger="hover"
        popper-class="el-popover-box"
      >
        <div class="hover_box">
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 7)">
            <img src="~@/assets/basic-tools/icon_topsight.svg" />
            <span>顶视</span>
          </div>
          <div class="toolbar_box" @click="() => $emit('toolbar-click', 8)">
            <img src="~@/assets/basic-tools/icon_overlook.svg" />
            <span>俯视</span>
          </div>
        </div>
        <div slot="reference" class="toolbar_box">
          <el-tooltip content="视图" placement="top" effect="light">
            <img src="~@/assets/basic-tools/icon_view.svg" />
          </el-tooltip>
        </div>
      </el-popover>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 9)">
        <el-tooltip content="指北" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_north.svg" />
        </el-tooltip>
      </div>
      <div class="toolbar_box" @click="() => $emit('toolbar-click', 10)">
        <el-tooltip content="人视角" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_perspective.svg" />
        </el-tooltip>
      </div>
      <div class="toolbar_box" @click="handleFullscreen">
        <el-tooltip
          v-if="!fullscreen"
          content="全屏"
          placement="top"
          effect="light"
        >
          <img src="~@/assets/basic-tools/icon_fullscreen.svg" />
        </el-tooltip>
        <el-tooltip v-else content="退出全屏" placement="top" effect="light">
          <img src="~@/assets/basic-tools/icon_smallscreen.svg" />
        </el-tooltip>
      </div>
    </div>
    <div class="toolbar_box" @click="isShow = !isShow" :class="{color:routeColor == true}" @mousemove="rulershow = true" @mouseleave="rulershow = false">
      <el-tooltip content="工具栏" placement="top" effect="light">
        <!-- <img src="~@/assets/basic-tools/icon_toolkit.svg" /> -->
        <img v-if="!rulershow" src="~@/assets/basic-tools/rulerN.png" />
        <img v-if="rulershow" src="~@/assets/basic-tools/ruler.png" />
        <!-- <img src="~@/assets/basic-tools/icon_toolkit.svg" /> -->
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { DOM } from "@/utils/dom.js";
export default {
  data() {
    return {
      rulershow: false,
      fullscreen: false,
      isShow: false,
      collapse: false,
      command: false,
      notFlyIsShow: true,
      routeColor:false,
    };
  },
  watch: {
    "$store.state.device.flag"(newData) {
      this.collapse = newData;
    },
     "$route.path": {
      handler(value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
  },
  created() {
    console.log(this.$route.path);
    if (this.$route.path == "/command") {
      this.command = true;
    } else {
      this.command = false;
    }
  },
  mounted() {
    // 控制重要元素下拉菜单隐藏
    document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".cesium-toolbar"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.isShow = false
      }
    });
  },
  methods: {
    handleClick() {
      this.notFlyIsShow = !this.notFlyIsShow;
      this.$emit("toolbar-click", 11, this.notFlyIsShow);
    },
    handleFullscreen() {
      this.fullscreen = !this.fullscreen;
      if (this.fullscreen) {
        DOM.fullscreen();
      } else {
        DOM.exit_fullscreen();
      }
    },
    moveToolbar(e) {
      if (e == "center") {
        this.collapse = false;
      } else if (e == "left") {
        this.collapse = true;
      } else {
        this.collapse = !this.collapse;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cesium-toolbar {
  // position: absolute;
  // left: 550px;
  // top: 102px;

  position: absolute;
  right: 0;
  top: 130px;
  display: flex;
  transform: translateX(0);
  transition: 0.3s;
  .toolbar_box {
    width: 50px;
    height: 50px;
    // border: 1px solid #04d5da;
    background: rgba(22, 29, 45, 0.7);
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      border: 1px solid;
    }
    & > img {
      width: 20px;
      height: 20px;
      margin: 15px auto;
    }
  }
  .hover_box {
    display: flex;
    background: red;
    .toolbar_box {
      img {
        width: 20px;
        height: 20px;
        margin: 15px auto;
      }
    }
  }
}
.color{
  // border: 1px solid #fff !important;
  &:hover{
  border: 1px solid #43deff !important;
  }
}
</style>
