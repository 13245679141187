
<template>
  <div class="cpt-hwkeys cp">
    <div class="change">
      <div
        class="typeNmae"
        :class="{ active: !is_3d ,color:routeColor == true}"
        v-show="!is_3d"
        @click="handle_change_3d(true)"
      >
        2D
      </div>
      <div class="typeNmae" :class="{ active: is_3d,color:routeColor == true }" v-show="is_3d" @click="handle_change_3d(false)">
        3D
      </div>
    </div>

    <div class="hwkeys-wrap" :class="{ active,color:routeColor == true }" @click="change">
      <div :class="{
          'hwkeys-item': 1,
          [item.class_name]: 1,
        }" v-for="item in tabs" :key="item.id" v-show="active || item.id == current" @click="handle_change(item.id)">
        <div class="typeNmae" :class="{color:routeColor == true }">{{ item.name }}</div>
      </div>
    </div>

    <div v-show="waterFlag" @click="openwater" class="iconfont icon-shuizhijiance waterbox"></div>
    <div class="tubiao">
      <div class="kuang" v-for="(item, index) in icon" :key="index" :title="item.title" @click="pop_up(item.title)">
        <i class="iconfont" :class="item.icon"></i>
      </div>
    </div>
    <div class="wenjuan" v-if="isSurveyflag && isSurveyShow && isSumbit">
      <el-tooltip effect="light" content="调查问卷" placement="top-start">
      <img src="@/assets/wenjuan.png" alt="" @click="SurveyShow = true">
      </el-tooltip>
      </div>
    <Questionnaire v-if="SurveyShow" @handleClose="SurveyShow = false" @close="isSumbit = false"></Questionnaire>
  </div>
</template>

<script>
  import { global_leyers, base_layer } from "@/utils/global";
  import Water from "@/components/accident/water";
  import EventBus from "@/assets/ligature.js";
  //问卷调查表
import Questionnaire from "@/pages/home/questionnaire";
  export default {
    components: { Water,Questionnaire },
    data() {
      return {
        waterFlag: false,
        showwater: false,
        active: false,
        flag: false,
        is_3d: true,
        command: false,
         routeColor: false,
        current: 1,
        tabs: [
          {
            id: 1,
            name: "卫星",
            class_name: "hwkeys-item-satellite",
          },
          {
            id: 2,
            name: "街道",
            class_name: "hwkeys-item-street",
          },
          {
            id: 3,
            name: "夜景",
            class_name: "hwkeys-item-night-blue",
          },
        ],
        icon: [],
        isSurveyShow: false,
        isSurveyflag: false,
        SurveyShow: false,
        isSumbit: true
      };
    },
    mounted(){
      setTimeout(() => {
        base_layer.BASIC.show = true
      }, 2000);

      document.addEventListener("click", (e) => {
      const iconEl = document.querySelector(".hwkeys-wrap"); // 这里是要隐藏的弹窗 类名
      if (iconEl && !iconEl.contains(e.target)) {
        // contains 方法 就是查看包含关系
        // contains 方法 本身含义：用于判断字符串中是否包含指定的字符或字符串
        // 返回值 :如果包含指定的字符或字符串返回 true，否则返回 false。
        this.active = false;
        
      }
    });
    },
    created() {
      if (JSON.parse(localStorage.getItem("user_info")).data.isSurvey || localStorage.getItem("isSurvey")) {
      this.isSurveyShow = false
    } else {
      this.isSurveyShow = true
    }
    if (this.$route.path == "/home") {
        this.isSurveyflag = true;
      } else {
        this.isSurveyflag = false;
      }
      if (this.$route.path == "/command") {
        this.command = true;
      } else {
        this.command = false;
      }
      console.log('handle_change1')
      // this.handle_change(3);

      setTimeout(() => {
        this.handle_change(1);
        this.handle_change_3d(false);
      }, 1000);
      EventBus.$on("icons", (icon, title) => {
        let a = { icon, title };
        this.icon.push(a);
      });
      EventBus.$on("deletes", (icons) => {
        let a = this.icon;
        a = JSON.parse(JSON.stringify(a));
        let index = a.findIndex(function (item) {
          return item.icon === icons;
        });
        a.splice(index, 1);
        this.icon = a;
      });
    },
    watch: {
      "$store.state.device.mapFlag"(newData) {
        this.flag = newData;
      },
      "$store.state.device.waterFlag"(newData) {
        this.waterFlag = newData;
      },
          "$route.path": {
      handler(value) {
        if (value == "/home1027") {
          this.routeColor = true;
        }
      },
      immediate: true,
    },
    },
    methods: {

      pop_up(item) {
        EventBus.$emit('CloseMenu')
        EventBus.$emit('icon_message', item)
      },
      guanbiwater() {
        this.showwater = false;
      },
      openwater() {
        // this.showwater = true
        // "$store.state.device.mapFlag"(newData){
        //   this.flag = newData
        // }
        this.$store.commit("device/SET_WATERBOX", true);
      },
      handle_change_3d(bool) {
        this.is_3d = bool;
        this.$store.commit("device/SET_3D_STATUS", bool);
        try {
          global_leyers.forEach((promise) => {
            promise.then((layer) => {
              layer.visible = bool;
            });
          });
        } catch (error) { }
      },
      handle_change(id) {
        this.current = id;
        if (id == 1) {
          base_layer.BASIC && (base_layer.BASIC.show = false);
          base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
          base_layer.NIGHT && (base_layer.NIGHT.show = false);
        } else if (id == 2) {
          base_layer.BASIC && (base_layer.BASIC.show = true);
          base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
          base_layer.NIGHT && (base_layer.NIGHT.show = false);
        } else {
          base_layer.BASIC && (base_layer.BASIC.show = false);
          base_layer.SATELLITE && (base_layer.SATELLITE.show = false);
          base_layer.NIGHT && (base_layer.NIGHT.show = true);
        }
        console.log('base_layer', base_layer, id)

      },
      change() {
        this.active = !this.active;
        // global_leyers.forEach(promise => {
        //   promise.then((layer) =>{
        //     layer.visible = this.active;
        //   });
        // })
        // base_layer.BASIC && (base_layer.BASIC.show = !this.active)
        // base_layer.SATELLITE && (base_layer.SATELLITE.show = this.active)
      },
    },
  };
  
</script>

<style lang="scss" scoped>
  .cpt-hwkeys {

    position: absolute;
    right: 0;
    top: 185px;

    width: 104px;
    height: 78px;
    transition: 0.2s;
    transform: translateX(0);

    &.wkeysRight {
      transition: 0.3s;
      transform: translateX(-12px);
      right: 40px;
    }

    .wenjuan{
      position: absolute;
      right: 0;
      top: 110px;
      width: 50px;
      height: 50px;
    }

    .change {
      position: absolute;
      top: 0;
      right: 0;
      display: flex;

      .typeNmae {
        // padding: 0 5px;
        height: 50px;
        width: 50px;
        // border: 1px solid #FFF;
        // box-shadow: inset 0 0 7px 0 #008fff;
        border-radius: 1px;
        font-size: 24px;
        font-weight: 700;
        line-height: 50px;
        text-align: center;
        color: #fff;
        box-sizing: border-box;
        background: rgba(22, 29, 45, 0.58);

        // background: rgba(0,23,79,0.70);
        &:hover {
          background: rgba(22, 29, 45, 0.7);
          border: 1px solid #43ddfe;
          color: #43ddfe;
        }

        &:active {
          background: rgba(22, 29, 45, 0.8);
        }

        // &.active {
        //   box-shadow: inset 0 0 7px 0 #43ddfe;
        // }
      }
    }

    .hwkeys-wrap {
      position: absolute;
      top: 55px;
      right: 0;
      width: 50px;
      height: 50px;
      // background: rgba(22, 29, 45, 0.58);
      border-radius: 2px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: width 0.25s;

      &.active {
        width: 150px;
        // border: 1px solid #43deff;
      }
      

      .hwkeys-item {
        width: 50px;
        height: 50px;
        // border: 1px solid #43ddfe; 
        position: relative;
        &:hover{
      border: 1px solid #43ddfe;
      }
      &.hwkeys-item-satellite {
        background: url(../../assets/images/3d.png) center no-repeat;
      }
      &.hwkeys-item-street {
        background: url(../../assets/images/2d.png) center no-repeat;
      }
      &.hwkeys-item-night-blue {
        background: url(../../assets/images/night-blue.png) center no-repeat;
        // background-size: 115% 115%;
      }
      &.hwkeys-item-3d {
        background: url(../../assets/images/3d.png) center no-repeat;
      }
      &.hwkeys-item-2d {
        background: url(../../assets/images/2d.png) center no-repeat;
      }
      &.hwkeys-item-street {
      }
      .typeNmae {
        position: absolute;
        bottom: 0;
        right: 0;
        // width: 20px;
        z-index: 1;
        height: 20px;
        // border: 1px solid #43ddfe;
        // box-shadow: inset 0 0 7px 0 #333;
        background: rgba($color: #000000, $alpha: 0.5);
        border-radius: 1px;
        font-size: 12px;
        line-height: 20px;
        text-align: center;
        color: #fff;
      }
    }
  }
  .waterbox {
    height: 48px;
    width: 48px;
    position: absolute;
    top: 130px;
    right: 0px;
    border: 1px solid #04d5da;
    background: rgba(22, 29, 45, 0.7);
    color: #43deff;
    font-size: 20px;
    line-height: 48px;
  }
  .water_quality {
    width: 459px;
    height: 418px;
    background: rgba(0, 23, 79, 0.7);
    box-shadow: 0 2px 4px 0 rgba(1, 162, 255, 0.35),
      inset 0 0 40px 0 rgba(0, 184, 255, 0.5);
    border-radius: 13px;
  }
}
.tubiao {
  position: absolute;
  top: 110px;
  right: 0;
  display: flex;
  border: 1px solid #43deff;
  border-right: 0.5px solid #43deff;
  border-left: 0.5px solid #43deff;
  // transform: rotate(-90deg);
  background: rgba(0, 23, 79, 0.7);
}
.kuang {
  height: 50px;
  width: 50px;
  border-right: 0.5px solid #43deff;
  border-left: 0.5px solid #43deff;
  font-family: MicrosoftYaHeiUI;
  color: #43deff;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  line-height: 50px;
  .iconfont {
    font-size: 30px;
  }
}
.color{
  // border: 1px solid #1772c1 !important;
  // color:#1772c1 !important ;
}
</style>