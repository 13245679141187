<!-- 总览页cesium -->
<template>
  <div class="cpt-home-cesium_layer">
    <div
      id="layer-container"
      class="bk1027"
      :class="{
        //isFullscreenBool 为true时代表全屏
        ycFlag_01: ycFlag && ycnum == 0 && !isFullscreenBool,
        ycFlag_01_full: ycFlag && ycnum == 0 && isFullscreenBool,
        ycFlag_02: ycFlag && ycnum == 1,
        ycFlag_02_full: ycFlag && ycnum == 1 && isFullscreenBool,
        ycFlag_03: ycFlag && ycnum == 2,
        ycFlag_03_full: ycFlag && ycnum == 2 && isFullscreenBool,
        'ycFlag--collapse': ycMapCollapse,
        wrjFlag,
        wrjFlag1,
        fkVideo,
        enable,
        yc1027: yPath == true,
        uav1027: uavpath == true,
      }"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { Utils } from "@/lib/cesium";
import GeoJson from "@/data/geo/geo_yc.json";
import GeoJsonBorder from "@/data/geo/geo_yc_border.json";
import API from "@/api";
import { primitives_3d_tiles, global_leyers, base_layer } from "@/utils/global";
import { BaiduImageryProvider } from "@/lib/cesium";
// 引用bus
import Bus from "@/assets/ligature.js";
let viewer = null;
// let plate_data_source = null;
let border_data_source = null;
let center = null;
let LAYERS = null;
let envType = process.env.VUE_APP_ENV_TYPE;
let superMapURL = ""; //超图服务URL

console.log("envType", envType);

switch (envType) {
  case "公网":
    superMapURL = "http://120.26.3.230:8090";
    LAYERS = Object.freeze({
      BASIC: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "https://webrd01.is.autonavi.com/appmaptile?x={x}&y={y}&z={z}&lang=zh_cn&size=1&scale=1&style=8",
        }),
      },
      SATELLITE: {
        image: new Cesium.WebMapTileServiceImageryProvider({
          // url: "https://{s}.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0",
          url: "https://t0.tianditu.gov.cn/img_w/wmts?tk=a5e0a8dcf07f40afe19b76bf8a32eef0",
          layer: "img",
          style: "default",
          tileMatrixSetID: "w",
          format: "tiles",
          maximumLevel: 18,
          // maximumLevel: 18,
        }),
      },
      NIGHT: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "https://map.geoq.cn/ArcGIS/rest/services/ChinaOnlineStreetPurplishBlue/MapServer/tile/{z}/{y}/{x}",
        }),
      },
      //影像地名标记
      TEXT: {
        image: new Cesium.WebMapTileServiceImageryProvider({
          url: "https://{s}.tianditu.gov.cn/cia_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=cia&STYLE={style}&TILEMATRIXSET={TileMatrixSet}&FORMAT=tiles&TILEMATRIX={TileMatrix}&TILEROW={TileRow}&TILECOL={TileCol}&tk=a5e0a8dcf07f40afe19b76bf8a32eef0",
          layer: "cia",
          style: "default",
          format: "tiles",
          tileMatrixSetID: "c",
          credit: new Cesium.Credit("天地图全球影像服务"),
          tilingScheme: new Cesium.GeographicTilingScheme(), //采用经纬度投影
          subdomains: ["t0", "t1", "t2", "t3", "t4", "t5", "t6", "t7"],
          tileMatrixLabels: [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
          ],
          maximumLevel: 18,
        }),
      },
    });
    break;

  //默认为内网配置
  default:
    superMapURL = "http://32.128.6.49:8090";
    LAYERS = Object.freeze({
      BASIC: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58088/minemap-data/data/raster/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
      SATELLITE: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58090/minemap-data/data/satellite/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
      NIGHT: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58089/minemap-data/data/raster/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
      //影像地名标记
      TEXT: {
        image: new Cesium.UrlTemplateImageryProvider({
          url: "http://32.128.12.23:58088/minemap-data/data/text/{sz}/{x}/{y}",
          tilingScheme: new Cesium.GeographicTilingScheme(),
          customTags: {
            sz: function (imageryProvider, x, y, level) {
              return level + 1;
            },
          },
        }),
      },
    });
}

const MODES = Object.freeze({
  PLATE: Symbol("PLATE"),
  GLOBE: Symbol("GLOBE"),
});

const origin = new Cesium.Cartesian3(
  -2718455.981755302,
  4687924.665227746,
  3547699.7600637083
);

const plate_orign = new Cesium.Cartesian3(
  -2988857.0261368295,
  5044169.936281486,
  3519001.2112579183
);

const plate_hpr = {
  heading: 0.09277125920045037,
  pitch: -1.2623381316756577,
  roll: 2.016165012719284,
};

const tinghu2_origin = new Cesium.Cartesian3(
  -2694782.3468037946,
  4621493.91410246,
  3479515.1219724463
);

export default {
  data() {
    return {
      MODES,
      ycnum: null, //  num为0 切换无人机视频 num为1 仓内视频切换  num为2 仓外视频  作用：地图位置
      ycFlag: false,
      wrjFlag: false,
      wrjFlag1: false,
      fkVideo: false,
      enable: false,
      initCount: 0, //初始化次数计数器
      yPath: false, // 判断当前页面是旧版还是1027版
      uavpath: false, //飞控鹰巢新版视频背景模式判断小地图在哪显示
      isFullscreenBool: false,
    };
  },
  inject: ["g_show_zysb"],
  computed: {
    ...mapGetters(["ycMapCollapse"]),
  },
  watch: {
    "$store.state.mapmanage.mapisStatus": function (newVal) {
      // 鹰巢模块切换视频
      if (newVal.type == "yc") {
        this.wrjFlag = false;
        this.ycFlag = newVal.flag;
        this.ycnum = newVal.num;
      } else if (newVal.type == "wrj") {
        this.ycFlag = false;
        this.ycnum = null;
        if (this.$store.state.mapmanage.videoItem == 0) {
          this.wrjFlag = newVal.flag;
          this.wrjFlag1 = false;
        }
        if (this.$store.state.mapmanage.videoItem == 1) {
          this.wrjFlag1 = newVal.flag;
          this.wrjFlag = false;
        }
      } else if (newVal.type == "fkVideo") {
        this.fkVideo = newVal.flag;
      } else if (newVal.type == "enable") {
        this.enable = newVal.flag;
      }
    },
  },
  mounted() {
    window.cthis = this;
    this.watchPageChange();
    if (window.screen.height - window.document.body.offsetHeight > 5) {
      this.isFullscreenBool = false;
    } else {
      this.isFullscreenBool = true;
    }
    window.addEventListener("resize", this.fullScreenChangeFn);
  },
  created() {
    // 搜索关键字：飞控 鹰巢 左下角视频切换为全局时状态
    Bus.$on("videos_flag", (status, num) => {
      if (this.$route.path == "/fckernel") {
        if (status == true) {
          this.yPath = true;
        } else {
          this.yPath = false;
        }
      }
    });
    Bus.$on("uav_flag", (status) => {
      if (this.$route.path == "/fckernel") {
        if (status == true) {
          this.uavpath = true;
        } else {
          this.uavpath = false;
        }
      }
    });
  },
  beforeDestroy() {
    try {
      window.viewer.destroy();
    } catch (e) {
      console.log("cesium-layer destroy fail", e);
    }
    window.removeEventListener("resize", this.fullScreenChangeFn);
    window.cthis = null;
  },
  methods: {
    viewer() {
      return viewer;
    },
    //监听屏幕是否全屏
    fullScreenChangeFn(e) {
      if (window.screen.height - window.document.body.offsetHeight > 5) {
        this.isFullscreenBool = false;
      } else {
        this.isFullscreenBool = true;
      }
    },
    watchPageChange() {
      let services = window.___s3m;
      if (document.hidden !== undefined) {
        document.addEventListener("visibilitychange", () => {
          let status = this.$store.state.device.is3d;
          if (document.hidden) {
            if (status) {
              services.forEach((item) => {
                viewer.scene.layers.remove(item);
              });
            }

            return;
          }
          if (status) {
            let ip = "120.26.3.230";
            services.forEach((item) => {
              let url = `${superMapURL}/iserver/services/${item}/rest/realspace/datas/Config/config`;
              let promise = viewer.scene.addS3MTilesLayerByScp(url, {
                name: item,
              });
              global_leyers.push(promise);
            });
          }
        });
      }
    },
    async init() {
      await this.init_viewer();
      await this.init_plate();
      // await this.init_border();
      // await this.init_wrap_border();
      this.$emit("initDone");
    },
    async init_viewer() {
      // 解决航线被三维模型遮盖
      var oldPolylineUpdate = Cesium.PolylineCollection.prototype.update;
      Cesium.PolylineCollection.prototype.update = function (frameState) {
        var oldMorphTime = frameState.morphTime;
        frameState.morphTime = 0.0;
        oldPolylineUpdate.call(this, frameState);
        frameState.morphTime = oldMorphTime;
      };
      window.viewer = viewer = new Cesium.Viewer("layer-container", {
        animation: false, //是否显示动画控件
        shouldAnimate: true,
        homeButton: false, //是否显示Home按钮
        fullscreenButton: false, //是否显示全屏按钮
        baseLayerPicker: false, //是否显示图层选择控件
        geocoder: true, //是否显示地名查找控件
        timeline: false, //是否显示时间线控件
        sceneModePicker: true, //是否显示投影方式控件
        navigationHelpButton: false, //是否显示帮助信息控件
        infoBox: false, //是否显示点击要素之后显示的信息
        requestRenderMode: true, //启用请求渲染模式
        maximumRenderTimeChange: Infinity,
        scene3DOnly: false, //每个几何实例将只能以3D渲染以节省GPU内存
        sceneMode: 3, //初始场景模式 1 2D模式 2 2D循环模式 3 3D模式  Cesium.SceneMode
        fullscreenElement: document.body, //全屏时渲染的HTML元素 暂时没发现用处
        selectionIndicator: false,

        // imageryProvider: false,
        // imageryProvider: new Cesium.ArcGisMapServerImageryProvider({
        //   url: "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer",
        // }),
        /*  imageryProvider: new Cesium.UrlTemplateImageryProvider({
          url: "",
        }), */
        // imageryProvider: new Cesium.TileMapServiceImageryProvider({
        //   url: Cesium.buildModuleUrl("Assets/Textures/NaturalEarthII"),
        // }),
        skyBox: false, //不显示天空盒
        orderIndependentTranslucency: true, //如果为true，并且配置支持它，则使用顺序无关的半透明性。
        contextOptions: {
          //传递给 Scene 的与 options 相对应的上下文和WebGL创建属性
          webgl: {
            alpha: true,
            preserveDrawingBuffer: true, //通过canvas.toDataURL()实现截图需要将该项设置为true
          },
        },
      });
      if (this.$route.path == "/home") {
        viewer.scene.globe.show = false;
      }

      viewer.scene.backgroundColor = new Cesium.Color(0, 0, 0, 0); //将背景色设置为透明, 显示出背景图
      let services = window.___s3m;

      Object.keys(LAYERS).forEach((item) => {
        base_layer[item] = viewer.imageryLayers.addImageryProvider(
          LAYERS[item].image
        );
      });
      // base_layer.BASIC && (base_layer.BASIC.show = true);
      // base_layer.SATELLITE && (base_layer.SATELLITE.show = true);
      // base_layer.NIGHT && (base_layer.NIGHT.show = false);
      window.base_layer = base_layer;

      services.forEach((item) => {
        let url = `${superMapURL}/iserver/services/${item}/rest/realspace/datas/Config/config`;
        // console.log('s3m url', url)
        let promise = viewer.scene.addS3MTilesLayerByScp(url, {
          name: item,
          // cullEnabled: false
        });
        /* promise.then((layer) => {
            layer.visible = false;
          }); */
        global_leyers.push(promise);
      });
      this.g_data.viewer = true;
      this.init_events();
      //调整光线
      let utc = Cesium.JulianDate.fromDate(new Date("2021/07/04 04:00:00"));
      //北京时间=UTC+8=GMT+8
      viewer.clockViewModel.currentTime = Cesium.JulianDate.addHours(
        utc,
        8,
        new Cesium.JulianDate()
      );
      // this.init_tiles(); // 加载三维数据

      //错误事件捕捉(能收到事件, 但是在recover.js中也能捕获, 统一由recover.js中处理)
      /* window.$renderError = function(){};
        window.$renderError.prototype.myListener = function(...args) {
          console.info('收到来自Cesium.Viewer.Scene的renderError事件', args);
          alert('收到来自Cesium.Viewer.Scene的renderError事件')
        }

        var myObjectInstance = new window.$renderError;
        window.viewer.scene.renderError.addEventListener(window.$renderError.prototype.myListener, myObjectInstance); */
    },

    init_events() {
      let handler = new Cesium.ScreenSpaceEventHandler(viewer.canvas);
      handler.setInputAction((movement) => {
        let pos = this.getcameraPosInfo();
        console.log(pos, "pos");
        // 点击事件获得实体
        this.on_plate_left_click(movement);
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);
      handler.setInputAction((movement) => {
        //获取当前相机高度
        // if (this.is_plate_mode()) {
        let height = Math.ceil(viewer.camera.positionCartographic.height);
        this.on_plate_wheel(height);
        // }
      }, Cesium.ScreenSpaceEventType.WHEEL);
    },

    async init_tiles() {
      let res = await API.MAPMANAGE.GetLayerList();
      let regexp = /^.*(\.json)$/;
      res[1].children.forEach((item) => {
        if (regexp.test(item.url)) {
          let primitive = viewer.scene.primitives.add(
            new Cesium.Cesium3DTileset({
              url: item.url,
              maximumScreenSpaceError: 2, // The maximum screen space error used to drive level of detail refinement. 值越小细节越好，默认16
              maximumMemoryUsage: 10240, // The maximum amount of memory in MB that can be used by the tileset. 显存调整到1G，目前低配的计算机显存差不多这个参数
              // skipLevelOfDetail: true
              dynamicScreenSpaceError: true,
              show: false,
            })
          );
          primitives_3d_tiles[item.id] = primitive;
        }
      });

      // 调整模型位置
      // tileset.then((model) => {
      //   let cartographic = Cesium.Cartographic.fromCartesian(
      //       model.boundingSphere.center
      //     ),
      //     surface = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     offset = Cesium.Cartesian3.fromRadians(
      //       cartographic.longitude,
      //       cartographic.latitude,
      //       0
      //     ),
      //     translation = Cesium.Cartesian3.subtract(
      //       offset,
      //       surface,
      //       new Cesium.Cartesian3()
      //     );
      //   model.modelMatrix = Cesium.Matrix4.fromTranslation(translation);
      // });
    },
    getcameraPosInfo() {
      // 获取 相机姿态信息
      var head = viewer.scene.camera.heading;
      var pitch = viewer.scene.camera.pitch;
      var roll = viewer.scene.camera.roll;
      var info = { heading: head, pitch: pitch, roll: roll };
      // 获取位置 wgs84的地心坐标系，x,y坐标值以弧度来表示
      var position = viewer.scene.camera.positionCartographic; //with longitude and latitude expressed in radians and height in meters.
      //以下方式也可以获取相机位置只是返回的坐标系不一样

      // 弧度转经纬度
      var longitude = Cesium.Math.toDegrees(position.longitude).toFixed(6);
      var latitude = Cesium.Math.toDegrees(position.latitude).toFixed(6);
      var height = position.height;
      return { lng: longitude, lat: latitude, h: height, mat: info };
    },
    async init_border() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJsonBorder);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];

        entity._name = "border" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.fill = false;
        entity.polygon.outlineColor =
          Cesium.Color.fromCssColorString("#ffd33c");

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString("#36b558"),
          // material: Cesium.Color.fromCssColorString('#ffb319'),
        };
      }
      await viewer.dataSources.add(dataSource);
      dataSource.show = true;
      border_data_source = dataSource;
    },

    async init_wrap_border() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i];

        entity._name = "border" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.fill = false;
        entity.polygon.outlineColor = Cesium.Color.fromCssColorString("red");

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          material: Cesium.Color.fromCssColorString("#1645fc"), //地图底部边线颜色
        };
      }
      await viewer.dataSources.add(dataSource);
      dataSource.show = true;
      border_data_source = dataSource;
    },

    async init_plate() {
      let promise = Cesium.GeoJsonDataSource.load(GeoJson);
      let dataSource = await promise;
      let entities = dataSource.entities.values;
      let center_hash = {};
      // console.log('entities', entities)
      for (let i = 0; i < entities.length; i++) {
        let entity = entities[i],
          name = entity.name,
          centerHash = {};
        let areaName = center_hash[name] ? `${name}1` : name; //出现地区重名则在名字后+'1'

        entity._name = "plate" + "_" + entity._name;
        entity.polygon.outline = true;
        entity.polygon.outlineColor =
          Cesium.Color.fromCssColorString("#218CDB"); // 边线填充色
        entity.polygon.material = Cesium.Color.fromCssColorString(
          "#2A9AFF" //填充色
        ).withAlpha(1);
        entity.polygon.extrudedHeight = Math.floor(10000);
        entity.polygon.material = `/static/img/cesium/${areaName}.png`;

        let positions = entity.polygon.hierarchy._value.positions;

        entity.polyline = {
          positions: positions.map((item) => {
            let c = Cesium.Cartesian3.clone(item);
            let wgs84 = Utils.transformCartesian2WGS84(c);
            wgs84.alt += 10020;
            return Utils.transformWGS842Cartesian(wgs84);
          }),
          width: 4,
          //边框
          material: Cesium.Color.fromCssColorString("#B3D9FF"), // 边框线的颜色
        };

        let center = entity.properties.centroid._value;
        center = Utils.transformWGS842Cartesian({
          lng: center[0],
          lat: center[1],
          alt: 10000,
        });

        if (!center_hash[name]) {
          entity.position = center;
          entity.label = {
            text: name,
            show: true,
            showBackground: false,
            font: "16px monospace bolder",
            horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
            verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
            pixelOffset: new Cesium.Cartesian2(0, 20),
            disableDepthTestDistance: Number.POSITIVE_INFINITY,
            //字体
            fillColor: Cesium.Color.fromCssColorString("#fff"), // 字体颜色
            scaleByDistance: new Cesium.NearFarScalar(90000, 3, 660000, 0.4),
            // pixelOffsetScaleByDistance: new Cesium.NearFarScalar(90000, 5, 460000, 1)
          };
          entity.billboard = {
            show: true,
            image: `/static/img/cesium/poi_${name}.png`,
            pixelOffset: new Cesium.Cartesian2(0, -40),
            width: 43,
            height: 75,
            scale: 1,
            scaleByDistance: new Cesium.NearFarScalar(90000, 5, 660000, 0.4),
            pixelOffsetScaleByDistance: new Cesium.NearFarScalar(
              90000,
              5,
              660000,
              0.4
            ),
          };
          center_hash[name] = center;
        }
      }

      await viewer.dataSources.add(dataSource);
      dataSource.show = false;
      window.plate_data_source = dataSource;
    },
    get_plate_by_click(moment) {
      let pickedObject = viewer.scene.pick(moment.position);
      if (pickedObject?.id?._name) {
        if (
          pickedObject &&
          pickedObject.id &&
          pickedObject.id._name.indexOf("plate_") != -1
        ) {
          return pickedObject.id;
        } else {
          return null;
        }
      }
    },

    on_plate_left_click(movement) {
      let plate = this.get_plate_by_click(movement);
      if (plate) {
        base_layer.SATELLITE.show = false;
        setTimeout(() => {
          base_layer.SATELLITE.show = true;
        }, 500);
        let center = plate.properties.center._value;
        this.fly_to_globe({
          destination: Utils.transformWGS842Cartesian({
            lng: center[0],
            lat: center[1],
            alt: 10000,
          }),
        });
      }
    },
    on_plate_wheel(height) {
      // if (height >= 1.4e7 && !window.plate_data_source.show) {
      //   this.fly_to_plate();
      // }
    },
    is_plate_mode() {
      return window.plate_data_source && window.plate_data_source.show;
    },

    location(options = { destination: origin }, fly_type = "flyTo") {
      viewer.camera[fly_type](options);
    },

    fly_to_globe(options) {
      console.log(options, "options");
      viewer.trackedEntity && (viewer.trackedEntity = undefined);
      viewer.scene.globe.show = true;
      viewer.scene.skyAtmosphere.show = true;
      viewer.scene.globe.showGroundAtmosphere = true;
      window.plate_data_source && (window.plate_data_source.show = false);
      this.location(options);
    },

    // fly_to_plate() {
    //   viewer.scene.skyAtmosphere.show = false;
    //   viewer.scene.globe.showGroundAtmosphere = false;
    //   viewer.scene.globe.show = false;
    //   window.plate_data_source && (window.plate_data_source.show = true);
    //   this.location(
    //     {
    //       destination: plate_orign,
    //       orientation: plate_hpr,
    //     },
    //     "setView"
    //   );
    //   center && (viewer.trackedEntity = center);
    // },

    fly_to_plate(fly_type = "setView", options) {
      window.plate_data_source && (window.plate_data_source.show = true);

      options = {};
      options.destination = plate_orign;
      options.orientation = plate_hpr;
      options.duration = 5;
      options.complete = () => {
        viewer.scene.skyAtmosphere.show = false;
        viewer.scene.globe.showGroundAtmosphere = false;
        viewer.scene.globe.show = false;
      };
      fly_type === "setView" && options.complete && options.complete();

      this.location(options, fly_type);
      center && (viewer.trackedEntity = center);
    },

    // fly_to_by_mode(mode = MODES.PLATE) {
    //   if (mode === MODES.PLATE) {
    //     this.fly_to_plate();
    //   } else if (mode === MODES.GLOBE) {
    //     this.fly_to_globe();
    //   }
    // },

    fly_to_by_mode(mode = MODES.PLATE, ...options) {
      if (mode === MODES.PLATE) {
        this.fly_to_plate(...options);
      } else if (mode === MODES.GLOBE) {
        this.fly_to_globe();
      }
    },

    add_model(entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          // uri: "cesium/model/1800_20210316_no_effect283g2color.gltf",
          uri: "cesium/model/mmcModel2.gltf",
          minimumPixelSize: 100,
          maximumScale: 10000,
          scale: 1,
          ...(data.options ? data.options : {}),
          // show: new Cesium.CallbackProperty(
          //   () => !window.plate_data_source || !window.plate_data_source.show,
          //   false
          // ),
        },
      });
    },
    add_model_X85(entities, data) {
      return entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        orientation: new Cesium.CallbackProperty(() => data.orientation, false),
        position: new Cesium.CallbackProperty(() => data.position, false),
        model: {
          uri: "cesium/model/x85_1025g2.glb",
          minimumPixelSize: 60,
          maximumScale: 1000,
          scale: 1.5,
          ...(data.options ? data.options : {}),
          // show: new Cesium.CallbackProperty(
          //   () => !window.plate_data_source || !window.plate_data_source.show,
          //   false
          // ),
        },
      });
    },

    add_polyline(entities, data) {
      return viewer.entities.add({
        id: data.entity_id,
        parent: data.parent || null,
        polyline: {
          positions: new Cesium.CallbackProperty(() => {
            return data.positions;
          }, false),
          width: 5,
          show: true,
          material: Cesium.Color.fromCssColorString("#ff0000"),
          ...(data.options ? data.options : {}),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cpt-home-cesium_layer {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;

  #layer-container {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    &.bk1027 {
      background-image: url(~@/assets/images/cesium/bk1027.png);
    }
  }
  .yc1027 {
    left: 14px !important;
    top: 532px;
    transition: none;
  }
  .uav1027 {
    left: 18px !important;
    top: calc(51% - 2px) !important;
  }
  // 鹰巢无人机切换视频时 ，地图的位置
  .ycFlag_01 {
    position: fixed !important;
    // right: 0;
    z-index: 11;
    left: 15px !important;
    bottom: calc(23% - 2px) !important;
    width: 349px !important;
    height: 200px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_01--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag_01_full {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    bottom: calc(33% - 2px) !important;
    width: 349px !important;
    height: 200px !important;
    transition: left 0.3s, opacity 0s;

    &.ycFlag_01_full--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag_02 {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    top: 410px;
    bottom: calc(46% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;
    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag_02_full {
    position: fixed !important;
    z-index: 11;
    left: 15px !important;
    bottom: calc(53% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;
    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag_03 {
    position: fixed !important;
    z-index: 11;
    left: 191px !important;
    top: 410px;
    bottom: calc(46% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;
    &.ycFlag_02--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag_03_full {
    position: fixed !important;
    z-index: 11;
    left: 191px !important;
    bottom: calc(53% - 2px) !important;
    width: 170px !important;
    height: 100px !important;
    transition: left 0.3s, opacity 0s;
    &.ycFlag_03--collapse {
      left: -354px !important;
      opacity: 0;
    }
  }
  .ycFlag--collapse {
    display: none;
  }
  .wrjFlag {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 24px !important;
    bottom: 80px !important;
    // top: calc(30% - 192px);
    width: 349px !important;
    height: 200px !important;
  }

  .wrjFlag1 {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 24px !important;
    top: calc(49% - 238px) !important;
    width: 349px !important;
    height: 200px !important;
  }
  .fkVideo {
    position: fixed !important;
    right: 0;
    z-index: 11;
    left: 16px !important;
    bottom: 10px !important;
    width: 429px !important;
    height: 223px !important;
  }
  .enable {
    display: none;
  }
}
</style>
