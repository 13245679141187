<template>
    <div class="w440 h177 noticeBox">
        <div class="headerBox" v-interact>
            <div class=" jcsb">
                <div class="jcsb title">
                    <div style="border:1px solid #FFBD36;background-color:#FFBD36 ; " class="w1 h16 mr10"></div>
                    {{ title }}
                </div>
                <div class="cp" @click="$emit('exit')" style="color: #70DAF9;">
                    关闭
                </div>
            </div>

        </div>
        <div class="contant pt34 ">
            <div class="mb34 cf pl22 pr22">
                {{ content }}
            </div>
            <div class="jcsb pl76 pr76">
                <div class="w122 h32 tc lh32 cf cp btn" @click="$emit('close')">{{ leftText }}</div>
                <div v-if="flag" class="w122 h32 tc lh32 cf cp btn ml10 mr10" @click="$emit('qzjg')">强制接管</div>
                <div class="w122 h32 tc lh32 cf cp btn" @click="$emit('handle')">{{ rightText }}</div>
            </div>
        </div>
    </div>
</template>
<script>
import API from "@/api";
export default {
    data() {
        return {

        }
    },
    props: {
        title: {
            'type': String,
            default: '提示'
        },
        content: {
            'type': String,
            default: '某某某某某某某某某某某某单位请求接管000000无人机无人机无人机'
        },
        leftText: {
            'type': String,
            default: '拒绝'
        },
        rightText: {
            'type': String,
            default: '同意'
        },
        flag:{
            'type': Boolean,
            default: ()=>{ false}
        }
    },
    methods: {
    },
}
</script>
<style lang="scss" scoped>
.noticeBox {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    z-index: 99;
    background: rgba(9, 32, 87, 0.70);
    border: 1px solid #70DAF9;

    .headerBox {
        background-image: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.22) 40%, #05091A 100%);
        border: 1px solid #70DAF9;
        box-shadow: inset 0 0 10px 2px #3F9DFF;
        padding: 5px 14px;

        .title {
            font-family: MicrosoftYaHei-Bold;
            font-size: 16px;
            color: #70DAF9;
            letter-spacing: 0;
            font-weight: 700;

        }
    }

    .contant {
        height: 146px;
        text-align: center;

        .btn {
            background-image: linear-gradient(180deg, #9198FF 0%, rgba(45, 81, 153, 0.22) 40%, #05091A 100%);
            border: 1px solid #70DAF9;
            box-shadow: inset 0 0 10px 2px #3F9DFF;
        }
    }
}
</style>