var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cpt-hwkeys"},[_c('div',{staticClass:"hwkeys-wrap cp",on:{"click":_vm.change,"mouseout":_vm.likai}},_vm._l((_vm.tabs),function(item){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(item.id == _vm.current),expression:"item.id == current"}],key:item.id,class:{
      'hwkeys-item': 1,
      'hwkeys-item-satellite': 1,
    },on:{"click":function($event){return _vm.handle_change(item.id)}}})}),0),_c('div',{staticClass:"changeMapBox pa cp"},[_c('div',{staticClass:"dise",on:{"mouseout":_vm.likai}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.is_3d),expression:"!is_3d"}],staticClass:"bai",class:{ active: !_vm.is_3d },on:{"click":function($event){return _vm.handle_change_3d(true)}}},[_vm._v(" 2D ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.is_3d),expression:"is_3d"}],staticClass:"bai",class:{ active: _vm.is_3d },on:{"click":function($event){return _vm.handle_change_3d(false)}}},[_vm._v(" 3D ")])]),_vm._l((_vm.tabs),function(item){
    var _obj;
return _c('div',{key:item.id,staticClass:"dise",on:{"mousemove":function($event){return _vm.jinru(item.id)}}},[_c('div',{class:( _obj = {
        'hwkeys-item': 1
      }, _obj[item.class_name] = 1, _obj ),staticStyle:{"position":"relative","overflow":"hidden"},on:{"click":function($event){return _vm.handle_change(item.id)}}},[_c('div',{staticClass:"zimu",class:{ sh: _vm.numId == item.id + 1 }},[_vm._v(" "+_vm._s(item.name)+" ")])])])})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }