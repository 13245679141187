import factor from "./factor"
import uav from "./uav"
import toolbar from "./toolbar"

function prefix(meta, prefix) {
    return Object.keys(meta).reduce((obj, key) => {
        obj[`${prefix}_${key}`] = meta[key]
        return obj
    }, {})
}

export default {
    ...prefix(factor, "factor"),
    ...prefix(uav, "uav"),
    ...prefix(toolbar, "toolbar"),
}