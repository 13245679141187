<!-- 勤务报备 -->
<template>
  <div id="type">
    <div class="head">
      <div class="flex">
          <div class="pup_img">
            <img
              src="~@/assets/zong_lan_ye_img/jinye.png"
              style="width: 100%; height: 100%"
              alt=""
            />
          </div>
          <div class="fontOne">
            天气预警
          </div>
        </div>
        <div class="head_r">
          <div class="fontTwo" @click="$emit('close')">关闭</div>
        </div>
    </div>
    <div class="list">
      <div class="report" v-for="(item,i) in list" :key="item.infoid">
        <div class="image">
          <img :src="item.img" alt="">
        </div>
        <div class="content">
          {{item.content}}
        </div>
        <div class="btn cp" @click="toggleDiv(i)">{{item.toggle}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
    };
  },
  watch: {},
  mounted() {
    console.log(this.list);
    this.getDiv()
  },
  methods: {
    getDiv(){
      this.list.forEach((val,i)=>{
        const content = document.querySelectorAll('.content')[i]
        const button = document.querySelectorAll('.btn')[i]
        // 根据文本内容是否超出容器高度来决定是否显示按钮
          if (content.scrollHeight > 60) {
            button.style.display = 'block';
          } else {
            button.style.display = 'none';
          }
      })
    },
    toggleDiv(i){
      const content = document.querySelectorAll('.content')[i]
      const button = document.querySelectorAll('.btn')[i]
      if (content.style.maxHeight) {
        // 已展开状态，点击收起
        content.style.maxHeight = null;
        this.list[i].toggle = "展开"
      } else {
        // 已收起状态，点击展开
        content.style.maxHeight = '1000px';
        this.list[i].toggle = "收起"
      }
    }
  },
};
</script>
<style scoped lang="scss">
#type {
  // width: 1000px;
  max-width: 1000px;
  position: fixed;
  top: 300px;
  left: 37%;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .list{
    padding: 10px;
    background: rgba(12,34,73,1);
    max-height: 450px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .report {
      margin: 10px 0;
      width: 508px;
      background: rgba(29, 65, 118, 0.5);
      border-radius: 10px;
      backdrop-filter: blur(1px);
      backface-visibility: hidden; //元素背面向屏幕时是否可见
      display: flex;
      align-items: center;
      .content{
        align-self: flex-start;
        padding: 8px;
        margin-top: 6px;
        text-indent: 32px;
        color: #fff;
        line-height: 20px;
        max-height: 52px;
        overflow: hidden;
      }
      .image{
        width: 100px;
        height: 110px;
        background: rgba(2,23,61,1);
        display: flex;
        justify-content: center;
      }
      .btn{
        position: absolute;
        right: 10px;
        bottom: 2px;
        font-size: 12px;
        color: #fff;
      }
    }
  }

    .head {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 16px 0 12px;
      align-items: center;
      height: 32px;
      background: linear-gradient(
        180deg,
        #9198ff 0%,
        rgba(45, 81, 153, 0.45) 40%,
        #05091a 100%
      );
      box-shadow: inset 0px 0px 10px 2px #3f9dff;
      border-radius: 10px 10px 0px 0px;
      border: 1px solid #427dff;

      .pup_img {
        display: flex;
        align-items: center;
        width: 19px;
        height: 25px;
        margin: 0 4px 0 0;
      }

      .fontOne {
        font-size: 20px;
        font-family: YouSheBiaoTiHei;
        color: #14faff;
        line-height: 26px;
        text-shadow: 0px 1px 1px rgba(2, 32, 56, 0.2);
        background: linear-gradient(
          135deg,
          #e3aa77 0%,
          #f5cda9 38%,
          #f9ecd3 58%,
          #fcdbb1 79%,
          #edb07a 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      .head_r{
        display: flex;
        align-items: center;
        .fz {
          cursor: pointer;
          padding: 0 8px;
          background: url("./assets/btn.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 24px;
          font-size: 14px;
          // font-family: YouSheBiaoTiHei;
          color: #fff;
          line-height: 26px;
        }
      
        .fontTwo {
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #d2dfff;
          cursor: pointer;
          line-height: 20px;
        }
      }
    }
}
.flex {
    display: flex;
  }
    
</style>
