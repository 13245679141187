<!-- 警情派发-航线规划 -->
<template>
  <div class="cpt-command-situation-edit">
    <div class="header" v-interact>
      <div class="hd-box ml20">航线规划</div>

      <div class="hd-box cp mr20" @click="flight_task_chage_view(1)">关闭</div>
    </div>

    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="警情事件内容" prop="content">
        <el-input v-model="ruleForm.content" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="目标位置" prop="destination">
        <el-input v-model="ruleForm.incidentAddress" :disabled="true"></el-input>
        <el-tooltip effect="dark" content="定位" placement="top">
          <div
            @click="() => $emit('handleLocation', situation_detail)"
            class="el-icon-location-outline"
          ></div>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="航线名称" prop="flight_line_id">
        <el-select
          v-model="ruleForm.flight_line_id"
          clearable
          placeholder="请选择航线"
          :popper-append-to-body="false"
        >
          <el-option v-for="item in lineList" :key="item.id" :label="item.title" :value="item.id">
            <span style="float: left">{{ item.title }}</span>
            <span style="float: left; color: #00b900; font-size: 13px">
              {{
              item.issafe == 1 ? "（安全）" : ""
              }}
            </span>
          </el-option>
        </el-select>
        <el-tooltip effect="dark" content="航线规划" placement="top">
          <div class="iconfont icon-hangkonghangxian track_btn" @click="handle_click"></div>
        </el-tooltip>
      </el-form-item>
      <el-form-item label="警情类型" prop="bjlxmc">
        <el-input v-model="ruleForm.bjlxmc" :disabled="true"></el-input>
        <!-- <el-select
          v-model="ruleForm.jing_qing_type"
          clearable
          placeholder="请选择警情类型"
          :popper-append-to-body="false"
        >
          <el-option
            v-for="item in typeList"
            :key="item.id"
            :label="item.title"
            :value="item.title"
          >
          </el-option>
        </el-select>-->
      </el-form-item>
      <!-- <el-form-item label="警情执行状态" prop="taskStatus">
        <el-select
          v-model="ruleForm.taskStatus"
          clearable
          placeholder="请选择执行状态"
          :popper-append-to-body="false"
          :disabled="true"
        >
          <el-option label="待执行" :value="0"></el-option>
          <el-option label="执行中" :value="3"></el-option>
          <el-option label="已完成" :value="1"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item prop="mountType" label="所需挂载">
        <el-select
          v-model="ruleForm.mountType"
          multiple
          filterable
          clearable
          placeholder="请选择所需挂载"
        >
          <el-option
            v-for="item in mounteList"
            :key="item.id"
            :label="item.name"
            :value="item.name"
          >
          </el-option>
        </el-select>
      </el-form-item>-->
      <el-form-item label="责任单位" prop="organizationId">
        <el-cascader
          ref="elcascader"
          @change="changeVal"
          placeholder="请选择所属机构"
          v-model="ruleForm.organizationId"
          :options="unitList"
          :show-all-levels="false"
          :props="{
            children: 'children',
            label: 'orgName',
            value: 'id',
            checkStrictly: true,
            emitPath: false,
          }"
          clearable
        ></el-cascader>
      </el-form-item>
      <!-- <el-form-item label="需协飞单位">
        <el-checkbox v-model="checked2" class="text">最优调度</el-checkbox>
      </el-form-item>-->
      <!-- <el-form-item class="assist" v-show="checked2" label="协助单位">
        <el-dropdown trigger="click" placement="bottom-start">
          <div class="assist-content pl15">
            {{ ruleForm.assistUnit }}
          </div>
          <el-dropdown-menu slot="dropdown" class="cpt-situation-edit">
            <el-dropdown-item v-for="(item, index) in selectArr" :key="index">{{
              item
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-form-item>-->
      <el-form-item label="飞手选择" prop="userIds">
        <el-select
          v-model="ruleForm.userIds"
          clearable
          placeholder="请选择"
          :popper-append-to-body="false"
          multiple
        >
          <el-option v-for="item in flyerList" :key="item.id" :label="item.title" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item label="内容描述" prop="status">
        <el-input
          v-model="ruleForm.remark"
          clearable
          type="textarea"
        ></el-input>
      </el-form-item>-->
    </el-form>
    <div class="btn">
      <el-button @click="handleClickFn">提交</el-button>
      <el-button @click="handleCancer">取消</el-button>
    </div>
  </div>
</template>

<script>
import API from "@/api";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      resourceVisible: false,
      mounteList: [],
      checked2: false,
      ruleForm: {
        content: null,
        incidentAddress: null, //目标位置
        startPosition: null, //起始位置
        flight_line_id: null,
        jing_qing_type: null,
        taskStatus: null,
        organizationId: null,
        userIds: null,
        remark: null,
        taskId: null,
        id: null,
        mountType: null,
        resourceName: null,
        assistUnit: null,
        bjlxmc: null,
        applyStatus: null,
      },
      rules: {
        flight_line_id: [
          { required: true, message: "请选择航线", trigger: "change" },
        ],
      },
      lineList: [],
      unitList: [],
      flyerList: [],
      typeList: [],
      destination: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      startPosition: {
        // name: '',
        lng: 0,
        lat: 0,
      },
      selectList: null,
      selectArr: [],
    };
  },
  computed: {
    ...mapGetters(["situation_detail"]),
  },
  inject: ["flight_task_chage_view", "change_page", "change_airway_visible"],
  async mounted() {
    await this.getMount();
    await this.getLineList(),
      await this.getUnitList(),
      await this.getFlyerList(),
      // await this.getTypeList(),
      this.getDetail();
  },
  methods: {
    handle_click() {
      this.change_page(4);
      this.change_airway_visible();
      this.$store.commit("situation/IS_FROM_SITUATION_EDIT", true);
    },
    handleCancer() {
      this.flight_task_chage_view(1);
      this.checked2 = false;
      this.resourceVisible = false;
    },
    // 接收子组件勾选资源单位
    acceptSelectFn(val, resourceName) {
      this.ruleForm.resourceName = JSON.stringify(resourceName);
      let arr = [];
      val.map((item) => arr.push(item.organizationName));
      this.selectArr = Array.from(new Set(arr));
      this.ruleForm.assistUnit = this.selectArr.join();
    },
    changeVal() {
      this.$refs.elcascader.dropDownVisible = false;
    },
    getDetail() {
      const keys = Object.keys(this.ruleForm);
      keys.forEach((item) => {
        this.ruleForm[item] = this.situation_detail[item];
      });
    },
    async handleClickFn() {
      const keys = Object.keys(this.ruleForm);
      const form = JSON.parse(JSON.stringify(this.ruleForm));
      keys.forEach((item) => {
        if (form[item] == this.situation_detail[item]) {
          if (
            item != "id" &&
            item != "taskId" &&
            item != "applyStatus" &&
            item != "flight_line_id"
          ) {
            delete form[item];
          }
        }
      });
      console.log(form, "form");
      try {
        if (form.resourceName) {
          form.flag = 1;
        }
        console.log(form.flight_line_id, "form.flight_line_id");
        if (!form.flight_line_id) {
          this.$message.warning("请选择航线");
        } else {
          let res = await API.SITUATION.savePoliceTaskFlightLine(form);
          this.$emit("handleClick", false);
          this.checked2 = false;
          this.resourceVisible = false;
          this.$el_message("提交成功");
          let time = Date.now();
          // 刷新飞行任务-警情任务列表
          this.$store.commit("device/SET_JQ_LIST", time);
          // 疑似跳转功能
          this.flight_task_chage_view(1);
        }
      } catch (error) {
        this.$el_message("信息填写不完整,提交失败", () => {}, "error");
      }
    },
    async getLineList() {
      let data = await API.AIRWAY.Drd();
      this.lineList = data || [];
    },
    async getUnitList() {
      let data = await API.DEPARTMENT.Drd();
      this.unitList = [data] || [];
    },
    async getFlyerList() {
      let data = await API.USER.DrdPilot();
      this.flyerList = data || [];
    },
    async getTypeList() {
      let data = await API.SITUATION.ListTpye();
      this.typeList = data || [];
    },
    // 获取挂载列表
    async getMount() {
      let data = await API.SITUATION.getAllMountTypeList();
      console.log("------data", data);
      this.mounteList = data || [];
    },
  },
  watch: {
    "$store.state.device.key_line"(newData) {
      this.getLineList();
    },
    checked2(val) {
      this.flight_task_chage_view(1, val, true);
    },
  },
  beforeDestroy() {
    this.flight_task_chage_view(1, false, true);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/styles/form.scss";
.cpt-command-situation-edit {
  position: absolute;
  left: 90px;
  top: 110px;
  width: 560px;
  // height: 784px;
  box-sizing: border-box;
// background: rgba(12, 34, 73, 0.7);
    background: rgba(12, 34, 73, 0.7);
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
  border-radius: 13px;
  border: 1px solid rgba(26, 92, 246, 0.5);
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 44px;
    background: linear-gradient(
180deg, #9198ff 0%, rgba(45, 81, 153, 0.45) 40%, #05091a 100%);
    box-shadow: inset 0px 0px 10px 2px #3f9dff;
    border-radius: 10px 10px 0px 0px;
    border: 1px solid #427dff
}
.hd-box {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #00ffff;
  letter-spacing: 0;
  font-weight: 400;
}

.el-form {
  padding-top: 30px;
  ::v-deep {
    .el-textarea__inner {
      height: 70px;
    }
    .el-input .el-input__inner{
          background: #02173d;
    border-radius: 2px;
    border: 1px solid #315ec7;
    color: #00DFFF
    }
  }
  
}
.btn {
  margin-left: 139px;
  margin-top: 20px;
  margin-bottom: 30px;
}
.el-icon-location-outline {
  color: rgb(179, 201, 203);
  font-size: 30px;
  margin-left: 0.3em;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
/deep/.el-checkbox__input {
  background: #000000;
  border: 1px solid #08c2d1;
  border-radius: 4px;
}
/deep/.el-checkbox__inner {
  border: none;
  background-color: transparent;
}
.assist {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #dcdfe3;
  font-weight: 400;
}
.assist-content {
  font-family: MicrosoftYaHeiUI;
  font-size: 15px;
  color: #08c2d1;

  background: rgba(2, 31, 51, 0.7);
  border: 1px solid #06b6e0;
  width: 375px;

  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: transparent;
}
/deep/ .el-dropdown {
  color: #08c2d1;
}
.track_btn {
  color: #b3c9cb;
  position: absolute;
  right: 18px;
  top: 3px;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
</style>

<style lang="scss">
.cpt-situation-edit {
  width: 394px;
}
</style>
